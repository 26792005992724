import React, { useContext } from "react";
import ResumeUpload from "../Resume";
import SvgButton from "components/base/SvgButton";
import { APPLICATION_TAB_NAMES, JOB_APPLICATION_MATCH_STATUS } from "lookup";
import DropDownMenu from "components/DropDownMenu";
import { AuthContext } from "context/providers";

const UserActionIcons = ({
  hit,
  collectionKey,
  activeTabName,
  updateHit,
  showModal,
}) => {
  const { isAdmin } = useContext(AuthContext);

  const saveAttributes = async (attributes) => {
    const attributesToUpdate = {
      input: {
        id: hit.id,
        ...attributes,
      },
    };
    try {
      await updateHit(attributesToUpdate);
    } catch (error) {
      console.log("Error when updating profile info", error);
    }
  };

  const showUserInfoModal = () => {
    if (hit.match?.applicationId) {
      showModal({ type: "userInfo", applicationId: hit.match.applicationId });

      return;
    }

    showModal({ type: "userInfo", userId: hit.id });
  };

  const CustomLink = ({ url, label }) => (
    <a target="_blank" rel="noreferrer" href={url}>
      <span className="w-full text-center">{label}</span>
    </a>
  );

  const menuOptions = [
    collectionKey === "match" &&
      hit.match?.status !== JOB_APPLICATION_MATCH_STATUS.SHORTLISTED &&
      hit.match?.status !== JOB_APPLICATION_MATCH_STATUS.SKIPPED && (
        <p
          onClick={() =>
            showModal({
              type: "match",
              applicationId: hit.match?.applicationId,
              jobOpportunityId: hit.match?.jobOpportunityId,
              freelancerPitchPrefill: hit.match?.freelancerPitchPrefill,
              hitStatus: hit.match?.status,
              freelancerPitch: hit.match?.freelancerPitch,
              isUpdate: true,
            })
          }
        >
          Candidate Questions
        </p>
      ),
    <p
      onClick={() =>
        showModal({
          type: "link",
          userId: hit.id,
          applicationId: hit?.match?.applicationId,
          collectionKey,
        })
      }
    >
      Add Link
    </p>,
    isAdmin && (
      <CustomLink
        url={`https://admin.torc.dev/users/${hit.username}`}
        label={"Admin Tool"}
      />
    ),
    <CustomLink
      url={`${process.env.REACT_APP_SFDC_BASE_URL}/torcUserRedirect?id=${hit.id}`}
      label={"SFDC User"}
    />,
    activeTabName === APPLICATION_TAB_NAMES.MATCHED && (
      <CustomLink
        url={`${process.env.REACT_APP_SFDC_BASE_URL}/customFlowOperation?platformmatchid=${hit?.match?.id}`}
        label={"SFDC Match"}
      />
    ),
  ];

  return (
    <div className="flex items-center gap-x  md:gap-x-2">
      <a
        className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
        href={`mailto:${hit.email}`}
        target="_blank"
        rel="noreferrer"
      >
        <SvgButton icon="email" />
      </a>

      {hit?.phone?.number ? (
        <a
          className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
          href={`tel:${hit.phone.number}`}
          target="_blank"
          rel="noreferrer"
        >
          <SvgButton icon="phone" />
        </a>
      ) : null}

      {hit?.phone?.whatsAppAllowed ? (
        <a
          className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
          href={`whatsapp://send?phone=${hit.phone.number}`}
          target="_blank"
          rel="noreferrer"
        >
          <SvgButton icon="whatsapp" />
        </a>
      ) : null}
      <SvgButton
        icon="openBook"
        tooltip="assessments"
        onClick={() => showModal({ type: "assessment", userId: hit.id })}
      />
      <ResumeUpload user={hit} save={saveAttributes} />
      <SvgButton icon="info" onClick={showUserInfoModal} />

      <div className="bg-gray-400 !w-[2px] h-[18px]"></div>

      <DropDownMenu options={menuOptions.filter((e) => e)} />
    </div>
  );
};

export default UserActionIcons;
