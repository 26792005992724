export const REQUIRED_ERRORS = {
  name: "Required field",
  value: "Required field",
  criteriaTitle: "Title is required",
  content: "Content is required",
  metadataKey: "Key is required",
  metadataValue: "Value is required",
};

export const WRONG_FORMAT_ERRORS = {
  value: "Wrong URL format",
};
