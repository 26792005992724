import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "components/Button";

const PrefillPitch = ({
  field,
  className,
  borderTop,
  editorLabel,
  editorSubLabel,
  onSubmit,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [objectField, setObjectField] = useState({
    value: field,
    successMessage: "",
    errorMessage: "",
  });

  const handleChange = (value) => {
    setObjectField((prev) => ({
      ...prev,
      value,
      errorMessage: "",
      successMessage: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) {
      return;
    }

    setSubmitting(true);
    const res = await onSubmit(objectField);
    let successMessage = "";
    let errorMessage = "";

    if (res.isSuccess) {
      successMessage = "update successful";
    } else {
      errorMessage = "Something went wrong";
    }
    setObjectField((prev) => ({
      ...prev,
      successMessage,
      errorMessage,
    }));

    setTimeout(() => {
      setObjectField((prev) => ({
        ...prev,
        successMessage: "",
        errorMessage: "",
      }));
    }, 2000);

    setSubmitting(false);
  };

  return (
    <div className={classNames("flex flex-col", className)}>
      {borderTop && <hr className="w-full border-t-2 border-gray-300 mb-4" />}

      <form onSubmit={handleSubmit}>
        {editorLabel && <p className="text-2xl font-bold">{editorLabel}</p>}

        {editorSubLabel && (
          <p className="font-normal text-base mb-4">{editorSubLabel}</p>
        )}

        <textarea
          className="w-full px-4 py-2 bg-white border-gray-300 border-2 mt-2"
          value={objectField.value}
          onChange={(e) => handleChange(e.target.value)}
          rows={6}
        />

        <div className="flex flex-col items-start">
          {objectField.successMessage && (
            <span className="text-green-600 text-sm font-bold mt-1">
              {objectField.successMessage}
            </span>
          )}

          {objectField.errorMessage && (
            <span className="text-red-600 text-sm font-bold mt-1">
              {objectField.errorMessage}
            </span>
          )}

          <Button
            type="submit"
            isReadOnly={submitting || field === objectField.value}
            className={classNames("mt-4", {
              "animate-pulse cursor-not-allowed": submitting,
            })}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

PrefillPitch.propTypes = {
  field: PropTypes.string,
  className: PropTypes.string,
  borderTop: PropTypes.bool,
  editorLabel: PropTypes.string,
  editorSubLabel: PropTypes.string,
  onSubmit: PropTypes.func,
};

PrefillPitch.defaultProps = {
  field: "",
  className: "",
  borderTop: false,
  editorLabel: "",
  editorSubLabel: "",
  onSubmit: () => {},
};

export default PrefillPitch;
