import React, { createContext, useReducer } from "react";
import { isArray } from "lodash";

import api from "apiSingleton";

import { contextConstants } from "context/constants";
import { contextReducers } from "context/reducers";

export const NotesContext = createContext();

export const NotesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.notes.reducer,
    contextReducers.notes.initialState
  );

  const job = async (jobOpp) => {
    const filter = { jobOpportunityId: jobOpp.id };
    const notes = await api.notes.listJob(filter);
    const output = (notes.data.listJobOpportunityNotes.items || []).filter(
      (note) => !note.applicationId && !note.userId
    );

    setNotesByTarget(jobOpp.id, output, "job");
  };

  const user = async (noteUser) => {
    const filter = {
      userId: noteUser.id,
    };

    const userNotes = await api.notes.listUser(filter);

    setNotesByTarget(
      noteUser.id,
      userNotes?.data?.listUserNotes?.items || [],
      "user"
    );
  };

  const match = async (application, jobOpp) => {
    const filter = {
      applicationId: application.id,
      jobOpportunityId: { eq: jobOpp.id },
    };

    const matchNotes = await api.notes.listMatch(filter);

    setNotesByTarget(
      application.id,
      matchNotes?.data?.listMatchNotes?.items || [],
      "match"
    );
  };

  const clear = (target) => {
    if (isArray(target)) {
      target.forEach((el) => setNotesByTarget([], el));
    } else {
      setNotesByTarget([], target);
    }
  };

  const add = async ({ ...args }) => {
    await api.notes.create({
      input: {
        ...args,
      },
    });
  };

  const remove = async ({ ...args }) => {
    await api.notes.remove({
      input: {
        ...args,
      },
    });
  };

  const setNotesByTarget = (id, notes, target) => {
    switch (target) {
      case "job": {
        dispatch({
          type: contextConstants.notes.JOB_NOTES_LOADED,
          payload: { jobOpportunityId: id, notes },
        });
        break;
      }

      case "user": {
        dispatch({
          type: contextConstants.notes.USER_NOTES_LOADED,
          payload: { userId: id, notes },
        });
        break;
      }

      case "match": {
        dispatch({
          type: contextConstants.notes.MATCH_NOTES_LOADED,
          payload: { applicationId: id, notes },
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  const notes = {
    ...state,
    job,
    user,
    match,
    clear,
    add,
    remove,
  };

  return (
    <NotesContext.Provider value={notes}>{children}</NotesContext.Provider>
  );
};
