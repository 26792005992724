import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";

import { validateMetadata } from "utils/validation";
import { MatchContext, SearchContext } from "context/providers";

import Input from "components/base/Input";
import SvgButton from "components/base/SvgButton";
import Button from "components/Button";

const DEFAULT_INPUTS = {
  metadataKey: {
    value: "",
    placeholder: "Key",
    errorMessage: "",
    maxLength: 255,
  },
  metadataValue: {
    value: "",
    placeholder: "Value",
    errorMessage: "",
    maxLength: 1000,
  },
};

const Metadata = ({ target, user }) => {
  const { updateMatch, updateMatchFieldLocally } = useContext(MatchContext);
  const { updateHit, updateHitField } = useContext(SearchContext);

  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState(prepareInputs(user));
  const [inputs, setInputs] = useState({
    inputs: DEFAULT_INPUTS,
    loadingInputs: DEFAULT_INPUTS,
  });

  const predictions = useMemo(() => {
    const keys = JSON.parse(
      process.env.REACT_APP_PREDEFINED_METADATA_KEYS || "{}"
    );
    const keyPredictions = keys[target];
    const excludedPredictions = metadata.map((group) => {
      const [key] = group;

      return key.value.toLowerCase();
    });

    return {
      metadataKey: {
        predictions: keyPredictions,
        excludedPredictions,
      },
    };
  }, [target, metadata]);

  function prepareInputs(input) {
    const parsed =
      typeof input?.metadata === "string"
        ? JSON.parse(input?.metadata)
        : input?.metadata || {};

    const output = [];

    Object.keys(parsed).forEach((key) => {
      output.push([
        { value: key, errorMessage: "" },
        { value: parsed[key], errorMessage: "" },
      ]);
    });

    return output;
  }

  const prepareMetadataPayload = () => {
    const output = {};

    metadata.forEach((group) => {
      const [keyInput, valueInput] = group;

      output[keyInput.value] = valueInput.value;
    });

    validateMetadata({
      data: {
        metadataKey: inputs.inputs.metadataKey.value,
        metadataValue: inputs.inputs.metadataValue.value,
      },
      onSuccess: (validData) => {
        output[validData.metadataKey] = validData.metadataValue;
      },
    });

    return JSON.stringify(output);
  };

  const handleDelete = (index) => () => {
    const cloned = cloneDeep(metadata);

    cloned[index] = null;

    setMetadata(cloned.filter((el) => el));
  };

  const handleChangeExistInput =
    (target, index) =>
    ({ value }) => {
      const output = cloneDeep(metadata);
      const [keyInput, valueInput] = cloneDeep(output[index]);

      switch (target) {
        case "key": {
          keyInput.value = value;
          keyInput.errorMessage = "";
          break;
        }

        default: {
          valueInput.value = value;
          valueInput.errorMessage = "";
          break;
        }
      }

      output[index] = [keyInput, valueInput];

      setMetadata(output);
    };

  const handleChangeNewInput =
    (inputsKey) =>
    ({ value, valueKey }) => {
      setInputs((prev) => {
        return {
          ...prev,
          [inputsKey]: {
            ...prev[inputsKey],
            [valueKey]: {
              ...prev[inputsKey][valueKey],
              errorMessage: "",
              value,
            },
          },
        };
      });
    };

  const validatePayload = () => {
    const clonedMetadata = cloneDeep(metadata);

    let isValid = true;

    clonedMetadata.forEach((group, index) => {
      const [keyInput, valueInput] = group;

      validateMetadata({
        data: {
          metadataKey: keyInput.value,
          metadataValue: valueInput.value,
        },
        onError: (errors) => {
          if (errors.metadataKey) {
            keyInput.errorMessage = errors.metadataKey;
          }

          if (errors.metadataValue) {
            valueInput.errorMessage = errors.metadataValue;
          }

          clonedMetadata[index] = [keyInput, valueInput];

          isValid = false;
        },
      });
    });

    if (!!inputs.inputs.metadataValue.value) {
      validateMetadata({
        data: {
          metadataKey: inputs.inputs.metadataKey.value,
          metadataValue: inputs.inputs.metadataValue.value,
        },
        onError: (errors) => {
          setInputs((prev) => {
            const output = cloneDeep(prev);

            Object.keys(errors).forEach(
              (errorKey) =>
                (output.inputs[errorKey].errorMessage = errors[errorKey])
            );

            return output;
          });

          isValid = false;
        },
      });
    } else {
      setInputs((prev) => {
        const output = cloneDeep(prev);

        Object.keys(output.inputs).forEach(
          (key) => (output.inputs[key].errorMessage = "")
        );

        return output;
      });
    }

    if (!isValid) {
      setMetadata(clonedMetadata);
    }

    return isValid;
  };

  const updateMetadata = async () => {
    try {
      const { applicationId, jobOpportunityId, id } = user;
      const newMetadata = prepareMetadataPayload();

      switch (target) {
        case "Match": {
          await updateMatch(applicationId, jobOpportunityId, {
            metadata: newMetadata,
          });

          const newMatchedUser = updateMatchFieldLocally(
            applicationId,
            "metadata",
            newMetadata
          );

          return newMatchedUser;
        }

        default: {
          await updateHit({
            input: {
              id,
              metadata: newMetadata,
            },
          });

          const newUser = updateHitField(id, "metadata", newMetadata);

          return newUser;
        }
      }
    } catch (error) {
      console.log("updateMetadata error: ", error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const isValid = validatePayload();

    if (isValid) {
      setIsLoading(true);

      try {
        const newUser = await updateMetadata();

        setMetadata(prepareInputs(newUser));
      } catch (error) {
        console.log("handleSave error: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderMetadata = () => {
    if (metadata.length === 0) {
      return <p className="mb-4">No tags provided</p>;
    }

    return metadata.map((group, index) => {
      const [keyInput, valueInput] = group;

      return (
        <div key={nanoid()} className="flex items-center mb-6">
          <Input
            className="w-full mr-4 mb-0"
            value={keyInput.value}
            errorMessage={keyInput.errorMessage}
            placeholder="Key"
            onBlur={handleChangeExistInput("key", index)}
          />

          <Input
            className="w-full mr-4 mb-0"
            value={valueInput.value}
            errorMessage={valueInput.errorMessage}
            placeholder="Value"
            onBlur={handleChangeExistInput("value", index)}
          />

          <SvgButton
            icon="cross"
            iconProps={{ className: "w-[20px]" }}
            onClick={handleDelete(index)}
          />
        </div>
      );
    });
  };

  useEffect(() => {
    if (isLoading) {
      setInputs((prev) => ({ ...prev, loadingInputs: DEFAULT_INPUTS }));
    }

    if (!isLoading) {
      setInputs((prev) => ({
        ...prev,
        inputs: prev.loadingInputs,
        loadingInputs: DEFAULT_INPUTS,
      }));
    }
  }, [isLoading]);

  const renderInputs = (inputs, inputsKey, readOnly = false) => {
    return (
      <div className="grid grid-cols-2 gap-4 mb-6 w-[calc(100%_-_35px_-_1rem)]">
        {Object.keys(inputs).map((inputKey) => {
          return (
            <Input
              className="mb-0"
              key={inputKey}
              valueKey={inputKey}
              readOnly={readOnly}
              onChange={handleChangeNewInput(inputsKey)}
              {...predictions[inputKey]}
              {...inputs[inputKey]}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSave}>
        {renderMetadata()}

        {renderInputs(inputs.inputs, "inputs", isLoading)}

        {isLoading && renderInputs(inputs.loadingInputs, "loadingInputs")}

        <Button isReadOnly={isLoading} disabled={isLoading} type="submit">
          save
        </Button>
      </form>
    </div>
  );
};

Metadata.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

Metadata.defaultProps = {
  user: null,
};

export default Metadata;
