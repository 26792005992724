import classNames from "classnames";
import SvgButton from "components/base/SvgButton";
import React, { useEffect, useRef, useState } from "react";

const DropDownMenu = ({ icon = "ellipsis", options, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    const handleOutsideClick = (e) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left h-full">
      <div ref={buttonRef}>
        <SvgButton icon={icon} onClick={() => setIsOpen((prev) => !prev)} />
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className={classNames(
            "z-[50] origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5",
            className
          )}
        >
          <ul
            className="whitespace-nowrap"
            role="menu"
            aria-orientation="vertical"
          >
            {options.map((option, index) => (
              <li
                onClick={() => {
                  setIsOpen(false);
                }}
                key={index}
                className="px-4 py-2 text-gray-700 cursor-pointer border-b-2"
                role="menuitem"
              >
                <div className="hover:text-sky-500">{option}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;
