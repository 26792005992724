import { JOB_APPLICATION_MATCH_RATINGS_FORMATTED } from "lookup";
import DESIRABLESRC from "images/reactions/DESIRABLE.png";
import NEUTRALSRC from "images/reactions/NEUTRAL.png";
import UNDESIRABLESRC from "images/reactions/UNDESIRABLE.png";
import VERYDESIRABLESRC from "images/reactions/VERYDESIRABLE.png";
import VERYUNDESIRABLESRC from "images/reactions/VERYUNDESIRABLE.png";

const RATINGS_SRC = {
  DESIRABLE: DESIRABLESRC,
  NEUTRAL: NEUTRALSRC,
  UNDESIRABLE: UNDESIRABLESRC,
  VERYDESIRABLE: VERYDESIRABLESRC,
  VERYUNDESIRABLE: VERYUNDESIRABLESRC,
};

const Ratings = ({ rating }) => {
  const formattedRatingsKeys = Object.keys(
    JOB_APPLICATION_MATCH_RATINGS_FORMATTED
  );
  return (
    <div className="flex gap-4">
      {formattedRatingsKeys.map((formattedRatingsKey, index) => (
        <div
          key={index}
          title={JOB_APPLICATION_MATCH_RATINGS_FORMATTED[formattedRatingsKey]}
          className={`${rating !== formattedRatingsKey && "grayscale"}`}
        >
          <img
            src={RATINGS_SRC[formattedRatingsKey]}
            alt=""
            width="auto"
            height="auto"
          />
        </div>
      ))}
    </div>
  );
};
export default Ratings;
