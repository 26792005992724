import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
  JOB_OPPORTUNITY_STATUSES,
} from "lookup";
import { getCardClassName } from "components/UserCardList/helpers/userCard";

import { JobsContext, MatchContext, ModalContext } from "context/providers";

import {
  HitActionButtons,
  CollapsibleSection,
  MatchActionButtons,
  MatchedDetails,
  Status,
  UserContacts,
  UserInfo,
} from "./molecules";

const UserCardResult = ({
  activeTabName,
  hit,
  showActions,
  showStatusColor,
  collectionKey,
}) => {
  const { showModal } = useContext(ModalContext);
  const { createMatch, updateMatch, removeMatch } = useContext(MatchContext);
  const { jobOpp } = useContext(JobsContext);

  const [isLoading, setIsLoading] = useState({
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
    [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.NULL]: false,
    [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]: false,
    [JOB_APPLICATION_MATCH_STATUS.APPLIED]: false,
    isCalibration: false,
  });

  const { applicationId, jobOpportunityId } = useMemo(() => {
    const app = hit.applications?.find(
      (a) => a.jobTypeId === jobOpp.jobType?.id
    );

    return {
      applicationId: hit?.match?.applicationId || app?.id,
      jobOpportunityId: jobOpp?.id,
    };
  }, [hit, jobOpp]);

  const handleMatchAction = async (args, isUpdate) => {
    if (args.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) {
      showModal({
        type: "match",
        applicationId,
        jobOpportunityId,
        userId: hit.id,
        collectionKey,
        status: args.status,
      });
      return;
    }

    if (args.isCalibration) {
      showModal({
        type: "calibration",
        applicationId,
        jobOpportunityId,
        userId: hit.id,
        collectionKey,
        status: args.status,
        isCalibration: args.isCalibration,
        isUpdate,
      });
      return;
    }
    setIsLoading((prev) => ({ ...prev, [args.status]: true }));

    try {
      await createMatch(applicationId, jobOpportunityId, hit.id, args);
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [args.status]: false }));
    }
  };

  const updateExistingMatch = async (args) => {
    if (args?.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) {
      showModal({
        type: "match",
        applicationId,
        jobOpportunityId,
        collectionKey,
        status: args?.status,
        hitStatus: hit.match?.status,
        isUpdate: true,
      });
      return;
    }

    if (args.isCalibration) {
      showModal({
        type: "calibration",
        applicationId,
        jobOpportunityId,
        collectionKey,
        isCalibration: args.isCalibration,
        isUpdate: true,
      });
      return;
    }
    setIsLoading((prev) => ({
      ...prev,
      [args.status ||
      (args.subStatus !== undefined ? args.subStatus : "isCalibration")]: true,
    }));

    try {
      await updateMatch(applicationId, jobOpportunityId, args);
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        [args.status ||
        (args.subStatus !== undefined
          ? args.subStatus
          : "isCalibration")]: false,
      }));
    }
  };

  const removeExistingMatch = async () => {
    const confirmRemove = window.confirm(
      `Are you sure you want to delete ${hit.username}?`
    );

    if (confirmRemove) {
      setIsLoading((prev) => ({
        ...prev,
        [JOB_APPLICATION_MATCH_STATUS.DELETED]: true,
      }));

      try {
        await removeMatch(applicationId, jobOpportunityId);
      } catch (error) {
        console.log("removeExistingMatch error: ", error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
        }));
      }
    }
  };

  return (
    <div
      className={classNames("rounded-t-lg flex flex-col bg-white font-rubik", {
        ...getCardClassName(hit, showStatusColor),
      })}
    >
      <div className="rounded-t-lg flex flex-wrap md:flex-nowrap border border-[#E5E7EB] p-4 box-border w-full gap-x-2">
        <div>
          <UserContacts hit={hit} />
        </div>

        <div className="w-full">
          <UserInfo
            hit={hit}
            collectionKey={collectionKey}
            activeTabName={activeTabName}
          />

          <CollapsibleSection activeTabName={activeTabName} hit={hit} />
        </div>
      </div>

      <div className="rounded-b-lg border-t border-grey-900 py-3 bg-gray-50">
        {(hit.associatedMatch || hit?.match?.applicationId) && (
          <>
            <div className="pl-[148px]">
              {hit?.match?.applicationId && (
                <p className="mb-3">Matched Details</p>
              )}
              <div className="flex items-start mb-3 gap-3">
                <div className="grid grid-cols-1 gap-3">
                  {(hit.match?.isCalibration ||
                    hit.associatedMatch?.isCalibration) && (
                    <div
                      className={classNames(
                        "flex justify-center w-min px-2 items-center text-sm font-bold font-rubik text-grey-900 whitespace-nowrap border-l-4",
                        "border-l-4 border-blue-300"
                      )}
                    >
                      <p>Calibration</p>
                    </div>
                  )}
                </div>
                {hit?.match?.applicationId &&
                  hit.match?.calibrationRate?.value > 0 && (
                    <p className="text-sm">
                      <span className="font-bold">Calibration rate: </span>$
                      {hit.match?.calibrationRate?.value}
                    </p>
                  )}
              </div>
              <div className="flex items-start gap-3">
                <div className="grid grid-cols-1 gap-3">
                  <Status
                    matchStatus={
                      hit?.match?.applicationId
                        ? hit.match?.status
                        : hit.associatedMatch.status
                    }
                  />
                  <Status
                    matchStatus={
                      hit?.match?.applicationId
                        ? hit.match?.subStatus
                        : hit.associatedMatch.subStatus
                    }
                  />
                </div>
                {hit?.match?.applicationId && (
                  <MatchedDetails hit={hit} match={hit.match} />
                )}
              </div>
            </div>

            <hr className="border-t border-grey-900 my-6" />
          </>
        )}

        <div className="flex justify-center min-h-[45px]">
          {hit?.match?.applicationId ? (
            <MatchActionButtons
              match={hit.match}
              isLoading={isLoading}
              isJobStatusActive={
                jobOpp.status === JOB_OPPORTUNITY_STATUSES.ACTIVE
              }
              activeTabName={activeTabName}
              updateMatch={updateExistingMatch}
              removeMatch={removeExistingMatch}
            />
          ) : (
            <HitActionButtons
              hit={hit}
              isLoading={isLoading}
              showActions={showActions}
              matchAction={handleMatchAction}
              jobType={jobOpp.jobType}
              isJobStatusActive={
                jobOpp.status === JOB_OPPORTUNITY_STATUSES.ACTIVE
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

UserCardResult.propTypes = {
  hit: PropTypes.object.isRequired,
  showActions: PropTypes.bool,
  showStatusColor: PropTypes.bool,
  collectionKey: PropTypes.string,
  activeTabName: PropTypes.string,
};

UserCardResult.defaultProps = {
  showActions: false,
  showStatusColor: false,
  collectionKey: "hits",
  activeTabName: "ALLUSERS",
};

export default UserCardResult;
