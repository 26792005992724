import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import classNames from "classnames";

import HelpText from "components/HelpText";

const Input = ({
  label,
  value,
  placeholder,
  valueKey,
  successMessage,
  errorMessage,
  helpText,
  isRequired,
  isLoading,
  maxLength,
  onChange,
  onBlur,
  className,
  labelClassName,
  inputClassName,
  predictions,
  excludedPredictions,
  readOnly,
  type,
  autoFocus,
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const [internalPredictions, setInternalPredictions] = useState([]);
  const [internalExcludedPredictions, setInternalExcludedPredictions] =
    useState(excludedPredictions);

  const id = useMemo(nanoid, []);

  const getIsChangingAllowed = (value) => {
    if (maxLength > 0 && value.length > maxLength) {
      return false;
    }

    return true;
  };

  const getLabel = () => {
    if (isRequired) {
      return `${label}*`;
    }

    return label;
  };

  const filterPredictions = (value) => {
    const filteredPredictions = predictions.filter(
      (el) =>
        !internalExcludedPredictions.includes(el.toLowerCase()) &&
        el.toLowerCase().includes(value.toLowerCase())
    );

    setInternalPredictions(filteredPredictions);
  };

  const handleChange = ({ target: { value } }) => {
    if (!getIsChangingAllowed(value)) {
      return;
    }

    setInternalValue(value);
    onChange({ value, valueKey });

    if (!!predictions.length) {
      filterPredictions(value);
    }
  };

  const handlePredictionClick = (value) => {
    if (!getIsChangingAllowed(value)) {
      return;
    }

    onChange({ value, valueKey });
    setInternalPredictions([]);

    setInternalExcludedPredictions((prev) => {
      const output = Array.from(new Set([...prev, value]));

      return output;
    });
  };

  const handleBlur = ({ target: { value } }) => {
    onBlur({ value, valueKey });
  };

  const renderPredictions = () => {
    return (
      <div className="shadow w-full bg-white rounded rounded-t-none absolute top-[100%]">
        {internalPredictions.map((prediction, index) => {
          return (
            <p
              key={prediction}
              onClick={() => handlePredictionClick(prediction)}
              className={classNames(
                "p-2 cursor-pointer hover:bg-gray-100 transition-all select-none",
                {
                  "border-t": index > 0,
                }
              )}
            >
              {prediction}
            </p>
          );
        })}
      </div>
    );
  };

  useEffect(() => setInternalValue(value), [value]);

  useEffect(
    () => setInternalExcludedPredictions(excludedPredictions),
    [excludedPredictions]
  );

  return (
    <div className={classNames("flex flex-col mb-4 relative", className)}>
      {!!label && (
        <label
          htmlFor={id}
          className={classNames("mb-1 flex whitespace-nowrap", labelClassName)}
        >
          {getLabel()}

          {helpText && <HelpText text={helpText} className="ml-2" />}
        </label>
      )}

      <div className="w-full flex flex-col relative">
        <input
          id={id}
          className={classNames(
            "border-2 outline-none p-2 rounded transition-all focus:border-gray-300",
            inputClassName,
            {
              "rounded-b-none": !!internalPredictions.length,
              "cursor-not-allowed": readOnly || isLoading,
              "animate-pulse": isLoading,
            }
          )}
          value={internalValue}
          placeholder={placeholder}
          readOnly={readOnly || isLoading}
          disabled={readOnly || isLoading}
          type={type}
          autoFocus={autoFocus}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {!!internalPredictions.length && !!value && renderPredictions()}

        <p
          className={classNames(
            "text-red-500 text-sm font-bold mt-1 absolute transition-all",
            {
              "top-[90%] opacity-0": !errorMessage,
              "top-[100%]": !!errorMessage,
            }
          )}
        >
          {errorMessage}
        </p>

        <p
          className={classNames(
            "text-green-500 text-sm font-bold mt-1 absolute transition-all",
            {
              "top-[90%] opacity-0": !successMessage,
              "top-[100%]": !!successMessage,
            }
          )}
        >
          {successMessage}
        </p>
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  helpText: PropTypes.string,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  predictions: PropTypes.array,
  excludedPredictions: PropTypes.array,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
};

Input.defaultProps = {
  label: "",
  placeholder: "",
  errorMessage: "",
  successMessage: "",
  helpText: "",
  isRequired: false,
  isLoading: false,
  maxLength: 0,
  className: "",
  labelClassName: "",
  inputClassName: "",
  onChange: () => {},
  onBlur: () => {},
  predictions: [],
  excludedPredictions: [],
  readOnly: false,
  type: "default",
  autoFocus: false,
};

export default Input;
