import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  jobOpp: [],
  jobOpps: [],
  similarJobsFilters: {},
  loadingJobOpp: true,
  isLoading: true,
  companyFilter: "All",
  companyNames: [],
  jobSkills: [],
  jobSkillsFilter: "All",
  jobTypeFilter: "All",
  statusFilter: ["ACTIVE", "PENDINGAPPROVAL"],
  jobTypes: [],
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.jobs.JOB_OPP_LOADED: {
      draft.jobOpp = payload.jobOpp;
      draft.loadingJobOpp = false;
      break;
    }

    case contextConstants.jobs.JOB_OPP_CLEARED: {
      draft.jobOpp = {};
      draft.loadingJobOpp = true;
      break;
    }

    case contextConstants.jobs.JOB_OPPS_LOADING: {
      draft.isLoading = payload.isLoading;
      break;
    }

    case contextConstants.jobs.JOB_OPPS_LOADED: {
      draft.jobOpps = payload.jobOpps;
      draft.isLoading = false;
      break;
    }

    case contextConstants.jobs.JOB_TYPES_LOADED: {
      draft.jobTypes = payload.jobTypes;
      break;
    }

    case contextConstants.jobs.JOB_SKILLS_LOADED: {
      draft.jobSkills = payload.jobSkills;
      break;
    }

    case contextConstants.jobs.COMPANY_NAMES_LOADED: {
      draft.companyNames = payload.companyNames;
      break;
    }

    case contextConstants.jobs.COMPANY_FILTER_UPDATED: {
      draft.companyFilter = payload.companyFilter;
      break;
    }

    case contextConstants.jobs.JOB_TYPE_FILTER_UPDATED: {
      draft.jobTypeFilter = payload.jobTypeFilter;
      break;
    }

    case contextConstants.jobs.JOB_SKILLS_FILTER_UPDATED: {
      draft.jobSkillsFilter = payload.jobSkillsFilter;
      break;
    }

    case contextConstants.jobs.STATUS_FILTER_UPDATED: {
      draft.statusFilter = payload.statusFilter;
      break;
    }

    case contextConstants.jobs.JOB_FILTERS_CLEARED: {
      draft.companyFilter = "All";
      draft.jobTypeFilter = "All";
      draft.jobSkillsFilter = "All";
      draft.statusFilter = ["All"];
      break;
    }

    case contextConstants.jobs.JOB_OPP_CALENDAR_EVENTS_LOADED: {
      draft.jobCalendarEvents = payload.jobCalendarEvents;
      break;
    }

    case contextConstants.jobs.SIMILAR_JOB_OPPS_FILTERS_LOADED: {
      draft.similarJobsFilters = { ...payload };
      break;
    }

    case contextConstants.jobs.SIMILAR_JOB_OPP_FILTER_CHANGE: {
      draft.similarJobsFilters = {
        ...draft.similarJobsFilters,
        [payload.key]: payload.value,
      };
      break;
    }

    default:
      break;
  }
});
