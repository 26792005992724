import Base from "./Base.js";

import {
  getFieldHistory,
  listCoderbyteAssessments,
} from "./graphql/queries.js";

export default class CommonAPI extends Base {
  getFieldHistory(payload) {
    return this.apiClient.request({ query: getFieldHistory, payload });
  }

  listCoderbyteAssessments() {
    return this.apiClient.request({ query: listCoderbyteAssessments });
  }
}
