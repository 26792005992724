/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import qs from "query-string";

import { APPLICATION_TAB_NAMES, JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { MatchContext, SearchContext } from "context/providers";
import { mapHits } from "utils/helpers/search";
import { mapMatchesToHit } from "utils/helpers/match";

import UserCardList from "components/UserCardList";
import Tabs from "components/base/Tabs";

const ApplicationsTabs = () => {
  const { clearSearchState } = useContext(SearchContext);
  const { matches, bestFit } = useContext(MatchContext);
  const { hits } = useContext(SearchContext);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const renderCustomNoResults = (collectionKey) => () => {
    switch (collectionKey) {
      case "hits": {
        return (
          <p>
            There are no applicants to display. Please{" "}
            <a
              className="text-sky-500 cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
              onClick={clearSearchState}
            >
              Clear Filters
            </a>{" "}
            or change filter criteria
          </p>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  const filtersConfig = {
    Matched: {
      excludedMatchStatuses: [
        JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
        JOB_APPLICATION_MATCH_STATUS.SKIPPED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK,
      ],
    },
    Shortlisted: {
      matchStatus: [
        JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK,
      ],
    },
    Skipped: {
      matchStatus: [JOB_APPLICATION_MATCH_STATUS.SKIPPED],
    },
  };

  const getItemsCount = (matchStatus, excludedMatchStatuses, tabName) => {
    switch (tabName) {
      case "All Users": {
        return mapHits(hits, matches)?.length;
      }

      case "Best Fit": {
        return mapHits(bestFit, matches)?.length;
      }

      case "Matched":
      case "Shortlisted":
      case "Skipped": {
        return mapMatchesToHit(
          matches,
          matchStatus,
          false,
          excludedMatchStatuses
        )?.length;
      }

      case "Calibration": {
        return mapMatchesToHit(
          matches,
          matchStatus,
          true,
          excludedMatchStatuses
        )?.length;
      }

      default: {
        return 0;
      }
    }
  };

  const setCount = (item) => {
    const count = getItemsCount(
      item.matchStatus,
      item.excludedMatchStatuses,
      item.title
    );

    return { ...item, count };
  };

  const updateQs = (index) => {
    const queryString = qs.stringify({
      ...qs.parse(location.search),
      tab: index + 1,
    });

    navigate(`${location.pathname}?${queryString}`, { replace: true });
  };

  const tabsContent = useMemo(() => {
    const tabs = [
      {
        title: APPLICATION_TAB_NAMES.ALLUSERS,
        icon: "users",
        type: "custom",
        updateQs,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="hits"
            activeTabName={activeTabName}
            renderCustomNoResults={renderCustomNoResults("hits")}
            customFilter={[
              "hideSkipped",
              "hideRejected",
              "hideMatchedAndApplied",
            ]}
            showGeographicalFilter
            showStatusColor
            showActions
            pagination
            stats
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.MATCHED,
        icon: "heart",
        type: "custom",
        excludedMatchStatuses: filtersConfig["Matched"].excludedMatchStatuses,
        updateQs,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            noResultsMessage="There are no Matched applicants to display"
            filter={["status", "subStatus"]}
            excludedMatchStatuses={
              filtersConfig["Matched"].excludedMatchStatuses
            }
            allowSorting
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.CALIBRATION,
        icon: "calibration",
        type: "custom",
        updateQs,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            noResultsMessage="There are no Calibrations to display"
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.SHORTLISTED,
        icon: "focus",
        type: "custom",
        updateQs,
        matchStatus: filtersConfig["Shortlisted"].matchStatus,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            filter={["status"]}
            noResultsMessage="There are no Shortlisted applicants to display"
            matchStatus={filtersConfig["Shortlisted"].matchStatus}
            allowSorting
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.SKIPPED,
        icon: "skip",
        type: "custom",
        matchStatus: filtersConfig["Skipped"].matchStatus,
        updateQs,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            noResultsMessage="There are no Skipped applicants to display"
            matchStatus={filtersConfig["Skipped"].matchStatus}
            allowSorting
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.BESTFIT,
        icon: "calculator",
        type: "custom",
        updateQs,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="bestFit"
            activeTabName={activeTabName}
            noResultsMessage="The system is unable to find a best fit candidate for this job"
            showStatusColor
            showActions
            filterOutHitByMatchStatus={[JOB_APPLICATION_MATCH_STATUS.SKIPPED]}
          />
        ),
      },
    ];

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialIndex = () => {
    let initialIndex = params.get("tab");

    if (initialIndex) {
      initialIndex = parseInt(initialIndex) - 1;

      if (initialIndex <= tabsContent.length - 1) {
        return initialIndex;
      }
    }
    return 0;
  };

  return (
    <Tabs
      items={tabsContent.map((item) => setCount(item))}
      className="h-full"
      initialIndex={getInitialIndex()}
    />
  );
};

export default ApplicationsTabs;
