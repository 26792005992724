import { Auth } from "aws-amplify";

export async function initUser() {
  const user = await Auth.currentUserInfo();

  return user;
}

export async function initUserTypeValues() {
  const auth = await Auth.currentSession();
  const groups = auth.getAccessToken().payload["cognito:groups"] || [];
  const isManager = groups.includes(
    process.env.REACT_APP_COGNITO_GROUP_USER_MANAGERS
  );
  const isAdmin = groups.includes(process.env.REACT_APP_COGNITO_ADMIN_GROUP);

  const isPremiumCustomers = groups.includes(
    process.env.REACT_APP_COGNITO_GROUP_PREMIUM_CUSTOMERS
  );

  return { isManager, isAdmin, isPremiumCustomers };
}
