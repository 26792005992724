import React from "react";
import PropTypes from "prop-types";

import { USER_TYPES } from "lookup";

import ImageViewer from "components/ImageViewer";
import classNames from "classnames";

const DummyPhoto = () => <div className="w-full h-full bg-[#D9D9D9]" />;

const UserContacts = ({ hit }) => {
  const renderCustomerTag = () => {
    if (hit.userType !== USER_TYPES.CUSTOMER) {
      return null;
    }
    return (
      <div className="w-[90%] mt-4 flex flex-col gap-y-1">
        <p className="text-center bg-[#0495b7] text-white rounded">Customer</p>
        <p className="text-center truncate">{hit.company}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-none items-center w-full max-w-[125px]">
      <div className="flex flex-col gap-y-2 p-2">
        <div className="w-[100px] h-[100px] overflow-hidden">
          {hit.headshotKey ? (
            <ImageViewer
              objectKey={hit.headshotKey}
              width={100}
              responsive={false}
            />
          ) : (
            <DummyPhoto />
          )}
        </div>
        <div className="w-full bg-[#D9D9D9] rounded-full h-2">
          <div
            className={classNames(
              "h-2 rounded-full",
              {
                "bg-red-500":
                  hit.profileCompletion >= 0 && hit.profileCompletion < 50,
              },
              {
                "bg-sky-500":
                  hit.profileCompletion >= 50 && hit.profileCompletion < 100,
              },
              {
                "bg-green-500": hit.profileCompletion >= 100,
              }
            )}
            style={{ width: `${hit.profileCompletion}%` }}
          ></div>
        </div>
        <p
          className={classNames(
            "text-sm",
            {
              "text-red-500":
                hit.profileCompletion >= 0 && hit.profileCompletion < 50,
            },
            {
              "text-sky-500":
                hit.profileCompletion >= 50 && hit.profileCompletion < 100,
            },
            {
              "text-green-500": hit.profileCompletion >= 100,
            }
          )}
        >
          {hit.profileCompletion || 0}% Complete
        </p>
      </div>

      {renderCustomerTag()}
    </div>
  );
};

UserContacts.propTypes = {
  hit: PropTypes.object,
};

UserContacts.defaultProps = {
  hit: {},
};

export default UserContacts;
