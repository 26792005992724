import React, { useContext, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { CSVDownload } from "react-csv";
import dayjs from "dayjs";

import { prepareQuery } from "utils/helpers/search";
import { prepareHourlyRate } from "./UserCardList/helpers/userCard";

import { JobsContext, SearchContext } from "context/providers";
import { USER_STATUS } from "lookup";
import SvgButton from "./base/SvgButton";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const searchIndex = searchClient.initIndex(process.env.REACT_APP_INDEX_NAME);

export default function DownloadResults({ className }) {
  const searchState = useContext(SearchContext);
  const { jobOpp } = useContext(JobsContext);
  const [preparingCSV, setPreparingCSV] = useState(false);
  const [exportCSV, setExportCSV] = useState(null);

  const prepareLocation = (location) => {
    if (location?.countryName) {
      return location.countryName;
    }

    return "";
  };

  const prepareAvailability = (availability) => {
    if (!availability) return "";
    return availability;
  };

  const prepareLangs = (langs) => {
    if (!langs || !langs?.length) {
      return "Not Specified";
    }

    const preparedArray = langs.map(
      (lang) =>
        `${lang.language} (${lang.level[0]}${lang.level
          .slice(1)
          .toLowerCase()})`
    );

    return preparedArray.join(", ");
  };

  const exportAsCSV = async () => {
    const filters = prepareQuery(searchState, jobOpp.jobType?.title);

    setPreparingCSV(true);

    try {
      const results = [];
      let indexing = true;
      let page = 0;
      while (indexing) {
        const data = await searchIndex.search(
          searchState.searchState?.query || "",
          {
            attributesToRetrieve: [
              "username",
              "given_name",
              "family_name",
              "profileCompletion",
              "location",
              "availability",
              "ratePerHour",
              "knownLanguages",
              "createdAt",
            ],
            hitsPerPage: 1000,
            ...filters,
            filters: `status:${USER_STATUS.ACTIVE}${searchState.searchState.skillsStrFilter}`,
            page,
          }
        );

        if (data?.hits?.length === 0) {
          indexing = false;
        }

        results.push(...data.hits);
        page++;
      }

      setExportCSV(
        results.map((h) => ({
          username: h.username,
          given_name: h.given_name,
          family_name: h.family_name,
          profileCompletion: h.profileCompletion || 0,
          location: prepareLocation(h.location),
          availability: prepareAvailability(h?.availability),
          ratePerHour: prepareHourlyRate(h?.ratePerHour),
          knownLanguages: prepareLangs(h.knownLanguages),
          createdAt: dayjs(h.createdAt).format("MM/DD/YYYY"),
        }))
      );

      setPreparingCSV(false);
    } catch (e) {
      console.log(e);
      alert("An error occurred preparing the data for download. Try again.");
      setExportCSV(null);
      setPreparingCSV(false);
    }
  };

  return (
    <>
      <SvgButton
        icon="downloadFile"
        className={className}
        onClick={exportAsCSV}
        disabled={preparingCSV}
      />
      {!preparingCSV && exportCSV && (
        <CSVDownload data={exportCSV} target="_blank" />
      )}
    </>
  );
}
