import React from "react";
import PropTypes from "prop-types";

import {
  getButtonClassName,
  getButtonText,
} from "components/UserCardList/helpers/userCard";
import classNames from "classnames";
import SvgIcon from "components/base/SvgIcon";

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const ActionButton = ({
  isDisabled,
  isLoading,
  action,
  title,
  className,
  isUpdate,
  type,
  children,
  iconType,
  iconFill,
}) => {
  return (
    <button
      className={classNames(
        DEFAULT_BUTTON_CLASS_NAME,
        className,
        getButtonClassName(type, isLoading, null, isDisabled)
      )}
      disabled={isDisabled}
      onClick={action}
      title={title}
    >
      <SvgIcon type={iconType} fill={iconFill} className="w-[20px] h-[20px]" />
      {children}

      <span className="ml-2">{getButtonText(type, isLoading, isUpdate)}</span>
    </button>
  );
};

ActionButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdate: PropTypes.bool,
  isCustom: PropTypes.bool,
  action: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

ActionButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isUpdate: false,
  isCustom: false,
  action: () => {},
  title: "",
  type: "",
  className: "",
};

export default ActionButton;
