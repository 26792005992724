import React, { useContext } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
  formatTalentSource,
  prepareAvailability,
  prepareHourlyRate,
  prepareLocation,
} from "components/UserCardList/helpers/userCard";

import { ModalContext, SearchContext } from "context/providers";

import CountryFlag from "components/CountryFlag";
import UserActionIcons from "./UserActionIcons";
import HelpText from "components/HelpText";

import MatchStatusHistoryInfo from "./MatchStatusHistoryInfo";
import CognitoGroups from "./CognitoGroups";
import Referrer from "./Referrer";

const UserInfo = ({ hit, collectionKey, activeTabName }) => {
  const { showModal } = useContext(ModalContext);
  const { updateHit } = useContext(SearchContext);

  const renderLanguages = (languages) => {
    if (!languages || !languages?.length) {
      return "Not Specified";
    }

    return languages.map((lang, langIndex) => {
      return (
        <span key={lang.language}>
          {langIndex > 0 && ", "}
          {lang.language}
          {`(${lang.level[0]}${lang.level.slice(1).toLowerCase()})`}
        </span>
      );
    });
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="mb-2 w-full">
        <div className="flex flex-wrap md:flex-nowrap justify-between w-full">
          <div>
            <p className="text-text-grey-900 text-2xl whitespace-nowrap font-bold">
              {`${hit.given_name} ${hit.family_name}`}
            </p>
          </div>
          <div>
            <UserActionIcons
              hit={hit}
              collectionKey={collectionKey}
              activeTabName={activeTabName}
              updateHit={updateHit}
              showModal={showModal}
            />
            {hit?.reasonsForMatch && (
              <div className="flex justify-end gap-4 mr-2">
                <HelpText
                  iconType="text"
                  iconText="Why Fit?"
                  className="flex justify-start"
                  iconTextClassName="whitespace-nowrap  !text-sky-500"
                >
                  <ul className="max-w-md space-y-1 text-black font-rubik text-normal list-disc list-inside normal-case">
                    {hit.reasonsForMatch.map((el) => {
                      return (
                        <li key={el} className="font-rubik font-normal text-sm">
                          {el}
                        </li>
                      );
                    })}
                  </ul>
                </HelpText>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2">
          <p className="gap-2">
            {prepareLocation(hit.location) || hit.locale}{" "}
          </p>
          {hit.location?.countryCode?.toLowerCase() && (
            <CountryFlag
              className="h-[35px] w-[35px] rounded-full p-2"
              country={hit.location.countryCode.toLowerCase()}
              width={48}
              height={36}
            />
          )}
        </div>

        <div className="flex gap-x-3 flex-wrap items-start text-sm text-grey-900 opacity-40">
          {hit.username && (
            <p>
              <a
                className="cursor-pointer font-bold hover:opacity-60 transition-all"
                href={`${process.env.REACT_APP_DOMAIN}/#/profile/${hit.username}`}
                target="_blank"
                rel="noreferrer"
              >
                @{hit.username || hit.identity_username}
              </a>{" "}
            </p>
          )}
          <span>&#183;</span>{" "}
          <p>
            Member since:{" "}
            <span className="font-normal">
              {`${dayjs(hit.createdAt).format("MM/DD/YYYY")}`}
            </span>
            <Referrer referralCode={hit.referrerCode} key={hit.referrerCode} />
            {hit?.torcQualityScore && (
              <p>
                Match Ready Score:{" "}
                <span className="font-normal">
                  {`${hit.torcQualityScore}`}
                </span>
              </p>
            )}
          </p>
        </div>
        <div className="flex flex-col gap-2 pl-2 mt-2">
          <MatchStatusHistoryInfo
            hit={hit}
            collectionKey={collectionKey}
            configStatusFields={{
              SKIPPED: {
                title: "Previously Skipped",
                iconType: "cancel",
                hitFieldName: "previouslySkipped",
              },
            }}
          />

          <CognitoGroups hit={hit} collectionKey={collectionKey} />
        </div>
      </div>

      <p className="text-sm font-bold">
        Hourly Rate:{" "}
        <span className="font-normal text-grey-800">
          {prepareHourlyRate(hit.ratePerHour)}
        </span>
      </p>

      <p className="text-sm font-bold">
        Availability:{" "}
        <span className="font-normal text-grey-800">
          {prepareAvailability(hit.availability)}
        </span>
      </p>

      <p className="text-sm font-bold">
        Known Languages:{" "}
        <span className="font-normal text-grey-800">
          {renderLanguages(hit.knownLanguages)}
        </span>
      </p>

      {hit?.talentSource && (
        <p className="text-sm font-bold">
          Talent Source & Markup:{"  "}
          <span
            className="font-normal text-grey-800"
            title="Talent markup rounded to 2 decimal places"
          >
            {formatTalentSource(hit)}
          </span>
        </p>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  hit: PropTypes.object,
};

UserInfo.defaultProps = {
  hit: {},
};

export default UserInfo;
