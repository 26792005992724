import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  activeModal: null,
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.modal.MODAL_SHOWED: {
      draft.activeModal = payload.activeModal;
      break;
    }

    case contextConstants.modal.MODAL_HIDE: {
      draft.activeModal = null;
      break;
    }

    default: {
      break;
    }
  }
});
