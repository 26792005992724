import React from "react";

import { JobsContext, JobsProvider } from "context/providers/JobsProvider";

import {
  SearchContext,
  SearchProvider,
} from "context/providers/SearchProvider";

import { AlertContext, AlertProvider } from "context/providers/AlertProvider";
import { NotesContext, NotesProvider } from "context/providers/NotesProvider";
import { AuthContext, AuthProvider } from "context/providers/AuthProvider";
import { MatchContext, MatchProvider } from "context/providers/MatchProvider";
import { ModalContext, ModalProvider } from "context/providers/ModalProvider";

const RootProvider = ({ children }) => {
  return (
    <AlertProvider>
      <AuthProvider>
        <ModalProvider>
          <JobsProvider>
            <SearchProvider>
              <MatchProvider>
                <NotesProvider>{children}</NotesProvider>
              </MatchProvider>
            </SearchProvider>
          </JobsProvider>
        </ModalProvider>
      </AuthProvider>
    </AlertProvider>
  );
};

export {
  AlertContext,
  JobsContext,
  SearchContext,
  NotesContext,
  AuthContext,
  MatchContext,
  ModalContext,
};

export default RootProvider;
