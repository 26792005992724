import { useRef, useEffect } from "react";
import { createPortal } from "react-dom";

/**
 * @param onClose a function passed in to close the modal
 */

const Modal = ({ children, onClose }) => {
  const parentRef = useRef();

  const handleKeyPress = ({ keyCode }) => {
    if (keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keyup", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function useCloseModalOnOffClick(ref) {
    useEffect(() => {
      //checks for clicks outside of the content of the modal
      function handleClickOutside(event) {
        const content = ref.current.children[0];
        if (ref.current && !content.contains(event.target)) {
          onClose();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useCloseModalOnOffClick(parentRef);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  });

  return createPortal(
    <div
      ref={parentRef}
      className="fixed inset-0 grid place-items-center z-50"
      style={{
        background: `rgba(0, 0, 0, 0.3)`,
        height: "100%",
        width: "100%",
      }}
    >
      <div
        className="overflow-y-auto rounded-lg shadow-lg"
        style={{ maxHeight: "95%" }}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
