import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SvgIcon from "components/base/SvgIcon";

const DEFAULT_BUTTON_CLASS_NAME =
  "relative transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const ActionButton = ({
  isDisabled,
  isLoading,
  action,
  title,
  text,
  loadingText,
  className,
  children,
  iconType,
  iconFill = "#075985",
}) => {
  const [loading, setLoading] = useState(isLoading || false);
  const handleClick = async (e) => {
    setLoading(true);
    await action(e);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const disabled = useMemo(() => {
    if (loading || isDisabled) return true;
  }, [loading, isDisabled]);

  return (
    <button
      className={classNames(
        DEFAULT_BUTTON_CLASS_NAME,
        { "opacity-30": disabled },
        { "animate-pulse": loading },
        className
      )}
      disabled={disabled}
      onClick={handleClick}
      title={title}
    >
      {iconType && (
        <SvgIcon
          type={iconType}
          fill={iconFill}
          className="w-[20px] h-[20px] mr-2"
        />
      )}

      {children}

      <span className="w-full text-center">{loading ? loadingText : text}</span>
    </button>
  );
};

ActionButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  action: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  iconType: PropTypes.string,
  iconFill: PropTypes.string,
  className: PropTypes.string,
};

ActionButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  action: () => {},
  title: "",
  text: "",
  loadingText: "",
  className: "",
};

export default ActionButton;
