import { cloneDeep } from "lodash";

import { LANGUAGE_KEY_NAMES, SOCIAL_LINK_TYPES } from "lookup";

const prepareLangs = (languages = undefined, languageLevels = undefined) => {
  const output = [];

  switch (true) {
    case !!languages && !!languageLevels: {
      languages.forEach((lang) => {
        languageLevels.forEach((level) => {
          output.push(`knownLanguages_str:${lang}_${level}`);
        });
      });

      break;
    }

    case !!languages: {
      languages.forEach((lang) => {
        output.push(`knownLanguages.language:${lang}`);
      });

      break;
    }

    case !!languageLevels: {
      languageLevels.forEach((level) => {
        output.push(`knownLanguages.level:${level}`);
      });

      break;
    }

    default: {
      break;
    }
  }

  return output;
};

const getIsStateApplied = (state) => {
  let isMultirangeApplied = false;
  let isToggleApplied = false;
  let isRefinementListApplied = false;
  let isRangeApplied = false;

  if (state.multiRange) {
    isMultirangeApplied = !!Object.values(state.multiRange).filter(
      (el) => !!el.length
    ).length;
  }

  if (state.toggle) {
    isToggleApplied = !!Object.values(state.toggle).length;
  }

  if (state.refinementList) {
    isRefinementListApplied = !!Object.values(state.refinementList).filter(
      (el) => !!el.length
    ).length;
  }

  if (state.range) {
    isRangeApplied = !!Object.values(state.range).filter(
      (el) => !!el.min || !!el.max
    ).length;
  }

  return (
    isMultirangeApplied ||
    isToggleApplied ||
    isRefinementListApplied ||
    isRangeApplied
  );
};

export const prepareQuery = (state, jobTypeTitle) => {
  const filter = {};
  const isStateApplied = getIsStateApplied(state.searchState);

  if (!isStateApplied) {
    // Default state
    if (jobTypeTitle) {
      return {
        facetFilters: [
          "agreedToTerms:true",
          `activeJobTypeTitles:${jobTypeTitle}`,
        ],
      };
    }
    return {
      facetFilters: [`agreedToTerms:true`],
    };
  }

  if (state.searchState.multiRange) {
    filter.numericFilters = [];

    Object.keys(state.searchState.multiRange).forEach((key) => {
      const [min, max] = state.searchState.multiRange[key].split(":");

      if (min && max) {
        filter.numericFilters = [
          ...filter.numericFilters,
          `${key}:${min} TO ${max}`,
        ];
      }
    });
  }

  if (state.searchState.refinementList || state.searchState.toggle) {
    const filters = {
      ...(state.searchState.refinementList || {}),
      ...(state.searchState.toggle || {}),
    };

    const isThereLangFilters =
      filters[LANGUAGE_KEY_NAMES.language] || filters[LANGUAGE_KEY_NAMES.level];

    const filtersKeys = Object.keys(filters).filter(
      (key) =>
        key !== LANGUAGE_KEY_NAMES.language && key !== LANGUAGE_KEY_NAMES.level
    );

    if (!filter.facetFilters) {
      filter.facetFilters = [];
    }

    if (isThereLangFilters) {
      filter.facetFilters = [
        ...filter.facetFilters,
        prepareLangs(
          filters[LANGUAGE_KEY_NAMES.language],
          filters[LANGUAGE_KEY_NAMES.level]
        ),
      ];
    }

    filtersKeys.forEach((key) => {
      if (typeof filters[key] === "boolean" && !!filters[key]) {
        filter.facetFilters = [
          ...filter.facetFilters,
          `${key}:${filters[key]}`,
        ];
      } else if (!!filters[key]) {
        const operator = state.operators[key];
        const currentFilter =
          operator && operator === "and"
            ? filters[key].map((el) => `${key}:${el}`)
            : [filters[key].map((el) => `${key}:${el}`)];

        filter.facetFilters = [...filter.facetFilters, ...currentFilter];
      }
    });
  }

  if (state.searchState.range) {
    filter.numericFilters = [...(filter.numericFilters || [])];

    Object.keys(state.searchState.range).forEach((key) => {
      const { min, max } = state.searchState.range[key];

      filter.numericFilters = [
        ...filter.numericFilters,
        `${key}:${min || 0} TO ${max || 0}`,
      ];
    });
  }

  if (!state.searchState.toggle) {
    if (!filter.facetFilters) {
      filter.facetFilters = [];
    }

    filter.facetFilters.push(`agreedToTerms:true`);
  }

  if (jobTypeTitle) {
    if (!filter.facetFilters) {
      filter.facetFilters = [];
    }

    filter.facetFilters.push(`activeJobTypeTitles:${jobTypeTitle}`);
  }

  return filter;
};

const mapHit = (hit, matches) => {
  const clonedHit = cloneDeep(hit);
  const associatedMatch = matches.find((m) => m.username === hit.username);

  clonedHit.associatedMatch = associatedMatch;
  clonedHit.socialLinks = clonedHit.socialLinks
    ? clonedHit.socialLinks.filter(
        (s) => !!s.value && s.type !== SOCIAL_LINK_TYPES.CALENDAR
      )
    : [];

  return {
    ...clonedHit,
  };
};

export const mapHits = (hits, matches, filterOutHitByMatchStatus = []) => {
  const mappedHits = hits
    .map((hit) => mapHit(hit, matches))
    .filter(
      (hit) =>
        !!hit &&
        !filterOutHitByMatchStatus.includes(hit.associatedMatch?.status)
    );

  return mappedHits;
};
