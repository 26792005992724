import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { sortBy } from "lodash";
import classNames from "classnames";
import Rheostat from "rheostat";

import { AlertContext, JobsContext, MatchContext } from "context/providers";
import api from "apiSingleton";
import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
  JOB_OPPORTUNITY_STATUSES,
} from "lookup";

import { Chip } from "components/SearchFilters/components";
import SvgIcon from "components/base/SvgIcon";
import Button from "components/Button";
import SimilarJobCard from "./molecules/SimilarJobCard";
import CustomToggle from "components/base/Toggle";

const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const MATCH_STATUS_FILTER = {
  or: [
    { status: { eq: JOB_APPLICATION_MATCH_STATUS.ACCEPTED } },
    { status: { eq: JOB_APPLICATION_MATCH_STATUS.MATCHED } },
    { status: { eq: JOB_APPLICATION_MATCH_STATUS.APPLIED } },
    {
      status: {
        eq: JOB_APPLICATION_MATCH_STATUS.MOREINFO,
      },
    },
    { subStatus: { eq: JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST } },
  ],
};

const SimilarJobs = () => {
  const {
    jobOpp,
    initJob,
    loadingJobOpp,
    resetSimilarJobOppsFilters,
    updateSimilarJobsFilters,
    similarJobsFilters: filters,
  } = useContext(JobsContext);
  const { matches: jobMatches } = useContext(MatchContext);
  const { addGraphQLAlert } = useContext(AlertContext);

  const [similarJobs, setSimilarJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const hasDoneCustomSearch = useRef(false);

  const hasFilters = useMemo(() => {
    return Object.keys(filters).length > 0;
  }, [filters]);

  const getJobQuery = (jobOpp) => {
    return [
      jobOpp.title ? `title: ${jobOpp.title};` : "",
      jobOpp.jobType.title ? `job role: ${jobOpp.jobType.title}` : "",
      jobOpp.overview ? ` - overview: ${jobOpp.overview}` : "",
      jobOpp.requirements ? ` - requirements: ${jobOpp.requirements}` : "",
      jobOpp.responsibilities
        ? ` - responsibilities: ${jobOpp.responsibilities}`
        : "",
      ` - hourly rate: ${jobOpp.minRate.value}-${jobOpp.maxRate.value}`,
      jobOpp.skills?.length > 0
        ? ` - skills: ${jobOpp.skills.map(({ name }) => name).join(", ")}`
        : "",
      jobOpp.jobLengthInWeeks
        ? ` - duration ${jobOpp.jobLengthInWeeks} weeks`
        : jobOpp.jobLength
        ? ` - duration ${jobOpp.jobLength} months`
        : "",
      jobOpp.timezone?.value ? ` - timezone: ${jobOpp.timezone?.value}` : "",
      jobOpp.timeOverlap > 1 && jobOpp.timeOverlap < 8
        ? ` - time overlap: ${jobOpp.timeOverlap} hours`
        : jobOpp.timeOverlap >= 8
        ? " - time overlap: All hours"
        : " - time overlap: No Restriction",
      jobOpp.timeCommitment
        ? ` - time commitment: ${jobOpp.timeCommitment}`
        : "",
    ]
      .join("")
      .replace(/\n/g, "");
  };

  const jobOppQuery = useMemo(() => getJobQuery(jobOpp), [jobOpp]);

  const loadRecords = async (queryObj, nextToken = null) => {
    const response = await api.jobs.listMatches({
      ...queryObj.payload,
      matchNexToken: nextToken,
    });

    const records = response.data[queryObj.name];

    if (records.matches.nextToken) {
      await sleep(250);
      const recordsNextPortions = await loadRecords(
        queryObj,
        records.matches.nextToken
      );

      return [...records.matches.items, ...recordsNextPortions];
    }

    return records.matches.items;
  };

  const filterOutMatchesNotOfInterest = (similarJobs, currentJobMatches) => {
    const similarJobOpps = [...similarJobs];
    for (const similarJob of similarJobOpps) {
      if (!!similarJob.matches?.items?.length) {
        similarJob.matches.items = similarJob.matches.items.filter(
          (m) =>
            // matches that are already created in current job
            !currentJobMatches.some(
              (currentJobMatch) =>
                currentJobMatch.applicationId === m.applicationId
            ) &&
            // matches that user have an active application with job type id same as current jobOpp jobType
            m.application?.user?.applications?.items.some(
              ({ jobTypeId, isNotActive }) =>
                jobTypeId === jobOpp.jobTypeId && !isNotActive
            )
        );
      }
    }
    return similarJobOpps;
  };

  const getSimilarJobs = async (isCustomSearch) => {
    if ((isCustomSearch && !searchTerm) || !hasFilters) {
      return;
    }
    setIsLoading(true);
    let similarJobOpps = [];

    const preFilter = {
      and: [
        { minRateValue: { gte: filters.hourlyRate[0] } },
        { maxRateValue: { lte: filters.hourlyRate[1] } },
        {
          status: {
            in: [
              JOB_OPPORTUNITY_STATUSES.ACTIVE,
              JOB_OPPORTUNITY_STATUSES.FULFILLED,
            ],
          },
        },
      ],
    };

    if (filters.skills.length > 0) {
      if (filters.skillsOperator === "and") {
        const skillsNameFilter = filters.skills.map((skName) => ({
          skillsName: { eq: skName },
        }));
        preFilter.and.push(...skillsNameFilter);
      } else {
        preFilter.and.push({
          skillsName: {
            in: filters.skills,
          },
        });
      }
    }

    try {
      const response = await api.jobs.listSimilarJobs({
        query: isCustomSearch ? searchTerm : jobOppQuery,
        preFilter,
        filter: {
          vectorSearchScore: { gte: filters.vectorSearchScore },
          id: { ne: jobOpp.id },
        },
        limit: filters.limit,
        matchFilter: MATCH_STATUS_FILTER,
      });
      similarJobOpps = response.data.listSimilarJobOpportunities;
    } catch (err) {
      addGraphQLAlert(err);
    }

    for (const similarJob of similarJobOpps) {
      if (similarJob.matches.nextToken) {
        try {
          const similarJobMatches = await loadRecords(
            {
              name: "getJobOpportunity",
              payload: {
                id: similarJob.id,
                matchFilter: MATCH_STATUS_FILTER,
              },
            },
            similarJob.matches.nextToken
          );
          similarJob.matches.items = [
            ...similarJob.matches.items,
            ...similarJobMatches,
          ];
        } catch (err) {
          addGraphQLAlert(err);
        }
      }
    }
    // filter out existing matches in current job
    const filteredSimilarJobOpps = filterOutMatchesNotOfInterest(
      similarJobOpps,
      jobMatches || []
    );

    const sortedSimilarJobs = sortBy(filteredSimilarJobOpps, [
      ({ matches, status }) =>
        !!matches?.items?.length &&
        (status === JOB_OPPORTUNITY_STATUSES.ACTIVE ||
          status === JOB_OPPORTUNITY_STATUSES.FULFILLED)
          ? -1
          : 1,
    ]);

    setSimilarJobs(sortedSimilarJobs);

    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getSimilarJobs(filters.isCustomSearch);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const filteredSimilarJobs = filterOutMatchesNotOfInterest(
      similarJobs,
      jobMatches || []
    );

    const sortedSimilarJobs = sortBy(filteredSimilarJobs, [
      ({ matches, status }) =>
        !!matches?.items?.length && status === JOB_OPPORTUNITY_STATUSES.ACTIVE
          ? -1
          : 1,
    ]);

    setSimilarJobs(sortedSimilarJobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobMatches]);

  if (loadingJobOpp || !hasFilters) {
    return (
      <div className="flex justify-center pt-8">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <div className="w-full h-full py-2 px-8 flex flex-col gap-y-2">
      <div className="flex justify-between items-start">
        <button
          className="flex items-center gap-2 hover:underline disabled:opacity-75"
          onClick={() => getSimilarJobs(filters.isCustomSearch)}
          disabled={isLoading}
        >
          <p className="text-center font-semibold text-lg">Similar Jobs</p>
          <SvgIcon
            type="refresh"
            className={classNames("w-[25px] transform rotate-180", {
              "animate-spin ": isLoading,
              "animate-none": !isLoading,
            })}
          />
        </button>
        {filters.isCustomSearch ? (
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (searchTerm) {
                  getSimilarJobs(true);
                  hasDoneCustomSearch.current = true;
                }
              }}
            >
              <input
                value={searchTerm}
                type="search"
                className="border-2 px-2 h-[32px] text-sm min-w-[150px] lg:min-w-[450px] "
                placeholder="Search... (Press enter to search)"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                type="button"
                className="border-2 ml-2 px-2 h-[32px]"
                title={`Search by Job:\ntitle, job type, description, overview,\nrequirements, responsibilities, hourly rate,\njob duration, skills, time zone, time overlap and time commitment`}
                onClick={async () => {
                  updateSimilarJobsFilters("isCustomSearch", false);
                  setSearchTerm("");
                  getSimilarJobs();
                  hasDoneCustomSearch.current = false;
                }}
              >
                Default search
              </button>
            </form>
            <p className="text-slate-400 text-xs pl-1">
              Similarity score based on input search
            </p>
          </div>
        ) : (
          <Button
            className="h-[32px] !py-0"
            onClick={() => {
              updateSimilarJobsFilters("isCustomSearch", true);
            }}
          >
            Custom Search
          </Button>
        )}

        <button
          className="flex items-center gap-2 hover:underline disabled:opacity-75"
          onClick={() => resetSimilarJobOppsFilters()}
          disabled={isLoading}
        >
          <p className="text-center font-semibold text-lg">Reset filters</p>
        </button>
      </div>
      <div className="flex justify-between items-start mt-2">
        <div className="flex flex-col gap-y-6 lg:gap-y-1 w-3/4">
          <div className="flex flex-col gap-2">
            <CustomToggle
              checked={filters.skillsOperator === "and"}
              valueKey="skillsOperator"
              leftLabel="OR"
              rightLabel="AND"
              helpText="If you would like to filter multiple skills. Ex:  C# and Java. Select OR if you want to see similar jobs with either of the skill. Select AND if you would like to see the only similar jobs with both the skills."
              toolTipClassName="!translate-x-[0%]"
              onChange={(value, valueKey) => {
                updateSimilarJobsFilters(valueKey, value ? "and" : "or");
              }}
              helpTextToolTipClassName="!translate-x-[0%]"
            />
            <div className="flex gap-x-2">
              <div>
                <p className="font-medium">Required skills: </p>
              </div>

              <div className="flex flex-wrap gap-1 h-[25px]">
                {jobOpp.skills?.map(({ name }) => {
                  const isActive = filters.skills.includes(name);

                  return (
                    <Chip
                      className={classNames(
                        "!h-[28px] !py-4 !border !font-medium",
                        {
                          "!bg-sky-600 !text-white": isActive,
                        },
                        { "!text-sky-600 !border-sky-600": !isActive }
                      )}
                      key={name}
                      value={name}
                      isActive={isActive}
                      onClose={() => {
                        updateSimilarJobsFilters(
                          "skills",
                          filters.skills.filter((el) => el !== name)
                        );
                      }}
                      {...(!isActive && {
                        onClick: () => {
                          updateSimilarJobsFilters("skills", [
                            ...filters.skills,
                            name,
                          ]);
                        },
                      })}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex gap-x-4  mt-2">
            <p className="font-medium">Hourly Rate: </p>
            <div className="w-1/2 ml-2">
              <Rheostat
                min={0}
                max={250}
                values={filters.hourlyRate}
                onChange={({ values }) => {
                  updateSimilarJobsFilters("hourlyRate", [...values]);
                }}
              />
              <div className="rheostat-values">
                <div>{`$${filters.hourlyRate[0]}`}</div>
                <div>{`$${filters.hourlyRate[1]}`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {similarJobs.map((similarJob) => (
        <SimilarJobCard
          key={similarJob.id}
          similarJob={similarJob}
          jobOpp={jobOpp}
          callback={async () => {
            await initJob(jobOpp.id);
          }}
          isLoading={isLoading}
          chipScoreTitle={
            filters.isCustomSearch && hasDoneCustomSearch.current
              ? "Similarity based on input search"
              : "Similarity based on Job:\ntitle, job type, description, overview,\nrequirements, responsibilities, hourly rate,\njob duration, skills, time zone, time overlap and time commitment"
          }
        />
      ))}

      {similarJobs.length === 0 && !isLoading && (
        <p className="text-center text-red-400 text-xl mt-8">
          No Results found
        </p>
      )}
    </div>
  );
};

export default SimilarJobs;
