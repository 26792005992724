import React, { useEffect } from "react";
import { Image, Transformation, Placeholder } from "cloudinary-react";

function ImageViewer({
  objectKey,
  placeholder,
  responsive = true,
  width = "auto",
  ...props
}) {
  const [publicId, setPublicId] = React.useState(null);

  useEffect(() => {
    if (!objectKey) {
      return;
    }

    setPublicId(objectKey);
  }, [objectKey]);

  if (!objectKey) {
    return placeholder;
  }

  return (
    <Image
      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
      secure={true}
      upload_preset={process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}
      publicId={publicId}
      width={width}
      responsive={responsive}
    >
      <Transformation fetch_format="auto" quality="auto" {...props} />
      <Placeholder type="vectorize" />
    </Image>
  );
}

export default ImageViewer;
