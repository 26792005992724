import React, { useState, useEffect } from "react";
import FileCloudinaryWidget from "components/CloudinaryWidget/FileCloudinaryWidget";
import SvgButton from "components/base/SvgButton";

const ResumeUpload = ({ user, save }) => {
  const [resumeDetails, setResumeDetails] = useState({
    resumeFileName: `${user.resumeLocation?.split("/")[2]}.pdf`,
    resumeLocation: user.resumeLocation,
  });

  useEffect(() => {
    if (resumeDetails.resumeLocation !== user.resumeLocation) {
      (async () => {
        await save({ resumeLocation: resumeDetails.resumeLocation });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeDetails.resumeLocation]);

  const handleResumeUpload = (resumeStorageLocation) => {
    setResumeDetails({
      resumeFileName: `${resumeStorageLocation.split("/")[2]}.pdf`,
      resumeLocation: resumeStorageLocation,
    });
  };

  return (
    <>
      {resumeDetails?.resumeLocation ? (
        <div>
          <a
            href={`https://res.cloudinary.com/${
              process.env.REACT_APP_CLOUDINARY_CLOUDNAME
            }/image/upload/v${Date.now()}/${resumeDetails.resumeLocation}.pdf`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <SvgButton icon="downloadFile" tooltip="download resume"/>
          </a>
        </div>
      ) : (
        <FileCloudinaryWidget
          onUpload={handleResumeUpload}
          identifier="resume-upload"
          label="Upload Resume"
          minImageHeight={517}
          minImageWidth={1839}
          resourceType="aspose"
          fileName={`${user.username}_resume`}
          icon="upload"
          tooltip="upload resume"
        />
      )}
    </>
  );
};

export default ResumeUpload;
