import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import classNames from "classnames";

import api from "apiSingleton";
import { JobsContext, MatchContext } from "context/providers";

import Modal from "components/Modals";
import Tabs from "components/base/Tabs";
import Metadata from "./Metadata";

const UserInfoModal = ({ user, modalTarget, onClose }) => {
  const { jobOpp } = useContext(JobsContext);
  const { updateMatch1LevelFieldLocally } = useContext(MatchContext);

  const [clonedUser, setClonedUser] = useState(null);
  const [tabsContent, setTabsContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const associatedApplication = useMemo(() => {
    if (clonedUser) {
      const app = clonedUser.applications?.find(
        (a) => a.jobTypeId === jobOpp.jobType?.id
      );

      return app || {};
    }
  }, [clonedUser, jobOpp]);

  const initClonedUser = () => {
    if (!user) {
      setClonedUser(null);

      return;
    }

    setClonedUser(cloneDeep(user));
  };

  const sortByDate = (items) =>
    items.sort((a, b) =>
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
    );

  const prepareDate = (createdAt) => {
    const createdDaysAgo = dayjs().diff(createdAt, "days");
    const dateFormat =
      createdDaysAgo >= 2 ? "MMM DD YYYY" : "MMM DD YYYY, hh:mm";

    return dayjs(createdAt).format(dateFormat);
  };

  const CenteredHyphen = () => {
    return <p className="text-center">-</p>;
  };

  const initTabsContent = async () => {
    const contentByTarget = {};
    const applicationId = clonedUser.applicationId || associatedApplication.id;
    const jobOpportunityId = clonedUser.jobOpportunityId || jobOpp.id;

    const userActivityHistory = await api.user.getActivityHistory({
      userId: clonedUser.id || clonedUser.application?.user?.id,
    });

    const sortedUserActivityHistory =
      sortByDate(userActivityHistory?.data?.getUserActivityHistory?.items) ||
      [];

    const activityHistory = {
      title: "Activity history",
      type: "table",
      rowsPerPage: 10,
      currentPage: 0,
      header: ["Action", "Message", "Date"],
      rows: sortedUserActivityHistory.map((el) => ({
        action: el.eventName,
        message: el.message,
        date: prepareDate(el.createdAt),
      })),
    };

    const tags = {
      title: "Tags",
      type: "custom",
      renderCustomContent: () => (
        <Metadata user={clonedUser} target={modalTarget} />
      ),
    };

    if (modalTarget === "Match") {
      const freelancerPitch = {
        title: "Freelancer pitch",
        type: "custom",
        renderCustomContent: () => (
          <div className="whitespace-pre-line pl-8">
            {" "}
            {clonedUser.freelancerPitch || "No record to display"}
          </div>
        ),
      };

      const matchHistory = await api.common.getFieldHistory({
        key: { eq: `${applicationId}_${jobOpportunityId}` },
        entity: "Match",
      });

      const sortedMatchHistory =
        sortByDate(matchHistory?.data?.getFieldHistory?.items) || [];

      updateMatch1LevelFieldLocally(
        applicationId,
        "matchHistory",
        sortedMatchHistory
      );

      const matchHistoryContent = {
        title: "Match history",
        type: "table",
        rowsPerPage: 10,
        currentPage: 0,
        header: ["Old Value", "New Value", "Changed by", "Date"],
        rows: sortedMatchHistory.map((el) => ({
          changedFrom: el.oldValue || "-",
          changedTo: el.newValue,
          changedBy: el.creator,
          date: prepareDate(el.createdAt),
        })),
      };

      contentByTarget[modalTarget] = [
        freelancerPitch,
        matchHistoryContent,
        activityHistory,
        tags,
      ];
    }

    if (modalTarget === "User") {
      const assessments = {
        title: "Assessments",
        type: "table",
        rowsPerPage: 10,
        currentPage: 0,
        header: ["Completed", "Test", "Score", "Plagiarism", "Time", "Status"],
        rows: (clonedUser.assessments || []).map((item) => ({
          assessmentCompleted: item.assessmentCompleted ? (
            dayjs(item.assessmentCompleted).format("MMM DD YYYY")
          ) : (
            <CenteredHyphen />
          ),
          testName: item.testName || <CenteredHyphen />,
          finalScore: (
            <a
              href={item.reportLink}
              target="_blank"
              rel="noreferrer"
              className={classNames({ "text-blue-500": !!item.reportLink })}
            >
              {`${item.finalScore || 0} (Code - ${item.codeScore || 0}, MCQ - ${
                item.multipleChoiceScore || 0
              })`}
            </a>
          ),
          plagiarism: item.plagiarism || <CenteredHyphen />,
          timeTaken:
            item.timeTaken > 0 ? item.timeTaken + " mins" : <CenteredHyphen />,
          status: item.status || <CenteredHyphen />,
        })),
      };

      contentByTarget[modalTarget] = [assessments, activityHistory, tags];
    }

    setTabsContent(contentByTarget);
  };

  useEffect(() => {
    (async () => {
      if (clonedUser) {
        await initTabsContent();
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clonedUser]);

  useEffect(() => {
    initClonedUser();

    if (user) {
      setIsLoading(true);
    }

    return () => {
      setTabsContent([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getTitle = () => {
    if (!clonedUser) {
      return;
    }

    return `User: ${clonedUser.username}`;
  };

  return (
    <Modal
      isVisible={clonedUser}
      title={getTitle()}
      onClose={onClose}
      className="h-[75vh] max-h-[75vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
    >
      <div>
        {isLoading && (
          <div className="flex items-center justify-center p-4">
            <div className="loader" />
          </div>
        )}

        {!isLoading && <Tabs items={tabsContent[modalTarget] || []} />}
      </div>
    </Modal>
  );
};

UserInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

UserInfoModal.defaultProps = {
  user: null,
};

export default UserInfoModal;
