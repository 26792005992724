import Base from "./Base.js";

import { createNote, deleteNote } from "api/graphql/mutations.js";

import {
  listJobOpportunityNotes,
  listMatchNotes,
  listUserNotes,
} from "api/graphql/queries.js";

export default class NotesAPI extends Base {
  listUser(payload) {
    return this.apiClient.request({ query: listUserNotes, payload });
  }

  listMatch(payload) {
    return this.apiClient.request({ query: listMatchNotes, payload });
  }

  listJob(payload) {
    return this.apiClient.request({ query: listJobOpportunityNotes, payload });
  }

  create(payload) {
    return this.apiClient.request({ query: createNote, payload });
  }

  remove(payload) {
    return this.apiClient.request({ query: deleteNote, payload });
  }
}
