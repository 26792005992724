import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  isLoading: true,
  jobOppNotes: {},
  matchedNotes: {},
  userNotes: {},
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.notes.JOB_NOTES_LOADED: {
      const { jobOpportunityId, notes } = payload;

      draft.jobOppNotes[jobOpportunityId] = notes;
      break;
    }

    case contextConstants.notes.MATCH_NOTES_LOADED: {
      const { applicationId, notes } = payload;

      draft.matchedNotes[applicationId] = notes;
      break;
    }

    case contextConstants.notes.USER_NOTES_LOADED: {
      const { userId, notes } = payload;

      draft.userNotes[userId] = notes;
      break;
    }

    default:
      break;
  }
});
