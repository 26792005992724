import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const TextArea = ({
  value,
  onChange,
  className,
  editorLabel,
  editorSubLabel,
  rows,
  ...props
}) => {
  return (
    <div className={classNames("flex flex-col w-full", className)}>
      {editorLabel && <p className="text-2xl font-bold">{editorLabel}</p>}

      {editorSubLabel && (
        <p className="font-normal text-base mb-4">{editorSubLabel}</p>
      )}
      <textarea
        className="w-full px-4 py-2 bg-white border-gray-300 border-2 mt-2"
        value={value}
        onChange={onChange}
        rows={rows || 6}
        {...props}
      />
    </div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  editorLabel: PropTypes.string,
  editorSubLabel: PropTypes.string,
  rows: PropTypes.number,
};

TextArea.defaultProps = {
  value: "",
  onChange: () => {},
  className: "",
  editorLabel: "",
  editorSubLabel: "",
  rows: 6,
};

export default TextArea;
