import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TableWithPagination from "../TableWithPagination";
import SvgIcon from "../SvgIcon";

import NumericCircle from "components/NumericCircle";

import styles from "components/index.module.css";

const Tabs = ({ items, className, headerClassName, initialIndex = 0 }) => {
  const [activeTab, setActiveTab] = useState(items[initialIndex]);

  const renderTabs = () => {
    return (
      <div
        className={classNames(
          "flex flex-wrap justify-start xl:justify-around w-full pt-2",
          headerClassName
        )}
      >
        {items.map((item, index) => {
          const tabTitle = item.title;

          const isTabActive = activeTab.title === tabTitle;

          return (
            <div
              className="flex gap-x items-center"
              key={`${tabTitle}_${index}`}
            >
              <div
                onClick={() => {
                  if (item.updateQs) {
                    item.updateQs(index);
                  }
                  setActiveTab(item);
                }}
                className={classNames(
                  "whitespace-nowrap  flex items-center text-text-grey-900 font-medium text-xs sm:text-sm lg:text-md  mx-2 lg:mx-2.5 pb-2 hover:fill-sky-500 hover:text-sky-500 transition-all cursor-pointer relative -bottom-[2px] border-b-2 border-[transparent]",
                  {
                    "text-sky-500 border-b-2 !border-sky-500": isTabActive,
                  }
                )}
              >
                {item.icon && (
                  <SvgIcon
                    type={item.icon}
                    className={classNames("mr-2", {
                      "fill-sky-500": isTabActive,
                    })}
                  />
                )}

                <p>{tabTitle}</p>
              </div>

              {item.count > 0 && (
                <NumericCircle value={item.count} isActive={isTabActive} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderContent = useCallback(() => {
    switch (activeTab?.type) {
      case "table": {
        return (
          <div className={classNames("overflow-auto", styles.customScrollBar)}>
            <TableWithPagination {...activeTab} />
          </div>
        );
      }

      case "text": {
        return <p>{activeTab.content}</p>;
      }

      case "custom": {
        return activeTab.renderCustomContent(activeTab.title);
      }

      default: {
        return <></>;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  if (!items.length) {
    return null;
  }

  return (
    <div className={classNames("flex flex-col", className)}>
      <div className="border-b-2 border-text-grey-900 sticky top-0 bg-white z-10 py-2">
        {renderTabs()}
      </div>
      {renderContent()}
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  className: "",
};

export default Tabs;
