import { Auth } from "aws-amplify";
import React, { useRef, useState, useEffect, useCallback } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import MarkdownIt from "markdown-it";
import underline from "markdown-it-plugin-underline";
import dayjs from "dayjs";
import { nanoid } from "nanoid";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./index.css";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Status,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import SvgIcon from "components/base/SvgIcon";
import classNames from "classnames";

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  langPrefix: "language-",
}).use(underline);

// Open links in new tab
const defaultRender =
  mdParser.renderer.rules.link_open ||
  ((tokens, idx, options, env, self) => {
    return self.renderToken(tokens, idx, options);
  });

mdParser.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex("target");

  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

class MessageModel {
  constructor(
    data,
    direction,
    sender,
    createdAt = new Date().toISOString(),
    position = "single",
    type = "custom",
    id = nanoid()
  ) {
    this.direction = direction;
    this.data = data;
    this.sender = sender;
    this.createdAt = createdAt;
    this.position = position;
    this.type = type;
    this.id = id;
    this.loader = false;
  }
}

const JobAssistant = ({ user }) => {
  const [messages, setMessages] = useState([]);
  const [url, setUrl] = useState(null);

  const sendDisabled = useRef(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const pendingMessage = useRef();
  const currentMessage = useRef();
  const auth = useRef();
  const hasActiveLoaders = useRef(false);
  const messageBuffer = useRef([]);
  const expectedOrder = useRef(0);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const { lastJsonMessage, readyState, sendJsonMessage } = useWebSocket(url, {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const getQsParamValue = (param) => {
    const parsed = qs.parse(search);
    return parsed[param];
  };

  const getInitialMessage = () => {
    return `Hi I'm ${
      user.given_name || user.username
    }" todays date is ${new Date()}, lets start with the first step. 
    Remember: 
    - Follow each step in order.
    - Be sure to search for the skills when you are generating the job description. 
    - Ask permission from ME(the user) when you need it.
    - Use the action buttons when needed.`;
  };

  useEffect(() => {
    (async () => {
      // Initial connection
      auth.current = await Auth.currentSession();
      setUrl(
        `${process.env.REACT_APP_JOB_ASSISTANT_WS_ENDPOINT}?accessToken=${auth.current.accessToken.jwtToken}`
      );
    })();
  }, []);

  const receiveChunk = (data) => {
    messageBuffer.current.push(data);

    messageBuffer.current.sort((a, b) => a.order - b.order);

    while (
      messageBuffer.current.length > 0 &&
      messageBuffer.current[0].order === expectedOrder.current
    ) {
      expectedOrder.current++;
      processChunk(messageBuffer.current.shift());
    }
  };

  const flushBuffer = () => {
    messageBuffer.current = [];
    expectedOrder.current = 0;
  };

  const updateLastMessage = (messageToUpdate) => {
    if (messages[messages.length - 1]?.id !== messageToUpdate.id) {
      return;
    }

    setMessages((prev) => {
      prev[prev.length - 1] = messageToUpdate;

      return prev;
    });
  };

  const processChunk = ({ chunk }) => {
    if (!chunk) {
      return;
    }

    if (chunk?.includes("assistant >")) {
      sendDisabled.current = true;
      setIsRefreshing(true);
      if (hasActiveLoaders.current) {
        clearLoaders();
        hasActiveLoaders.current = false;
      }

      const cleanedChunk = chunk
        .replace(/assistant > Action call:/g, "")
        .replace(/assistant >/g, "")
        .replace(/\[DONE\]/g, "");

      currentMessage.current = new MessageModel(
        cleanedChunk,
        "incoming",
        "Job Assistant"
      );

      if (chunk?.includes("assistant > Action call:")) {
        currentMessage.current.loader = true;
        hasActiveLoaders.current = true;
      }

      setMessages((prev) => prev.concat(currentMessage.current));

      if (chunk.includes("[DONE]")) {
        flushBuffer();
        setTimeout(() => {
          currentMessage.current = null;
        }, 25);
        sendDisabled.current = false;
        setIsRefreshing(false);
      }
    } else {
      if (messages.length === 0 || !currentMessage.current) {
        if (chunk.includes("[DONE]")) {
          flushBuffer();
        }
        return;
      }
      if (chunk.includes("[DONE]")) {
        flushBuffer();

        currentMessage.current.data += chunk.replace(/\[DONE\]/g, "");

        updateLastMessage({ ...currentMessage.current });

        setTimeout(() => {
          currentMessage.current = null;
        }, 25);
        sendDisabled.current = false;
        setIsRefreshing((prev) => !prev);
      } else {
        if (!currentMessage.current?.id) {
          return;
        }
        currentMessage.current.data += chunk;
        updateLastMessage({ ...currentMessage.current });
      }
    }
  };

  const clearLoaders = () => {
    const msgs = messages.map((msg) => {
      msg.loader = false;
      return msg;
    });

    setMessages(msgs);
  };

  // Called on incoming new message
  useEffect(() => {
    if (lastJsonMessage !== null) {
      const {
        message: assistantMessage,
        threadId,
        threadMessagesObject,
        action,
        order,
      } = lastJsonMessage;

      if (threadId && threadId !== getQsParamValue("threadId")) {
        updateQs("threadId", threadId);
      }

      if (action === "$connect") {
        // we have refreshed the connection to refresh the access token auth if we have pending message send it
        if (getQsParamValue("threadId")) {
          sendJsonMessage({
            action: "newThread",
            threadId: getQsParamValue("threadId"),
            message: pendingMessage.current,
          });
          pendingMessage.current = null;
        } else {
          let msg;
          if (!getQsParamValue("threadId")) {
            msg = getInitialMessage();
          }
          // initial connection start conversation
          startConversation(getQsParamValue("threadId"), msg);
        }
      }
      if (
        action === "newThread" &&
        messages.length === 0 &&
        threadMessagesObject.messages?.length > 0
      ) {
        // set messages history if available
        const threadMessages = threadMessagesObject.messages;
        sendDisabled.current = false;
        setIsRefreshing(false);
        threadMessages.pop();
        const mappedMessages = threadMessages.reverse().map((m, index) => {
          const newMessage = new MessageModel(
            m.text?.value || "",
            m.role === "assistant" ? "incoming" : "outgoing",
            m.role === "assistant"
              ? "Job Assistant"
              : `${user.given_name || user.username}`,
            m.createdAt && new Date(m.createdAt * 1000)
          );
          return newMessage;
        });
        setMessages((prev) => prev.concat([...mappedMessages]));
      }

      if (!assistantMessage) {
        return;
      }

      receiveChunk({
        chunk: assistantMessage,
        order,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  const updateQs = useCallback(
    (key, value) => {
      const queryString = qs.stringify({
        ...qs.parse(search),
        [key]: value,
      });

      navigate(`${pathname}?${queryString}`, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const startConversation = (threadId, message) => {
    sendJsonMessage({ action: "newThread", threadId, message });
  };

  const removeFormatting = (text) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.body.textContent || "";
  };

  const sendingMessage = (value, message, append = true, send = true) => {
    const formattedValue = removeFormatting(value);
    sendDisabled.current = true;
    if (send) {
      sendJsonMessage({
        action: "$default",
        threadId: getQsParamValue("threadId"),
        message: formattedValue,
      });
    }

    if (append) {
      setMessages((prev) =>
        prev.concat(
          new MessageModel(
            message || value,
            "outgoing",
            `${user.given_name || user.username}`
          )
        )
      );
    }
  };

  function tokenHasExpired() {
    return (
      new Date(auth.current.accessToken.payload.exp * 1000) - new Date() <= 0
    );
  }

  const handleSendMessage = async (value) => {
    if (!value) {
      return;
    }
    if (tokenHasExpired()) {
      pendingMessage.current = removeFormatting(value);
      sendingMessage(value, null, true, false);
      auth.current = await Auth.currentSession();
      setUrl(
        `${process.env.REACT_APP_JOB_ASSISTANT_WS_ENDPOINT}?accessToken=${auth.current.accessToken.jwtToken}`
      );
      return;
    }
    flushBuffer();

    sendingMessage(value);
  };

  const connectionStatusObj = {
    [ReadyState.CONNECTING]: { label: "away", status: "Connecting" },
    [ReadyState.OPEN]: { label: "available", status: "Open" },
    [ReadyState.CLOSING]: { label: "unavailable", status: "Closing" },
    [ReadyState.CLOSED]: { label: "dnd", status: "Closed" },
    [ReadyState.UNINSTANTIATED]: {
      label: "invisible",
      status: "Uninstantiated",
    },
  }[readyState];

  const handleRefreshButton = async (newConverSation) => {
    flushBuffer();
    setMessages([]);
    sendDisabled.current = true;

    // restart connection if socket is closed
    if (connectionStatusObj.status === "Closed" || newConverSation) {
      setUrl(null);

      if (tokenHasExpired()) {
        auth.current = await Auth.currentSession();
      }
      if (newConverSation) {
        navigate(`${pathname}`, { replace: true });
      }

      setTimeout(() => {
        setUrl(
          `${process.env.REACT_APP_JOB_ASSISTANT_WS_ENDPOINT}?accessToken=${auth.current.accessToken.jwtToken}`
        );
      }, 250);
      return;
    }

    setIsRefreshing(true);
    startConversation(getQsParamValue("threadId"));
  };

  const handleActionButtonClick = (event) => {
    const actionValue = event.target.value;

    if (!actionValue || sendDisabled.current === true) {
      return;
    }

    handleSendMessage(`**${actionValue}**`);
  };

  const stylesForActionButtons = (containers) => {
    containers.forEach((div, index) => {
      if (index !== containers.length - 1) {
        div.classList.add("disabled-buttons-container");
      } else if (
        !messages[messages.length - 1]?.data?.includes("buttons-container")
      ) {
        div.classList.add("disabled-buttons-container");
      }
    });
  };

  useEffect(() => {
    // Get the container by its class
    const containers = document.querySelectorAll(".buttons-container");

    stylesForActionButtons(containers);

    // Add the event listener to the container
    if (containers?.length > 0) {
      containers[containers.length - 1].addEventListener(
        "click",
        handleActionButtonClick
      );
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (containers?.length > 0) {
        containers[containers.length - 1].removeEventListener(
          "click",
          handleActionButtonClick
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing, messages.length]);

  return (
    <div className="relative w-full h-[90vh] p-4 job-assistant-component">
      <MainContainer>
        <ChatContainer>
          <ConversationHeader>
            <ConversationHeader.Content>
              <div>Job assistant</div>
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              <div className="flex gap-x-2">
                {getQsParamValue("threadId") && (
                  <button
                    className="flex items-center gap-2 hover:underline disabled:opacity-75"
                    onClick={() => handleRefreshButton()}
                    disabled={isRefreshing || sendDisabled.current}
                  >
                    <SvgIcon
                      type="refresh"
                      className={classNames("w-[25px] transform rotate-180", {
                        "animate-spin ": isRefreshing,
                        "animate-none": !isRefreshing,
                      })}
                    />
                  </button>
                )}
                <button
                  className="flex items-center gap-2 hover:underline disabled:opacity-75"
                  onClick={() => handleRefreshButton(true)}
                  disabled={isRefreshing || sendDisabled.current}
                >
                  <SvgIcon type="newAssistant" className="w-[25px]" />
                </button>
              </div>
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList>
            {messages.map(({ data, createdAt, ...rest }, index) => (
              <Message key={index} model={rest}>
                <Message.CustomContent>
                  {rest.loader && (
                    <div className="w-full flex justify-end">
                      <span className="loader !h-[20px] !w-[20px] mb-2" />
                    </div>
                  )}
                  <div
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(data),
                    }}
                  />
                </Message.CustomContent>
                <Message.Footer sentTime={dayjs(createdAt).format("HH:mm")} />
              </Message>
            ))}
          </MessageList>
          <div as={MessageInput}>
            <div>
              <div className="w-full flex justify-end p-2">
                <div className="flex mr-4 gap-2">
                  <p>Status: </p>
                  <Status
                    status={connectionStatusObj.label}
                    name={connectionStatusObj.status}
                  />
                  {sendDisabled.current && (
                    <span className="loader !h-[25px] !w-[25px]" />
                  )}
                </div>
              </div>
              <MessageInput
                placeholder="Type message here"
                disabled={sendDisabled.current || hasActiveLoaders.current}
                attachButton={false}
                onSend={(value) => {
                  handleSendMessage(value);
                }}
                sendDisabled={
                  sendDisabled.current ||
                  connectionStatusObj.status !== "Open" ||
                  hasActiveLoaders.current
                }
              />
            </div>
          </div>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default JobAssistant;
