import * as alert from "context/reducers/alert";
import * as jobs from "context/reducers/jobs";
import * as search from "context/reducers/search";
import * as notes from "context/reducers/notes";
import * as auth from "context/reducers/auth";
import * as match from "context/reducers/match";
import * as modal from "context/reducers/modal";

const contextReducers = { alert, jobs, search, notes, auth, match, modal };

export { contextReducers };
