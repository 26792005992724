import ImageViewer from "components/ImageViewer";

import placeholderPicture from "images/placeholderProfile.png";

import "./index.module.css";

const HeadShot = ({ headshotKey }) => (
  <div className="overflow-hidden rounded-full h-full profile--image">
    <ImageViewer
      objectKey={headshotKey}
      placeholder={
        <img
          className="bg-grey-800"
          src={placeholderPicture}
          alt="Placeholder"
        />
      }
      className="h-full"
    />
  </div>
);

export default HeadShot;
