import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./index.module.css";

import {
  getButtonClassName,
  getButtonText,
} from "components/UserCardList/helpers/userCard";

import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { ModalContext } from "context/providers";

import SvgIcon from "components/base/SvgIcon";
import ActionButton from "./ActionButton";
import NumericCircle from "components/NumericCircle";

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const HitActionButtons = ({
  hit,
  isLoading,
  showActions,
  matchAction,
  jobType,
  isJobStatusActive,
}) => {
  const { showModal } = useContext(ModalContext);

  const isNotCalibration = !hit.associatedMatch?.isCalibration;

  const noCalibrationStatuses = [
    JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
    JOB_APPLICATION_MATCH_STATUS.SKIPPED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
  ];

  const canBeCalibration = !noCalibrationStatuses.includes(
    hit.associatedMatch?.status
  );

  const oneOfButtonsLoading = useMemo(() => {
    return !!Object.values(isLoading).filter((el) => !!el).length;
  }, [isLoading]);

  const hasJobTypeApplication = hit.applications?.some(
    (app) => app.jobTypeId === jobType?.id && !app.isNotActive
  );

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4">
        {showActions &&
          hasJobTypeApplication &&
          isNotCalibration &&
          canBeCalibration && (
            <ActionButton
              className={classNames("text-gray-600 !py-1")}
              isDisabled={oneOfButtonsLoading}
              isLoading={isLoading["isCalibration"]}
              action={() =>
                matchAction(
                  !!hit.associatedMatch
                    ? {
                        isCalibration: true,
                      }
                    : {
                        status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
                        isCalibration: true,
                      },
                  !!hit.associatedMatch
                )
              }
              type={isNotCalibration ? "calibration" : "removeCalibration"}
              iconType="calibration"
            />
          )}
        {!hit.associatedMatch && showActions && hasJobTypeApplication && (
          <>
            <ActionButton
              isDisabled={oneOfButtonsLoading || !isJobStatusActive}
              isLoading={isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]}
              action={() =>
                matchAction({ status: JOB_APPLICATION_MATCH_STATUS.MATCHED })
              }
              title={!isJobStatusActive ? "This job is not active" : ""}
              className="text-gray-600"
              type="match"
              iconType="heart"
            />

            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "text-gray-600",
                getButtonClassName(
                  "shortlist",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction({
                  status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
                })
              }
            >
              <SvgIcon type="focus" className="w-[20px] h-[20px]" />

              <span className="ml-2">
                {getButtonText(
                  "shortlist",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]
                )}
              </span>
            </button>

            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "text-gray-600",
                getButtonClassName(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction({ status: JOB_APPLICATION_MATCH_STATUS.SKIPPED })
              }
            >
              <SvgIcon type="skip" className="w-[20px] h-[20px] " />

              <span className="ml-2">
                {getButtonText(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )}
              </span>
            </button>
          </>
        )}

        {!hasJobTypeApplication && jobType && (
          <div className={styles.tooltip}>
            <span className={styles["tooltip-preview"]}>
              {getButtonText("noActionJobTypeApp").slice(0, 46)}...
            </span>
            <span className={styles["tooltip-text"]}>
              {getButtonText("noActionJobTypeApp")}
            </span>
          </div>
        )}

        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            "text-gray-600",
            getButtonClassName("note")
          )}
          onClick={() =>
            showModal({
              type: "note",
              title: `User notes: ${hit.username}`,
              userId: hit.id,
            })
          }
        >
          <SvgIcon type="note" className="w-[15px] h-[15px] " />

          <p className="ml-2">
            Notes {!!hit.notesCount && <NumericCircle value={hit.notesCount} />}
          </p>
        </button>
      </div>
    </div>
  );
};

HitActionButtons.propTypes = {
  hit: PropTypes.object,
  isLoading: PropTypes.object,
  showActions: PropTypes.bool,
  matchAction: PropTypes.func,
};

HitActionButtons.defaultProps = {
  hit: {},
  showActions: false,
  isLoading: {
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
  },
  matchAction: () => {},
};

export default HitActionButtons;
