import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  matches: [],
  bestFit: [],
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.match.MATCHES_LOADED: {
      draft.matches = payload.matches;
      break;
    }

    case contextConstants.match.MATCH_1_LEVEL_FIELD_UPDATED: {
      const index = draft.matches.findIndex(
        (match) => match.applicationId === payload.applicationId
      );
      if (index !== -1) {
        draft.matches[index][payload.fieldKey] = payload.value;
      }
      break;
    }

    case contextConstants.match.MATCH_USER_FIELD_UPDATED: {
      const index = draft.matches.findIndex(
        (match) => match.applicationId === payload.applicationId
      );
      if (index !== -1 && draft.matches[index]?.application?.user) {
        draft.matches[index].application.user[payload.fieldKey] = payload.value;
      }
      break;
    }

    case contextConstants.match.BEST_FIT_LOADED: {
      draft.bestFit = payload.bestFit;
      break;
    }

    default: {
      break;
    }
  }
});
