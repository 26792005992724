import React, { createContext, useReducer, useContext, useMemo } from "react";

import {
  CUSTOM_PANEL_DEFAULT_OPERATOR,
  GEOGRAPHICAL_REGIONS,
  SEARCH_BY_ATTRIBUTES,
  SKILL_KEY_NAMES,
  SKILL_STR_FORMATTED_OPTIONS,
  USER_TYPES,
} from "lookup";

import { contextConstants } from "context/constants";
import { contextActions } from "context/actions";
import { contextReducers } from "context/reducers";
import { JobsContext } from "./JobsProvider";
import { AlertContext } from "./AlertProvider";
import { useEffect } from "react";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const { jobOpp } = useContext(JobsContext);
  const { addCustomAlert } = useContext(AlertContext);

  const [state, dispatch] = useReducer(
    contextReducers.search.reducer,
    contextReducers.search.initialState
  );

  const geographicalRegions = useMemo(() => {
    return process.env.REACT_APP_GEOGRAPHICAL_REGIONS
      ? JSON.parse(process.env.REACT_APP_GEOGRAPHICAL_REGIONS)
      : GEOGRAPHICAL_REGIONS;
  }, []);

  const defaultFilters = useMemo(
    () => {
      return {
        ...state.searchState,
        range: {},
        toggle: { agreedToTerms: true },
        refinementList: {
          userType: [USER_TYPES.FREELANCER],
        },
        searchByAttributes: SEARCH_BY_ATTRIBUTES[0],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobOpp.id]
  );

  const setSearchState = (searchState) => {
    dispatch({
      type: contextConstants.search.SEARCH_STATE_CHANGED,
      payload: { searchState },
    });
  };

  const handleNoHitsFoundOnSkillStr = () => {
    const lastSkillStr =
      state.searchState?.skillsStr[state.searchState?.skillsStr.length - 1];
    const formatExpLabel = SKILL_STR_FORMATTED_OPTIONS.find(({ value }) =>
      lastSkillStr.includes(value)
    )?.label;
    addCustomAlert({
      title: "Skill Experience",
      variation: "default",
      message: `No results found for: ${
        lastSkillStr.split("_")[0]
      } (${formatExpLabel})`,
      duration: 8000,
      callback: {
        label: "Undo",
        action: () => {
          const skillStrCopy = [...state.searchState.skillsStr];
          skillStrCopy.pop();
          handleSkillsStrChange(skillStrCopy);
        },
      },
    });
  };

  const setHits = (hits) => {
    if (hits.length === 0 && state.searchState?.skillsStr?.length > 0) {
      handleNoHitsFoundOnSkillStr();
    }
    dispatch({
      type: contextConstants.search.HITS_LOADED,
      payload: { hits },
    });
  };

  const replaceHit = (hit) => {
    const hits = contextActions.search.replaceHit(hit, state.hits);

    setHits(hits);
  };

  const setDisjunctiveFacets = (disjunctiveFacets) => {
    dispatch({
      type: contextConstants.search.DISJUNCTIVE_FACETS_CHANGED,
      payload: { disjunctiveFacets },
    });
  };

  const updateHitField = (hitId, fieldKey, value) => {
    const updatedHits = contextActions.search.updateHitFieldByKey(
      state.hits,
      hitId,
      fieldKey,
      value
    );

    const updatedHit = updatedHits.find((hit) => hit.id === hitId);

    dispatch({
      type: contextConstants.search.HITS_LOADED,
      payload: { ...state, hits: updatedHits },
    });

    return updatedHit;
  };

  const updateHit = async (args) => {
    const updatedHit = await contextActions.search.updateHit(args);

    replaceHit(updatedHit);
  };

  const setOperators = (valueKey) => {
    if (Array.isArray(valueKey)) {
      const operators = {};

      valueKey.forEach(
        (el) => (operators[el] = CUSTOM_PANEL_DEFAULT_OPERATOR[el])
      );

      dispatch({
        type: contextConstants.search.OPERATOR_UPDATED,
        payload: { operators },
      });
    } else {
      dispatch({
        type: contextConstants.search.OPERATOR_UPDATED,
        payload: {
          operators: {
            ...state.operators,
            [valueKey]: state.operators[valueKey] === "and" ? "or" : "and",
          },
        },
      });
      if (valueKey === "skills.name") {
        const finalState = {
          ...state.searchState,
          skillsStrFilter: getSkillsStrFilter(
            [...state.searchState.skillsStr],
            state.operators[valueKey] === "and" ? "or" : "and"
          ),
        };
        setSearchState(finalState);
      }
    }
  };

  const setHideSkipped = (hideSkipped) => {
    dispatch({
      type: contextConstants.search.HIDE_SKIPPED_CHANGED,
      payload: { hideSkipped },
    });
  };

  const setHideRejected = (hideRejected) => {
    dispatch({
      type: contextConstants.search.HIDE_REJECTED_CHANGED,
      payload: { hideRejected },
    });
  };

  const setHideMatchedAndApplied = (hideMatchedAndApplied) => {
    dispatch({
      type: contextConstants.search.HIDE_MATCHED_CHANGED,
      payload: { hideMatchedAndApplied },
    });
  };

  const setGeographicalRegions = (geographicalRegions, setSearch = true) => {
    dispatch({
      type: contextConstants.search.GEOGRAPHICAL_REGION_CHANGED,
      payload: { geographicalRegions },
    });

    if (setSearch) {
      const activeRegions = geographicalRegions.filter(
        (region) => region.checked
      );

      const activeRegionsCountries = activeRegions
        .map(({ countryNames }) => countryNames)
        .flat();

      const finalState = {
        ...state.searchState,
        refinementList: {
          ...state.searchState.refinementList,
          "location.countryName": activeRegionsCountries,
        },
      };

      setSearchState(finalState);
    }
  };

  const getSkillsStrFilter = (
    skillsStr,
    skillsNameOperator = "AND",
    skillsName = state.searchState.refinementList?.["skills.name"] || []
  ) => {
    let skillsNameFilter = "";
    const obj = {};
    const operator = skillsNameOperator.toUpperCase();

    for (const skillStr of skillsStr) {
      const key = skillStr.split("_")[0];
      if (obj[key]) {
        obj[key] = [...obj[key], `skills_str:"${skillStr}"`];
      } else {
        obj[key] = [`skills_str:"${skillStr}"`];
      }
    }

    const mappedFilter = Object.keys(obj).map((key) => {
      return `(${obj[key].join(" OR ")})`;
    });

    if (operator === "OR") {
      const skillsNameToFilter = skillsName.filter(
        (skName) => !skillsStr.find((skStr) => skStr.startsWith(skName))
      );
      skillsNameFilter = skillsNameToFilter
        .map((skName) => {
          return `skills.name:"${skName}"`;
        })
        .join(` ${operator} `);
    }

    return !!mappedFilter.length
      ? ` AND ${mappedFilter.join(` ${operator} `)}${
          skillsNameFilter && ` OR ${skillsNameFilter}`
        }`
      : "";
  };

  const handleSkillsStrChange = (skillsStr, skillsName) => {
    const skillsNameOperator = state.operators["skills.name"];
    const finalState = {
      ...state.searchState,
      refinementList: {
        ...state.searchState.refinementList,
      },
      skillsStr,
      skillsStrFilter: getSkillsStrFilter(
        skillsStr,
        skillsNameOperator,
        skillsName
      ),
    };

    if (state.searchState.refinementList) {
      const skillsNameRefinement =
        state.searchState.refinementList[SKILL_KEY_NAMES.name] || [];

      const skillsToBeActive = skillsStr
        .map((item) => {
          if (!skillsNameRefinement.some((sk) => item.startsWith(sk))) {
            return item.split("_")[0];
          }
          return null;
        })
        .filter((e) => e);
      if (!!skillsToBeActive.length) {
        finalState.refinementList[SKILL_KEY_NAMES.name] = [
          ...skillsNameRefinement,
          ...skillsToBeActive,
        ];
      }
    }

    setSearchState(finalState);
  };

  const clearSearchState = () => {
    setGeographicalRegions(geographicalRegions, false);
    setSearchState(defaultFilters);
    setOperators(Object.keys(state.operators));
  };

  const search = {
    searchState: state.searchState,
    hits: state.hits,
    operators: state.operators,
    hideSkipped: state.hideSkipped,
    hideRejected: state.hideRejected,
    hideMatchedAndApplied: state.hideMatchedAndApplied,
    geographicalRegions: state.geographicalRegions,
    disjunctiveFacets: state.disjunctiveFacets,
    setSearchState,
    setHits,
    updateHitField,
    updateHit,
    setOperators,
    clearSearchState,
    setHideSkipped,
    setHideRejected,
    setHideMatchedAndApplied,
    setGeographicalRegions,
    handleSkillsStrChange,
    setDisjunctiveFacets,
  };

  useEffect(() => {
    // initialize predefined regions
    setGeographicalRegions(geographicalRegions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchContext.Provider value={search}>{children}</SearchContext.Provider>
  );
};
