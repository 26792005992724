import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "components/Modals";
import TextArea from "components/base/TextArea";
import ActionButton from "components/UserCardList/components/UserCardResult/molecules/ActionButton";
import { useContext } from "react";
import { JobsContext, MatchContext } from "context/providers";
import { JOB_APPLICATION_MATCH_STATUS } from "lookup";

const MatchModal = ({
  onClose,
  applicationId,
  jobOpportunityId,
  userId,
  freelancerPitchPrefill,
  freelancerPitch,
  isUpdate,
  status,
  hitStatus,
}) => {
  const [objectInputField, setObjectInputField] = useState({
    value: freelancerPitchPrefill || "",
    successMessage: "",
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { jobOpp } = useContext(JobsContext);
  const { createMatch, updateMatch } = useContext(MatchContext);

  const handleOnChange = (e) => {
    setObjectInputField((prev) => ({
      ...prev,
      value: e.target.value,
      errorMessage: "",
      successMessage: "",
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let successMessage = "";
    let errorMessage = "";

    try {
      const params = {};
      if (status) {
        params.status = status;
      }
      if (isUpdate) {
        if (freelancerPitchPrefill !== objectInputField.value) {
          params.freelancerPitchPrefill = objectInputField.value;
          await updateMatch(applicationId, jobOpportunityId, params);
          successMessage = "update successful";

          status && onClose();
        }
      } else {
        if (objectInputField.value) {
          params.freelancerPitchPrefill = objectInputField.value;
        }
        await createMatch(applicationId, jobOpportunityId, userId, params);
        onClose();
      }
    } catch (error) {
      console.log("matchAction error: ", error);
      errorMessage = "Something went wrong";
    }
    setObjectInputField((prev) => ({
      ...prev,
      successMessage,
      errorMessage,
    }));
    setIsLoading(false);
  };

  const readOnlyInput = (title, text) => {
    return (
      <div className="flex flex-col w-full">
        <p className="text-2xl font-bold">
          {title} <span className="text-sm font-normal">{`(Read Only)`}</span>
        </p>
        <div className="w-full min-h-[100px] px-4 py-2 bg-white border-gray-400 border-2 cursor-default mt-2 whitespace-pre-line max-h-[175px] overflow-auto">
          {text}
        </div>
      </div>
    );
  };

  return (
    <Modal
      isVisible={applicationId && jobOpportunityId}
      title={"Application Questions"}
      onClose={onClose}
      className="max-h-[90vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
    >
      <>
        {hitStatus !== JOB_APPLICATION_MATCH_STATUS.MATCHED &&
        hitStatus !== JOB_APPLICATION_MATCH_STATUS.INTERESTED &&
        freelancerPitch ? (
          readOnlyInput("Answered Questions:", freelancerPitch)
        ) : (
          <>
            {jobOpp.freelancerPitchPrefill &&
              readOnlyInput("Job Questions:", jobOpp.freelancerPitchPrefill)}

            {hitStatus !== JOB_APPLICATION_MATCH_STATUS.MATCHED &&
            hitStatus !== JOB_APPLICATION_MATCH_STATUS.INTERESTED &&
            hitStatus !== JOB_APPLICATION_MATCH_STATUS.SHORTLISTED &&
            isUpdate ? (
              readOnlyInput("Candidate Questions:", objectInputField.value)
            ) : (
              <>
                <div className="flex justify-center mt-2">
                  <TextArea
                    editorLabel={"Candidate Questions:"}
                    value={objectInputField.value}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
                <div className="flex justify-end mt-6">
                  <div className="flex flex-col">
                    {objectInputField.successMessage && (
                      <span className="text-green-600 text-sm font-bold mt-1">
                        {objectInputField.successMessage}
                      </span>
                    )}

                    {objectInputField.errorMessage && (
                      <span className="text-red-600 text-sm font-bold mt-1">
                        {objectInputField.errorMessage}
                      </span>
                    )}
                    <div className="self-end">
                      <ActionButton
                        isDisabled={
                          isLoading ||
                          freelancerPitchPrefill === objectInputField.value
                        }
                        isLoading={isLoading}
                        action={() => handleSubmit()}
                        className="text-gray-600 border-b-2"
                        title={
                          isUpdate && !status ? "Update Match" : "Create Match"
                        }
                        isUpdate={isUpdate && !status}
                        type="match"
                        iconType="heart"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </Modal>
  );
};

MatchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MatchModal;
