import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { capitalize } from "lodash";
import { nanoid } from "nanoid";
import dayjs from "dayjs";

import {
  getIconForSocialType,
  getSocialProfileUrl,
  mapSkills,
} from "components/UserCardList/helpers/userCard";

import {
  APPLICATION_TAB_NAMES,
  CUSTOM_LINK_TITLES_BY_TYPE,
  CUSTOM_LINK_TYPES,
  JOB_APPLICATION_MATCH_STATUS_FORMATTED,
  MATCH_RATING_REASONS_FORMATTED,
} from "lookup";

import {
  JobsContext,
  ModalContext,
  NotesContext,
  SearchContext,
} from "context/providers";

import Collapsible from "components/Collapsible";
import SvgIcon from "components/base/SvgIcon";
import { Chip } from "components/SearchFilters/components";
import Ratings from "./Ratings";
import Note from "components/Notes/components/Note";

const SKILL_EXP_INFO = {
  low: { className: "!text-red-600", info: "0-2y" },
  medium: { className: "!text-amber-600", info: "2-5y" },
  high: { className: "!text-green-500", info: "5+y" },
};

const CollapsibleSection = ({ activeTabName, hit }) => {
  const [seeAssessments, setSeeAssessments] = useState(false);
  const [seeMatches, setSeeMatches] = useState(false);
  const [seeCalibrationNotes, setSeeCalibrationNotes] = useState(false);

  const { matchedNotes, match: initMatchNotes } = useContext(NotesContext);

  const { jobOpp } = useContext(JobsContext);
  const { showModal } = useContext(ModalContext);
  const { searchState } = useContext(SearchContext);

  useEffect(() => {
    (async () => {
      if (
        activeTabName === APPLICATION_TAB_NAMES.CALIBRATION &&
        !matchedNotes[hit.match?.applicationId]
      ) {
        await initMatchNotes(
          { id: hit.match?.applicationId },
          { id: jobOpp.id }
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit.match?.applicationId]);

  const matchNotes = useMemo(() => {
    return (
      matchedNotes[hit.match?.applicationId]
        ?.filter((n) => n.noteType === "CALIBRATION")
        .sort((a, b) =>
          dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
        ) || []
    );
  }, [matchedNotes, hit.match?.applicationId]);

  const mappedSkills = useMemo(
    () => mapSkills(jobOpp.skills || [], hit.skills || []),
    [jobOpp.skills, hit.skills]
  );
  const matchedJobs = useMemo(() => {
    const matches = [];
    for (let app of hit?.applications ?? []) {
      if (app?.matches?.length > 0) {
        if (app.isNotActive) {
          const inactiveMatches = app.matches.map((m) => ({
            ...m,
            hasInactiveApplication: true,
          }));
          matches.push(...inactiveMatches);
        } else {
          matches.push(...app.matches);
        }
      }
    }
    const nonSkippedMatches = matches.filter(
      (match) => match.status !== "SKIPPED"
    );
    const compareDates = (a, b) => {
      const dateA = new Date(a.updatedAt || a.createdAt);
      const dateB = new Date(b.updatedAt || b.createdAt);
      return dateB - dateA;
    };

    return nonSkippedMatches.sort(compareDates);
  }, [hit]);

  const { rating, reasonsForRating } = useMemo(
    () => ({
      rating: hit.match?.rating || null,
      reasonsForRating: hit.match?.reasonsForRating || [],
    }),
    [hit]
  );

  const assessments = useMemo(() => {
    return hit.assessments || [];
  }, [hit]);

  const getJobName = (jobOpp) => {
    return jobOpp
      ? jobOpp.status === "FULFILLED"
        ? `${jobOpp.title} (${jobOpp.status?.toLowerCase()})`
        : jobOpp.title || "-"
      : "-";
  };

  const formatMatchJobStatus = (status) => {
    const formattedStatus = JOB_APPLICATION_MATCH_STATUS_FORMATTED[status];
    return formattedStatus ?? capitalize(status);
  };

  const prepareScore = (item) => {
    return `${item.finalScore || 0} (Code - ${item.codeScore || 0}, MCQ - ${
      item.multipleChoiceScore || 0
    })`;
  };

  const renderCalibrationNotes = (items) => {
    return items.map((note, index) => {
      return (
        <div key={note.id} className="text-sm">
          {index > 0 && <hr className="border-t w-full" />}

          <Note key={note.createdAt} creator={note.creator} {...note} />
        </div>
      );
    });
  };

  const renderMatchedJobs = (items) => {
    return items.map((job, index) => {
      const jobName = getJobName(job.jobOpportunity);

      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={job?.applicationId + "-" + job?.jobOpportunityId}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex space-x-2.5">
            <span className="flex-1">{formatMatchJobStatus(job.status)}</span>

            <span className="flex-1">
              {dayjs(job.updatedAt).format("MM DD YYYY").split(" ").join("/")}
            </span>

            <span
              className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
              title={jobName}
            >
              {jobName}
            </span>
            <span
              className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
              title={job.jobOpportunity?.organization}
            >
              {job.jobOpportunity?.organization}
            </span>
            <span
              className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
              title={job.rate?.value}
            >
              {job.rate?.value ? "$" + job.rate?.value : ""}
            </span>
            <span className="flex-1">
              {job.hasInactiveApplication && (
                <SvgIcon
                  type="warning"
                  className="w-5 h-5 cursor-help text-amber-600"
                  title="This match is associated with a job role that is no longer active for this user"
                />
              )}
            </span>
          </div>
        </div>
      );
    });
  };

  const renderAssessments = (items) => {
    return items.map((assessment, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={assessment.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}
          <div className="flex gap-2 justify-between">
            <a
              href={assessment.reportLink}
              target="_blank"
              rel="noreferrer"
              title={assessment.testName}
              className={classNames(
                "min-w-[50%] max-w-[50%] whitespace-nowrap overflow-ellipsis overflow-hidden",
                { "text-blue-500": !!assessment.reportLink }
              )}
            >
              {assessment.testName}
            </a>

            <p className="w-1/2 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {assessment.status}
            </p>

            <p
              title={prepareScore(assessment)}
              className="w-auto whitespace-nowrap"
            >
              {assessment.finalScore}
            </p>
          </div>
        </div>
      );
    });
  };

  const getSKillTitle = (skill) => {
    if (skill.experience) {
      return `${skill.name}: ${capitalize(skill.experience)}`;
    }

    return skill.name;
  };

  const renderJobSkillInfo = ({ experience, hitHasJobSkill }) => {
    if (!hitHasJobSkill) {
      return <p className="text-red-500">Missing Skill</p>;
    }

    if (!experience) {
      return (
        <p className=" overflow-hidden text-ellipsis">
          Unknown Experience Level
        </p>
      );
    }

    return (
      <p className="text-sm">
        <span
          className={classNames(
            "capitalize",
            SKILL_EXP_INFO[experience].className
          )}
        >
          {experience}
        </span>{" "}
        {SKILL_EXP_INFO[experience].info}
      </p>
    );
  };

  const renderTorcRatings = (rating) => {
    const rows = rating.skills.map((skill, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={skill.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex space-x-2.5">
            <span className="flex-1">{skill.name}</span>

            <span className="flex-1">{skill.rating * 100} %</span>
          </div>
        </div>
      );
    });

    rows.push(
      <div
        className="flex flex-col font-rubik-medium text-sm w-full"
        key="overall"
      >
        <hr className="border-t my-1 w-full" />

        <div className="flex space-x-2.5">
          <span className="flex-1">Overall</span>

          <span className="flex-1">{rating.overall * 100} %</span>
        </div>
      </div>
    );

    return rows;
  };

  const renderHighLights = (snippets, attributes, title) => {
    const hasHighlights = attributes.some((e) =>
      e.childAttributes.some((childAttribute) =>
        snippets[e.parentAttribute]?.some(
          (snippet) =>
            snippet[childAttribute]
        )
      )
    );
    if (hasHighlights) {
      return (
        <Collapsible label={title} className="!text-sm" isVisible>
          {attributes.map(
            ({ childAttributes, parentAttribute, title }, parentIndex) => {
              return (
                <div key={parentIndex} className="mt-2">
                  <p className="text-sm font-semibold">{title}</p>
                  {childAttributes.map((childAttribute, childIndex) => {
                    const filteredHighlights =
                      snippets[parentAttribute]?.filter(
                        (snippet) =>
                          snippet[childAttribute]
                      ) || [];

                    if (filteredHighlights.length > 0) {
                      return (
                        <div
                          key={`${parentIndex}_${childIndex}`}
                          className="mt-1"
                        >
                          <p className="capitalize text-xs text-gray-500">
                            {childAttribute}:
                          </p>
                          <div className="flex flex-wrap gap-2">
                            {filteredHighlights.map((e, index) => (
                              <p
                                key={`${parentIndex}_${childIndex}_${index}`}
                                dangerouslySetInnerHTML={{
                                  __html: e[childAttribute].value,
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    }
                    return <></>;
                  })}
                </div>
              );
            }
          )}
        </Collapsible>
      );
    }
  };

  return (
    <div className="w-full flex flex-col gap-4 mt-6">
      {activeTabName === APPLICATION_TAB_NAMES.CALIBRATION && (
        <Collapsible isVisible label="Calibration">
          <div className="flex flex-col gap-y-4">
            {rating && <Ratings rating={rating} />}
            {reasonsForRating?.length > 0 && (
              <div>
                Reasons:
                <div className="flex flex-wrap gap-2">
                  {reasonsForRating.map((reason) => (
                    <Chip
                      key={`modal-view-${reason}`}
                      value={MATCH_RATING_REASONS_FORMATTED[reason]}
                      className={classNames(
                        "!h-[28px] !py-4 !border !border-amber-600 !text-gray-500"
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="flex gap-x-2">
              Notes:
              <button
                onClick={() =>
                  showModal({
                    type: "note",
                    title: `${hit.username}'s calibration comments`,
                    applicationId:
                      hit.associatedMatch?.applicationId ||
                      hit.match?.applicationId,
                    isCalibration: true,
                    isAllowedPublicNote: true,
                  })
                }
              >
                <SvgIcon type="note" className="w-[20px] h-[20px]" />
              </button>
            </div>
            <div>
              {renderCalibrationNotes(matchNotes?.slice(0, 2) || [])}
              {matchNotes.length > 2 && (
                <>
                  <div className="relative">
                    <button
                      className="text-blue hover:text-blue-600 text-sm"
                      onClick={() => setSeeCalibrationNotes((prev) => !prev)}
                    >
                      {seeCalibrationNotes ? "Show less" : "Show more"}
                    </button>
                  </div>
                  {seeCalibrationNotes &&
                    renderCalibrationNotes(matchNotes?.slice(2) || [])}
                </>
              )}
            </div>
          </div>
        </Collapsible>
      )}

      {!!hit.socialLinks?.length && (
        <Collapsible label="Social links">
          <div className="flex gap-x-3">
            {hit.socialLinks.map((s) => {
              return (
                <a
                  key={nanoid()}
                  className="flex transform transition duration-500 hover:scale-110"
                  href={getSocialProfileUrl(s.type, s.value)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgIcon
                    type={getIconForSocialType(s.type)}
                    className="!fill-sky-600"
                  />
                </a>
              );
            })}
          </div>
        </Collapsible>
      )}

      {hit.otherLinks && (
        <Collapsible label="User links">
          {hit.otherLinks && (
            <div className="flex flex-wrap gap-x-3 gap-y-1">
              {hit.otherLinks.map((link) => {
                const { name, description, value, type } = link;

                return (
                  <a
                    key={nanoid()}
                    href={value}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex text-sky-600"
                    title={`${CUSTOM_LINK_TITLES_BY_TYPE[type]}, ${name}${
                      description && ", " + description
                    }`}
                  >
                    <SvgIcon
                      type={CUSTOM_LINK_TYPES[type]}
                      className="transform transition duration-500 scale-90 hover:scale-100 min-w-[25px] w-[25px] mr-1 !fill-sky-600"
                    />
                    {name}
                  </a>
                );
              })}
            </div>
          )}
        </Collapsible>
      )}
      {!!mappedSkills.length && (
        <Collapsible label="Skills" isVisible>
          <div className="flex flex-col gap-2">
            {mappedSkills.map((skill) => (
              <div
                key={`modal-view-${skill.id}`}
                className={
                  (skill.isJobSkill && skill.hitHasJobSkill) ||
                  (skill.isJobSkill && !skill.hitHasJobSkill)
                    ? "flex gap-2 items-center"
                    : "hidden"
                }
              >
                <Chip
                  value={getSKillTitle(skill)}
                  className={classNames(
                    "!h-[28px] !py-4 !border",
                    {
                      "!border-sky-600 !text-sky-600 font-normal":
                        skill.isJobSkill && skill.hitHasJobSkill,
                    },
                    {
                      "!border-red-600 !text-red-600":
                        skill.isJobSkill && !skill.hitHasJobSkill,
                    },
                    {
                      "!border-amber-600 !text-amber-600":
                        !skill.isJobSkill && !skill.hitHasJobSkill,
                    }
                  )}
                />
                <div className="font-semibold text-sm flex whitespace-nowrap max-w-full">
                  {skill.isJobSkill && renderJobSkillInfo(skill)}
                </div>
              </div>
            ))}
          </div>
          <br />
          <div className="flex flex-wrap gap-2">
            {mappedSkills.map((skill) => (
              <div
                key={`modal-view-${skill.id}`}
                className={
                  (skill.isJobSkill && skill.hitHasJobSkill) ||
                  (skill.isJobSkill && !skill.hitHasJobSkill)
                    ? "hidden"
                    : "flex gap-2 items-center"
                }
              >
                <Chip
                  // flex gap-2 items-center
                  value={getSKillTitle(skill)}
                  className={classNames(
                    "!h-[28px] !py-4 !border",
                    {
                      "!border-sky-600 !text-sky-600 font-normal":
                        skill.isJobSkill && skill.hitHasJobSkill,
                    },
                    {
                      "!border-red-600 !text-red-600":
                        skill.isJobSkill && !skill.hitHasJobSkill,
                    },
                    {
                      "!border-amber-600 !text-amber-600":
                        !skill.isJobSkill && !skill.hitHasJobSkill,
                    }
                  )}
                />
                <div className="font-semibold text-sm flex whitespace-nowrap max-w-full">
                  {skill.isJobSkill && renderJobSkillInfo(skill)}
                </div>
              </div>
            ))}
          </div>
        </Collapsible>
      )}

      {!!matchedJobs.length && (
        <Collapsible label="Matched Jobs">
          {renderMatchedJobs(matchedJobs?.slice(0, 3) || [])}
          {matchedJobs.length > 3 && (
            <div className="relative max-w-xs w-full mt-4">
              <button
                className="text-blue hover:text-blue-600 text-sm"
                onMouseOver={() => setSeeMatches(true)}
              >
                See more
              </button>

              {seeMatches && (
                <div
                  className="h-fit max-h-96 w-fit bg-white p-4 overflow-auto rounded-lg absolute -top-1 shadow-lg z-30"
                  onMouseLeave={() => setSeeMatches(false)}
                >
                  <div className="flex flex-wrap">
                    {renderMatchedJobs(matchedJobs || [])}
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapsible>
      )}

      {!!assessments.length && (
        <Collapsible label="Assessments">
          {renderAssessments(assessments?.slice(0, 3) || [])}

          {assessments.length > 3 && (
            <div className="relative w-full mt-4">
              <button
                className="text-blue hover:text-blue-600 text-sm"
                onMouseOver={() => setSeeAssessments(true)}
              >
                See all Assessments
              </button>
              {seeAssessments && (
                <div
                  className="h-fit max-h-96 min-w-full bg-white p-4 rounded-lg absolute -top-1 shadow-lg z-30"
                  onMouseLeave={() => setSeeAssessments(false)}
                >
                  <div className="flex flex-wrap">
                    {renderAssessments(assessments || [])}
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapsible>
      )}

      {!!hit.ratings?.torc && (
        <Collapsible label="Torc Ratings">
          {renderTorcRatings(hit.ratings.torc)}
        </Collapsible>
      )}

      {searchState.query &&
        (searchState.searchByAttributes.title === "Experience & Resume" ||
          searchState.searchByAttributes.title === "Resume") &&
        hit._snippetResult?.parsedResume &&
        renderHighLights(
          hit._snippetResult,
          [
            {
              parentAttribute: "parsedResume",
              title: "",
              childAttributes: ["content"],
            },
          ],
          "Resume Results (highlights)"
        )}

      {searchState.query &&
        (searchState.searchByAttributes.title === "Experience & Resume" ||
          searchState.searchByAttributes.title === "Experience") &&
        (hit._snippetResult?.careers ||
          hit._snippetResult?.projectsCaseStudies) &&
        renderHighLights(
          hit._snippetResult,
          [
            {
              parentAttribute: "careers",
              title: "Careers",
              childAttributes: ["title", "stack", "description"],
            },
            {
              parentAttribute: "projectsCaseStudies",
              title: "Projects and Case Studies",
              childAttributes: ["title", "stack", "description"],
            },
          ],
          "Experience Results (highlights)"
        )}
    </div>
  );
};

CollapsibleSection.propTypes = {
  hit: PropTypes.object,
  activeTabName: PropTypes.string,
};

CollapsibleSection.defaultPropt = {
  hit: {},
  activeTabName: "ALLUSERS",
};

export default CollapsibleSection;
