import React from "react";
import PropTypes from "prop-types";

import Calendar from "components/Calendar";
import Modal from "components/Calendar/Modal";
import SvgButton from "components/base/SvgButton";
import classNames from "classnames";

const CalendarModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div
        className={classNames(
          "w-[65vw] h-[90vh] bg-white rounded-none no-scrollbar overflow-hidden relative rounded_modal px-4 py-2"
        )}
      >
        <div className="absolute top-2 !right-3 w-[25px] h-[25px] opacity-50 cursor-pointer hover:opacity-100 z-50">
          <SvgButton icon="cross" onClick={onClose} />
        </div>

        <Calendar
          noResultsMessage={"Currently this job has no meetings booked"}
        />
      </div>
    </Modal>
  );
};

CalendarModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CalendarModal;
