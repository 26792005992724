import produce from "immer";

import { SEARCH_BY_ATTRIBUTES, USER_TYPES } from "lookup";
import { contextConstants } from "context/constants";

export const initialState = {
  searchState: {
    refinementList: {
      userType: [USER_TYPES.FREELANCER],
      "skills.name": [],
    },
    searchByAttributes: SEARCH_BY_ATTRIBUTES[0],
    skillsStr: [],
    skillsStrFilter: "",
  },
  hits: [],
  hideSkipped: true,
  hideRejected: true,
  hideMatchedAndApplied: true,
  operators: {
    "skills.name": "and",
    activeJobTypeTitles: "and",
    "knownLanguages.language": "and",
    "assessments.testName": "and",
  },
  disjunctiveFacets: [],
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.search.SEARCH_STATE_CHANGED: {
      draft.searchState = payload.searchState;
      break;
    }

    case contextConstants.search.HITS_LOADED: {
      draft.hits = payload.hits;
      break;
    }

    case contextConstants.search.DISJUNCTIVE_FACETS_CHANGED: {
      draft.disjunctiveFacets = payload.disjunctiveFacets;
      break;
    }

    case contextConstants.search.OPERATOR_UPDATED: {
      draft.operators = payload.operators;
      break;
    }

    case contextConstants.search.HIDE_SKIPPED_CHANGED: {
      draft.hideSkipped = payload.hideSkipped;
      break;
    }

    case contextConstants.search.HIDE_REJECTED_CHANGED: {
      draft.hideRejected = payload.hideRejected;
      break;
    }

    case contextConstants.search.HIDE_MATCHED_CHANGED: {
      draft.hideMatchedAndApplied = payload.hideMatchedAndApplied;
      break;
    }

    case contextConstants.search.GEOGRAPHICAL_REGION_CHANGED: {
      draft.geographicalRegions = payload.geographicalRegions;
      break;
    }

    default:
      break;
  }
});
