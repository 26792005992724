import classNames from "classnames";
import logoImg from "images/headerLogo.png";

export default function AppLogo({ className }) {
  const imgSrc = process.env.REACT_APP_APP_LOGO ?? logoImg;

  return (
    <img
      className={classNames("h-14 w-40", className)}
      src={imgSrc}
      alt="application logo"
      width={183}
      height={68}
    />
  );
}
