import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  getButtonClassName,
  getButtonText,
} from "components/UserCardList/helpers/userCard";

import {
  APPLICATION_TAB_NAMES,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
} from "lookup";
import { ModalContext } from "context/providers";

import CustomerRateButton from "./CustomerRateButton";
import SvgIcon from "components/base/SvgIcon";
import ActionButton from "../ActionButton";

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const MatchActionButtons = ({
  match,
  isLoading,
  isJobStatusActive,
  activeTabName,
  updateMatch,
  removeMatch,
}) => {
  const { showModal } = useContext(ModalContext);

  const canBeFinalist =
    match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED ||
    match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED;

  const isNotFinalist =
    match?.subStatus !== JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST;

  const isNotCalibration = !match?.isCalibration;

  const noCalibrationStatuses = [
    JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
    JOB_APPLICATION_MATCH_STATUS.SKIPPED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
  ];

  const canBeCalibration = !noCalibrationStatuses.includes(match.status);

  const { oneOfButtonsLoading, notesCount, canBeMatched } = useMemo(() => {
    const oneOfButtonsLoading = !!Object.values(isLoading).filter((el) => !!el)
      .length;

    const { status, notesCount } = match;
    const canBeMatched =
      status === JOB_APPLICATION_MATCH_STATUS.SHORTLISTED ||
      status === JOB_APPLICATION_MATCH_STATUS.INTERESTED;

    return { oneOfButtonsLoading, notesCount, canBeMatched };
  }, [isLoading, match]);

  return (
    <div className="flex flex-wrap items-center gap-4">
      {((canBeCalibration && isNotCalibration) ||
        (activeTabName === APPLICATION_TAB_NAMES.CALIBRATION &&
          canBeCalibration)) && (
        <ActionButton
          className={classNames("text-gray-600 !py-1")}
          isDisabled={oneOfButtonsLoading}
          isLoading={isLoading["isCalibration"]}
          action={() =>
            updateMatch({
              isCalibration: isNotCalibration ? true : false,
            })
          }
          type={isNotCalibration ? "calibration" : "removeCalibration"}
          iconType="calibration"
        />
      )}
      {canBeFinalist && activeTabName !== APPLICATION_TAB_NAMES.CALIBRATION && (
        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            "text-gray-600",
            isNotFinalist
              ? getButtonClassName(
                  "finalist",
                  isLoading[JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]
                )
              : !isLoading[JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]
              ? "hover:!fill-red-500 hover:text-red-500"
              : "text-gray-600 hover:!text-gray-600"
          )}
          disabled={oneOfButtonsLoading}
          onClick={() =>
            updateMatch({
              subStatus: isNotFinalist
                ? JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST
                : JOB_APPLICATION_MATCH_SUB_STATUS.NULL,
            })
          }
        >
          <SvgIcon type={"checkBadge"} className="h-5 w-5" />

          <span className="ml-2">
            {getButtonText(
              isNotFinalist ? "finalist" : "removeFinalist",
              isLoading[
                isNotFinalist
                  ? JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST
                  : JOB_APPLICATION_MATCH_SUB_STATUS.NULL
              ]
            )}
          </span>
        </button>
      )}

      {activeTabName === APPLICATION_TAB_NAMES.CALIBRATION && (
        <CustomerRateButton
          isCalibration={activeTabName === APPLICATION_TAB_NAMES.CALIBRATION}
          match={match}
          disabled={oneOfButtonsLoading}
        />
      )}

      {canBeMatched && (
        <ActionButton
          isDisabled={oneOfButtonsLoading || !isJobStatusActive}
          isLoading={isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]}
          action={() =>
            updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.MATCHED })
          }
          title={!isJobStatusActive ? "This job is not active" : ""}
          className="text-gray-600"
          type="match"
          iconType="heart"
        />
      )}

      {match.status === JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK && (
        <ActionButton
          isDisabled={oneOfButtonsLoading || !isJobStatusActive}
          isLoading={isLoading[JOB_APPLICATION_MATCH_STATUS.APPLIED]}
          action={() =>
            updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.APPLIED })
          }
          title={!isJobStatusActive ? "This job is not active" : ""}
          className="text-gray-600"
          type="apply"
          iconType="heart"
        />
      )}

      {!canBeMatched && activeTabName !== APPLICATION_TAB_NAMES.CALIBRATION && (
        <CustomerRateButton match={match} disabled={oneOfButtonsLoading} />
      )}

      {match.status !== JOB_APPLICATION_MATCH_STATUS.INTERESTED &&
        match.status !== JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK && (
          <button
            className={classNames(
              DEFAULT_BUTTON_CLASS_NAME,
              "text-gray-600",
              getButtonClassName(
                "delete",
                isLoading[JOB_APPLICATION_MATCH_STATUS.DELETED]
              )
            )}
            disabled={oneOfButtonsLoading}
            onClick={removeMatch}
          >
            <SvgIcon type="delete" className="w-[15px] h-[15px]" />

            <span className="ml-2">
              {getButtonText(
                "delete",
                isLoading[JOB_APPLICATION_MATCH_STATUS.DELETED]
              )}
            </span>
          </button>
        )}

      {activeTabName === APPLICATION_TAB_NAMES.SHORTLISTED &&
        match.status === JOB_APPLICATION_MATCH_STATUS.SHORTLISTED && (
          <button
            className={classNames(
              DEFAULT_BUTTON_CLASS_NAME,
              "text-gray-600",
              getButtonClassName(
                "skip",
                isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
              )
            )}
            disabled={oneOfButtonsLoading}
            onClick={() =>
              updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.SKIPPED })
            }
          >
            <SvgIcon type="skip" className="w-[20px] h-[20px]" />

            <span className="ml-2">
              {getButtonText(
                "skip",
                isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
              )}
            </span>
          </button>
        )}

      {activeTabName === APPLICATION_TAB_NAMES.SHORTLISTED &&
        (match.status === JOB_APPLICATION_MATCH_STATUS.INTERESTED ||
          match.status ===
            JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK) && (
          <button
            className={classNames(
              DEFAULT_BUTTON_CLASS_NAME,
              "text-gray-600",
              getButtonClassName(
                "reject",
                isLoading[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]
              )
            )}
            disabled={oneOfButtonsLoading}
            onClick={() =>
              updateMatch({
                status: JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
              })
            }
          >
            <SvgIcon type="cross" className="w-[20px] h-[20px]" />

            <span className="ml-2">
              {getButtonText(
                "reject",
                isLoading[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]
              )}
            </span>
          </button>
        )}

      <button
        className={classNames(
          DEFAULT_BUTTON_CLASS_NAME,
          "text-gray-600",
          getButtonClassName("note")
        )}
        onClick={() =>
          showModal({
            type: "note",
            title: `Match Notes: ${match.application.user.username}`,
            applicationId: match.applicationId,
            isAllowedPublicNote: true,
          })
        }
      >
        <SvgIcon type="note" className="w-[15px] h-[15px]" />

        <span className="ml-2">{`Notes${
          !!notesCount ? ` (${notesCount})` : ""
        }`}</span>
      </button>
    </div>
  );
};

MatchActionButtons.propTypes = {
  match: PropTypes.object,
  isLoading: PropTypes.object,
  callMatchAction: PropTypes.func,
  activeTabName: PropTypes.string,
};

MatchActionButtons.defaultProps = {
  match: {},
  isLoading: {},
  callMatchAction: () => {},
  activeTabName: "ALLUSERS",
};

export default MatchActionButtons;
