import React, { createContext, useReducer, useEffect, useContext } from "react";

import { contextConstants } from "context/constants";
import { contextActions } from "context/actions";
import { contextReducers } from "context/reducers";
import { AlertContext } from "./AlertProvider";

export const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.jobs.reducer,
    contextReducers.jobs.initialState
  );
  const { addGraphQLAlert } = useContext(AlertContext);

  const init = async () => {
    dispatch({
      type: contextConstants.jobs.JOB_OPPS_LOADING,
      payload: { isLoading: true },
    });

    const { jobOpps, jobTypes, companyNames, jobSkills } =
      await contextActions.jobs.initializeJobs();

    dispatch({
      type: contextConstants.jobs.JOB_OPPS_LOADED,
      payload: { jobOpps },
    });

    dispatch({
      type: contextConstants.jobs.JOB_TYPES_LOADED,
      payload: {
        jobTypes: Object.keys(jobTypes).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.COMPANY_NAMES_LOADED,
      payload: {
        companyNames: Object.keys(companyNames).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.JOB_SKILLS_LOADED,
      payload: {
        jobSkills: Object.keys(jobSkills).sort(),
      },
    });
  };

  const initJob = async (jobId) => {
    const jobOpp = await contextActions.jobs.initializeJob(jobId);

    dispatch({
      type: contextConstants.jobs.JOB_OPP_LOADED,
      payload: { jobOpp },
    });
  };

  const initJobCalendarEvents = async (jobId) => {
    try {
      const jobCalendarEvents =
        await contextActions.jobs.initializeJobCalendarEvents(jobId);

      dispatch({
        type: contextConstants.jobs.JOB_OPP_CALENDAR_EVENTS_LOADED,
        payload: { jobCalendarEvents },
      });
    } catch (error) {
      addGraphQLAlert(error);
      dispatch({
        type: contextConstants.jobs.JOB_OPP_CALENDAR_EVENTS_LOADED,
        payload: { jobCalendarEvents: [] },
      });
    }
  };

  const updateJob = async (jobId, payload) => {
    await contextActions.jobs.updateJob(jobId, payload);
  };

  const updateJobLocally = async (value, valueKey) => {
    const jobOpp = await contextActions.jobs.updateJobLocally(
      state.jobOpp,
      value,
      valueKey
    );

    dispatch({
      type: contextConstants.jobs.JOB_OPP_LOADED,
      payload: { jobOpp },
    });
  };

  const clearJob = () => {
    dispatch({
      type: contextConstants.jobs.JOB_OPP_CLEARED,
    });
  };

  const updateCompanyFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.COMPANY_FILTER_UPDATED,
      payload: { companyFilter: newValue },
    });
  };

  const updateJobTypeFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_TYPE_FILTER_UPDATED,
      payload: { jobTypeFilter: newValue },
    });
  };

  const updateJobSkillsFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_SKILLS_FILTER_UPDATED,
      payload: { jobSkillsFilter: newValue },
    });
  };

  const updateStatusFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.STATUS_FILTER_UPDATED,
      payload: { statusFilter: newValue },
    });
  };

  const clearFilters = () => {
    dispatch({ type: contextConstants.jobs.JOB_FILTERS_CLEARED });
  };

  const resetSimilarJobOppsFilters = () => {
    dispatch({
      type: contextConstants.jobs.SIMILAR_JOB_OPPS_FILTERS_LOADED,
      payload: {
        skills: state.jobOpp.skills.map((sk) => sk.name),
        skillsOperator: "or",
        limit: 1000,
        vectorSearchScore: 88,
        hourlyRate: [
          Math.floor(state.jobOpp.minRate.value),
          Math.ceil(state.jobOpp.maxRate.value),
        ],
      },
    });
  };

  const updateSimilarJobsFilters = (key, value) => {
    dispatch({
      type: contextConstants.jobs.SIMILAR_JOB_OPP_FILTER_CHANGE,
      payload: { key, value },
    });
  };

  const jobOpps = {
    jobOpp: state.jobOpp,
    jobCalendarEvents: state.jobCalendarEvents,
    jobs: state.jobOpps,
    similarJobsFilters: state.similarJobsFilters,
    companyFilter: state.companyFilter,
    companyNames: state.companyNames,
    jobTypeFilter: state.jobTypeFilter,
    jobTypes: state.jobTypes,
    jobSkillsFilter: state.jobSkillsFilter,
    statusFilter: state.statusFilter,
    jobSkills: state.jobSkills,
    loadingJobOpp: state.loadingJobOpp,
    isLoading: state.isLoading,
    updateCompanyFilter,
    updateJobTypeFilter,
    updateJobSkillsFilter,
    updateStatusFilter,
    initJob,
    initJobCalendarEvents,
    updateJob,
    updateJobLocally,
    clearJob,
    clearFilters,
    resetSimilarJobOppsFilters,
    updateSimilarJobsFilters,
    init,
  };

  useEffect(() => {
    (async () => await init())();
  }, []);

  return (
    <JobsContext.Provider value={jobOpps}>{children}</JobsContext.Provider>
  );
};
