import React, { useContext, useCallback, useEffect } from "react";
import Select from "react-select";

import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import qs from "query-string";
import classNames from "classnames";

import { AlertContext, JobsContext } from "context/providers";

import SvgButton from "components/base/SvgButton";
import JobListingCard from "components/JobListingCard";

import { JOB_OPPORTUNITY_STATUSES } from "lookup";

export default function JobOpportunities() {
  const {
    jobs,
    isLoading,
    companyFilter,
    companyNames,
    jobTypeFilter,
    jobTypes,
    jobSkillsFilter,
    statusFilter,
    jobSkills,
    updateCompanyFilter,
    updateJobTypeFilter,
    updateStatusFilter,
    updateJobSkillsFilter,
    clearFilters,
    init,
  } = useContext(JobsContext);

  const { clearAlerts } = useContext(AlertContext);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const clearQs = useCallback(() => {
    navigate(`${pathname}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateQs = useCallback(
    (key, value) => {
      const queryString = qs.stringify({
        ...qs.parse(search),
        [key]: value,
      });

      navigate(`${pathname}?${queryString}`, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const onCompanyChange = useCallback(
    (value) => {
      updateCompanyFilter(value);
      updateQs("companyFilter", value);
    },
    [updateCompanyFilter, updateQs]
  );

  const onJobTypeChange = useCallback(
    (value) => {
      updateJobTypeFilter(value);
      updateQs("jobTypeFilter", value);
    },
    [updateJobTypeFilter, updateQs]
  );

  const onJobSkillsChange = useCallback(
    (value) => {
      updateJobSkillsFilter(value);
      updateQs("jobSkillsFilter", value);
    },
    [updateJobSkillsFilter, updateQs]
  );

  const onStatusChange = useCallback(
    (value) => {
      updateStatusFilter(value);
      updateQs("statusFilter", [value]);
    },
    [updateStatusFilter, updateQs]
  );

  const prepareQs = useCallback(() => {
    if (companyFilter && companyFilter !== "All") {
      updateQs("companyFilter", companyFilter);
    }

    if (jobTypeFilter && jobTypeFilter !== "All") {
      updateQs("jobTypeFilter", jobTypeFilter);
    }

    if (jobSkillsFilter && jobSkillsFilter !== "All") {
      updateQs("jobSkillsFilter", jobSkillsFilter);
    }

    if (statusFilter && !statusFilter.includes("All")) {
      updateQs("statusFilter", statusFilter);
    }
  }, [companyFilter, jobTypeFilter, jobSkillsFilter, statusFilter, updateQs]);

  const prepareSelectedFilters = useCallback(() => {
    const parsed = qs.parse(search);

    Object.keys(parsed).forEach((key) => {
      switch (key) {
        case "companyFilter":
          const chosenCompany = companyNames.find(
            (company) => company === parsed[key]
          );

          if (chosenCompany) {
            onCompanyChange(parsed[key]);
          }

          break;

        case "jobTypeFilter": {
          const chosenJobType = jobTypes.find((type) => type === parsed[key]);

          if (chosenJobType) {
            onJobTypeChange(parsed[key]);
          }

          break;
        }

        case "jobSkillsFilter": {
          const chosenJobSkills = jobSkills.find(
            (skill) => skill === parsed[key]
          );
          if (chosenJobSkills) {
            onJobSkillsChange(parsed[key]);
          }
          break;
        }

        case "statusFilter": {
          const chosenStatus = Object.values(JOB_OPPORTUNITY_STATUSES).find(
            (s) => s === parsed[key]
          );
          if (chosenStatus) {
            onStatusChange(parsed[key]);
          }
          break;
        }

        default: {
          break;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNames, jobTypes, jobSkills]);

  const handleRefresh = () => {
    clearFilters();
    clearQs();
    init();
  };

  const renderJobs = () => {
    if (
      companyFilter === "All" &&
      jobTypeFilter === "All" &&
      jobSkillsFilter === "All" &&
      statusFilter.includes("All")
    ) {
      return jobs.map((job) => <JobListingCard key={job.id} job={job} />);
    }

    return jobs.map((job) => {
      const company =
        job?.organization ||
        job?.customer?.companyDetails?.name ||
        job?.customer?.company;

      if (
        (company === companyFilter || companyFilter === "All") &&
        (job?.jobType?.title === jobTypeFilter || jobTypeFilter === "All") &&
        ((job?.skills.length > 0 &&
          job.skills.some((skill) => skill.name === jobSkillsFilter)) ||
          jobSkillsFilter === "All") &&
        (statusFilter.includes(job?.status) || statusFilter.includes("All"))
      ) {
        return <JobListingCard key={job.id} job={job} />;
      }

      return null;
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(prepareSelectedFilters, [companyNames, jobTypes]);

  useEffect(() => {
    clearAlerts();
    init();
    prepareQs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading && jobSkills?.length === 0) {
    return (
      <div className="flex justify-center mt-8">
        <span className="loader"></span>
      </div>
    );
  }

  const options = [
    { value: "All", label: "All" },
    ...Object.values(JOB_OPPORTUNITY_STATUSES).map((status) => ({
      value: status,
      label: status,
    })),
  ];

  return (
    <div className="relative">
      <div className="max-w-3xl mx-auto my-12 flex flex-col gap-8 px-4">
        <div className="w-full flex justify-center items-center">
          <h3 className="text-center text-2xl font-bold mr-2">Job Search</h3>

          <SvgButton
            icon="refresh"
            className={classNames("w-[20px] transform rotate-180", {
              "animate-spin": isLoading,
              "animate-none": !isLoading,
            })}
            title="Refresh Jobs List"
            onClick={handleRefresh}
          />
        </div>
        <div className="flex justify-between gap-2">
          <div>
            <label className="mr-2 font-bold whitespace-nowrap">
              Customer Name
            </label>
            <select
              value={companyFilter}
              className="block mt-2 border rounded border-black w-full p-1"
              onChange={(e) => onCompanyChange(e.target.value)}
            >
              <option value="All">All</option>
              {companyNames.map((name) => (
                <option value={name} key={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="mr-2 font-bold">Skill</label>
            <select
              value={jobSkillsFilter}
              className="block mt-2 border rounded border-black w-full p-1"
              onChange={(e) => onJobSkillsChange(e.target.value)}
            >
              <option value="All">All</option>
              {jobSkills.map((name) => (
                <option value={name} key={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="font-bold">Job Role</label>
            <div className="block mt-2">
              <select
                value={jobTypeFilter}
                className="block mt-2 border rounded border-black w-full p-1"
                onChange={(e) => onJobTypeChange(e.target.value)}
              >
                <option value="All">All</option>
                {jobTypes.map((jobType, i) => (
                  <option value={jobType} key={`jobtype=${i}`}>
                    {jobType}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <label className="font-bold">Status</label>
          <div className="block mt-2">
            <Select
              value={options.filter((option) =>
                statusFilter.includes(option.value)
              )}
              options={options}
              isMulti
              className="block mt-2 border rounded border-black w-full p-0 md:p-1"
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : [];
                onStatusChange(selectedValues);
              }}
            />
          </div>
        </div>
        {renderJobs()}
      </div>
    </div>
  );
}
