import { API, graphqlOperation } from "aws-amplify";

export default class ApiClient {
  constructor({ onError = () => {} }) {
    this.onError = onError;
  }

  async request({ query, payload = {} }) {
    try {
      const res = await API.graphql(graphqlOperation(query, { ...payload }));

      return res;
    } catch (error) {
      this.onError(error);

      throw error;
    }
  }
}
