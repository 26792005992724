import React, { useContext, useMemo } from "react";
import dayjs from "dayjs";

import {
  RefinementList,
  ToggleRefinement,
  NumericMenu,
  DynamicWidgets,
  Panel,
  connectRefinementList,
  SortBy,
} from "react-instantsearch-dom";

import GeographicalRegion from "./components/GeographicalRegion";
import { SearchContext } from "context/providers";

import {
  DEFAULT_RATE_RANGE,
  LANGUAGE_KEY_NAMES,
  SKILL_STR_FORMATTED_OPTIONS,
  USER_TYPES,
} from "lookup";

import {
  CustomPanel,
  CustomRangeSlider,
  CustomRefinementList,
} from "./components";

const subtractUnix = (amount, unit) =>
  dayjs().subtract(amount, unit).unix() * 1000;

const CREATED_AT_ITEMS = [
  {
    label: "< 1 day",
    start: subtractUnix(1, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 7 days",
    start: subtractUnix(6, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 30 days",
    start: subtractUnix(29, "days"),
    end: dayjs().unix() * 1000,
  },
];

const LAST_ACTIVITY_ITEMS = [
  {
    label: "< 1 day",
    start: subtractUnix(1, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 7 days",
    start: subtractUnix(6, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 30 days",
    start: subtractUnix(29, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 60 days",
    start: subtractUnix(59, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "> 60 days",
    end: dayjs().unix() * 1000,
  },
];

const VirtualRefinementList = connectRefinementList(() => null);

const SearchFilters = () => {
  const {
    searchState,
    operators,
    setOperators,
    geographicalRegions,
    setGeographicalRegions,
    handleSkillsStrChange,
    disjunctiveFacets,
  } = useContext(SearchContext);

  const skillsStr = searchState.skillsStr;

  const mapToFacetString = (firstAttributes, secondAttributes) => {
    const output = [];

    firstAttributes.forEach((firstAttribute) => {
      secondAttributes.forEach((secondAttribute) => {
        output.push(`${firstAttribute}_${secondAttribute}`);
      });
    });

    return output;
  };

  const languagesStr = useMemo(() => {
    const languages =
      searchState.refinementList?.[LANGUAGE_KEY_NAMES.language] || [];
    const languagesLevel =
      searchState.refinementList?.[LANGUAGE_KEY_NAMES.level] || [];

    return mapToFacetString(languages, languagesLevel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.refinementList]);

  const definedRate = useMemo(
    () => searchState?.range?.["ratePerHour.value"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState?.range?.["ratePerHour.value"]]
  );

  const handleChange = (value, valueKey, targetName) => {
    switch (targetName) {
      case "operators": {
        setOperators(valueKey);
        break;
      }

      default: {
        break;
      }
    }
  };

  const formatSkillsExp = (item, option) => {
    return `${item.label}_${option}`;
  };

  const handleSkillsExpChange = (newItem, checked) => {
    let newSkillsStr;

    if (checked) {
      newSkillsStr = [...skillsStr, newItem];
    } else {
      newSkillsStr = skillsStr.filter((item) => !item.startsWith(newItem));
    }
    handleSkillsStrChange(newSkillsStr);
  };

  const handleSkillsNameChange = (skillsName) => {
    if (skillsStr) {
      const newSubOptions = skillsStr.filter((item) =>
        skillsName.some((sk) => item.startsWith(sk))
      );
      handleSkillsStrChange(newSubOptions, skillsName);
    }
  };

  const getSkillsStrCount = (skillsStrItem) => {
    const skillsStrFacets = disjunctiveFacets.find(
      ({ name }) => name === "skills_str"
    );

    return skillsStrFacets?.data?.[skillsStrItem];
  };

  return (
    <>
      <Panel header="sort by">
        <SortBy
          defaultRefinement={process.env.REACT_APP_INDEX_NAME}
          items={[
            {
              value: process.env.REACT_APP_INDEX_NAME,
              label: "Default",
            },
            {
              value: `createdAt_dt_desc-${process.env.REACT_APP_INDEX_NAME}`,
              label: "Date Joined (desc)",
            },
            {
              value: `createdAt_dt_asc-${process.env.REACT_APP_INDEX_NAME}`,
              label: "Date Joined (asc)",
            },
            {
              value: `profileCompletion_desc-${process.env.REACT_APP_INDEX_NAME}`,
              label: "Profile Completion (desc)",
            },
            {
              value: `profileCompletion_asc-${process.env.REACT_APP_INDEX_NAME}`,
              label: "Profile Completion (asc)",
            },
            {
              value: `rate_desc-${process.env.REACT_APP_INDEX_NAME}`,
              label: "Rate (desc)",
            },
            {
              value: `rate_asc-${process.env.REACT_APP_INDEX_NAME}`,
              label: "Rate (asc)",
            },
          ]}
        />
      </Panel>

      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <CustomPanel
          title="Skills"
          checked={operators["skills.name"] === "and"}
          valueKey="skills.name"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple skills. Ex:  C# and Java. Select OR if you want to see talent with either of the skill. Select AND if you would like to see the only talents with both the skills."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            subRefinementOptions={SKILL_STR_FORMATTED_OPTIONS}
            subRefinement={skillsStr}
            currentRefinementChange={handleSkillsNameChange}
            subRefinementChange={handleSkillsExpChange}
            subOptionFormatter={formatSkillsExp}
            getSubRefinementCount={getSkillsStrCount}
            attribute="skills.name"
            placeholder="Search for skills"
            operator={operators["skills.name"]}
            limit={15}
            showMoreLimit={1000}
            showCount
            searchable
            showMore
            showChip
          />
        </CustomPanel>
        <VirtualRefinementList attribute="skills_str" />
        <CustomPanel
          title="Test Name"
          checked={operators["assessments.testName"] === "and"}
          valueKey="assessments.testName"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple tests. Ex:  C# and Java. Select OR if you want to see talent with either of the test. Select AND if you would like to see the only talents with both the tests."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="assessments.testName"
            placeholder="Search for tests"
            operator={operators["assessments.testName"]}
            limit={15}
            showCount
            searchable
            showMore
            showChip
          />
        </CustomPanel>
        <Panel header="Assessments Final Score">
          <NumericMenu
            attribute="assessments.finalScore"
            items={[
              { label: "<= 25", start: 0, end: 25 },
              { label: "26 <= 50", start: 26, end: 50 },
              { label: "51 <= 75", start: 51, end: 75 },
              { label: ">= 76", start: 76, end: 100 },
              { label: "= 100", start: 100, end: 100 },
            ]}
            transformItems={(items) =>
              items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              })
            }
          />
        </Panel>
        <Panel header="Hourly Rate">
          <CustomRangeSlider
            attribute="ratePerHour.value"
            {...(definedRate?.min && { min: DEFAULT_RATE_RANGE.min })}
            {...(definedRate?.max && { max: DEFAULT_RATE_RANGE.max })}
            displayMin={DEFAULT_RATE_RANGE.min}
            displayMax={DEFAULT_RATE_RANGE.max}
            showInputs
          />
        </Panel>
        <CustomPanel
          title="Job Role"
          checked={operators["activeJobTypeTitles"] === "and"}
          valueKey="activeJobTypeTitles"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="Select OR if you want to see talent with EITHER of the job roles. Select AND if you would like to only see talent with BOTH of the job roles."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <RefinementList
            attribute="activeJobTypeTitles"
            translations={{
              placeholder: "Search for a specific job role",
            }}
            operator={operators["activeJobTypeTitles"]}
            searchable
          />
        </CustomPanel>
        <Panel header="Location (Country)">
          <RefinementList
            attribute="location.countryName"
            translations={{
              placeholder: "Search in a specific location",
            }}
            limit={5}
            searchable
            showMore
          />
        </Panel>
      </DynamicWidgets>
      <Panel header="Location (Region)">
        <GeographicalRegion
          geographicalRegions={geographicalRegions}
          searchState={searchState}
          setGeographicalRegions={setGeographicalRegions}
        />
      </Panel>
      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <Panel header="User Type">
          <CustomRefinementList
            attribute="userType"
            predefinedValue={[USER_TYPES.FREELANCER]}
            placeholder="Search for user type"
            showCount
            searchable
            showChip
          />
        </Panel>
        <CustomPanel
          title="Community"
          checked={operators["cognitoGroups"] === "and"}
          valueKey="cognitoGroups"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="Select OR if you want to see talent within ANY of these groups. Select AND if you would like to only see talent within ALL of the selected groups."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <RefinementList
            attribute="cognitoGroups"
            operator={operators["cognitoGroups"]}
          />
        </CustomPanel>
        <Panel header="User Status">
          <RefinementList attribute="status" />
        </Panel>
        <Panel header="User Visibility">
          <RefinementList attribute="visibility" />
        </Panel>
        <Panel header="Availability">
          <RefinementList attribute="availability" />
        </Panel>
        <CustomPanel
          title="Language"
          checked={operators["knownLanguages.language"] === "and"}
          valueKey="knownLanguages.language"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple languages. Ex:  English and Spanish. Select OR if you want to see talent with either of the language. Select AND if you would like to see the only talents with both the language."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="knownLanguages.language"
            translations={{
              placeholder: "Search for languages",
            }}
            operator={operators["knownLanguages.language"]}
            limit={5}
            showMoreLimit={1000}
            showCount
            showMore
            searchable
          />
        </CustomPanel>
        <Panel header="Language Level">
          <CustomRefinementList
            attribute="knownLanguages.level"
            placeholder="Search for language level"
            showCount={false}
          />
        </Panel>
        <VirtualRefinementList
          attribute="knownLanguages_str"
          defaultRefinement={languagesStr}
        />
        <Panel header="Talent source">
          <RefinementList attribute="talentSource" />
        </Panel>
        <Panel header="Referrer Code">
          <RefinementList
            attribute="referrerCode"
            translations={{
              placeholder: "Search for specific referrer code",
            }}
            limit={5}
            searchable
            showMore
          />
        </Panel>
        <Panel header="% of Profile Completion">
          <NumericMenu
            attribute="profileCompletion"
            items={[
              { label: "<= 25", start: 0, end: 25 },
              { label: "26 <= 50", start: 26, end: 50 },
              { label: "51 <= 75", start: 51, end: 75 },
              { label: ">= 76", start: 76, end: 100 },
              { label: "= 100", start: 100, end: 100 },
            ]}
            transformItems={(items) =>
              items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              })
            }
          />
        </Panel>
        <Panel header="Last activity date">
          <NumericMenu
            attribute="lastActivityDate_dt"
            items={LAST_ACTIVITY_ITEMS}
            transformItems={(items) => {
              return items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              });
            }}
          />
        </Panel>
        <Panel header="Sign Up Date">
          <NumericMenu
            attribute="createdAt_dt"
            items={CREATED_AT_ITEMS}
            transformItems={(items) => {
              return items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              });
            }}
          />
        </Panel>
        <ToggleRefinement
          attribute="agreedToMarketing"
          label="Agreed to marketing"
          value={false}
          defaultRefinement={false}
        />
        <ToggleRefinement
          attribute="agreedToTerms"
          label="Agreed to terms"
          value={true}
          defaultRefinement={true}
        />
      </DynamicWidgets>
    </>
  );
};

export default SearchFilters;
