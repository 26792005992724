import React, { Fragment, useState } from "react";
import { marked } from "marked";
import dayjs from "dayjs";

import SvgIcon from "components/base/SvgIcon";
import SvgButton from "components/base/SvgButton";

const Note = ({
  isPublic,
  creator,
  content,
  shouldRenderSeparator,
  isAllowToDelete,
  onDelete,
  noteTypeIcon,
  ...rest
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const prepareDate = (createdAt) => {
    const createdDaysAgo = dayjs().diff(createdAt, "days");
    const dateFormat =
      createdDaysAgo >= 2 ? "MMM DD YYYY" : "MMM DD YYYY, hh:mm";

    return dayjs(createdAt).format(dateFormat);
  };

  const handleDeleteClick = () => {
    const confirmDeleting = window.confirm(
      "Are you sure you want to delete the note?"
    );

    if (confirmDeleting) {
      setIsDeleting(true);
      onDelete(rest.id);
    }
  };

  const renderDeleteUi = () => {
    return (
      <SvgButton
        icon="cross"
        iconProps={{ fill: "tomato", title: "Delete this note?" }}
        onClick={handleDeleteClick}
        isLoading={isDeleting}
      />
    );
  };

  return (
    <Fragment>
      <div className="flex my-2 py-2">
        <div className="flex flex-col items-center mr-2">
          <SvgIcon
            type={isPublic ? "notePublic" : "notePrivate"}
            className="font-red-500 min-w-[24px] min-h-[24px] w-[24px] h-[24px]"
          />
        </div>

        <div className="flex flex-col justify-between w-full">
          <div className="flex justify-between">
            <p className="text-xs">{creator || "user"}</p>

            <span className="text-xs whitespace-nowrap">
              {prepareDate(rest.createdAt)}
            </span>
          </div>

          <div className="w-full flex justify-between items-center">
            <div
              className="w-full"
              dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
            />

            {isAllowToDelete && renderDeleteUi()}
          </div>

          {noteTypeIcon && (
            <div className="flex justify-end items-center mt-2">
              <SvgIcon type={noteTypeIcon.icon} className="w-[18px] mr-1" />
              <span className="text-xs">{noteTypeIcon.label}</span>
            </div>
          )}
          <div className="flex justify-end items-center mt-2">
            {(() => {
              const iconType = isPublic ? "horn" : "eyeClosed";
              const text = isPublic ? "Visible to customer" : "Private";

              return (
                <>
                  <SvgIcon type={iconType} className="w-[18px] mr-1" />
                  <span className="text-xs">{text}</span>
                </>
              );
            })()}
          </div>
        </div>
      </div>

      {shouldRenderSeparator && <hr className="w-full border-t border-black" />}
    </Fragment>
  );
};

export default Note;
