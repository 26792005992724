import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom-v5-compat";
import MarkdownIt from "markdown-it";
import underline from "markdown-it-plugin-underline";

import { JobsContext, NotesContext, SearchContext } from "context/providers";

import Applications from "components/Applications/index";

import JobDetailsPageHeader from "components/JobDetailsPageHeader";

const mdParser = new MarkdownIt().use(underline);
// Open links in new tab
const defaultRender =
  mdParser.renderer.rules.link_open ||
  ((tokens, idx, options, env, self) => {
    return self.renderToken(tokens, idx, options);
  });

mdParser.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex("target");

  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

const JobOppDetails = ({ user }) => {
  const params = useParams();

  const { searchState, setSearchState } = useContext(SearchContext);

  const { loadingJobOpp, jobOpp, initJob, initJobCalendarEvents } =
    useContext(JobsContext);

  const { jobOppNotes, job: initJobNotes } = useContext(NotesContext);

  const initNotes = async () => {
    try {
      await initJobNotes(jobOpp);
    } catch (error) {
      console.error("initJobNotes error: ", error);
    }
  };

  useEffect(() => {
    (async () => {
      await initJob(params.id);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!loadingJobOpp) {
        await initJobCalendarEvents(params.id);
        document.title = `Torc Search Tool - ${jobOpp.title}`;
      }

      if (!loadingJobOpp && !jobOppNotes[jobOpp.id]) {
        initNotes();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingJobOpp]);

  if (loadingJobOpp) {
    return (
      <div className="flex justify-center pt-8">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      <JobDetailsPageHeader
        jobOpp={jobOpp}
        user={user}
        searchState={searchState}
        setSearchState={setSearchState}
      />
      <Applications user={user} />
    </>
  );
};

export default JobOppDetails;
