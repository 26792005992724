import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SvgIcon from "components/base/SvgIcon";

const Chip = ({ value, isActive, className, onClose, onClick }) => (
  <div
    className={classNames(
      "flex items-center max-w-full text-sm border border-blue-300 text-blue-300 rounded-full whitespace-nowrap transition-all",
      className,
      {
        "bg-blue-300 text-white": isActive,
        "pr-1.5": isActive && onClose,
        "hover:shadow": onClick,
      }
    )}
  >
    <button
      onClick={onClick}
      className={classNames("px-2 overflow-hidden text-ellipsis", {
        "cursor-pointer": !!onClick,
        "cursor-default": !onClick,
      })}
    >
      {value}
    </button>

    {onClose && isActive && (
      <button className="shrink-0" type="button" onClick={onClose}>
        <SvgIcon type="xmark" className="w-2" />
      </button>
    )}
  </div>
);

Chip.propTypes = {
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

Chip.defaultProps = {
  isActive: false,
  className: "",
  onClose: null,
  onClick: null,
};

export default Chip;
