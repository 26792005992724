import React, { useContext, useMemo } from "react";
import algoliasearch from "algoliasearch";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import { JOB_APPLICATION_MATCH_STATUS, USER_STATUS } from "lookup";
import { MatchContext, SearchContext } from "context/providers";

import ResultsContainer from "./Results";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const ALGOLIA_FILTERS = `status:${USER_STATUS.ACTIVE}`;

const SearchContainer = ({ children }) => {
  const {
    searchState,
    setSearchState,
    hideSkipped,
    hideRejected,
    hideMatchedAndApplied,
  } = useContext(SearchContext);
  const { matches } = useContext(MatchContext);

  const filteredObjectIDs = useMemo(() => {
    const skippedMatchesUserIDs = matches
      .filter((match) => match.status === JOB_APPLICATION_MATCH_STATUS.SKIPPED)
      .map((match) => match.application.user.id);

    const rejectedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER
      )
      .map((match) => match.application.user.id);

    const matchedAppliedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED ||
          match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED
      )
      .map((match) => match.application.user.id);

    const filt = [];
    if (hideSkipped) filt.push(...skippedMatchesUserIDs);
    if (hideRejected) filt.push(...rejectedMatchesUserIDs);
    if (hideMatchedAndApplied) filt.push(...matchedAppliedMatchesUserIDs);

    return filt;
  }, [matches, hideSkipped, hideRejected, hideMatchedAndApplied]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.REACT_APP_INDEX_NAME}
      onSearchStateChange={setSearchState}
      searchState={searchState}
    >
      <ResultsContainer>{children}</ResultsContainer>

      <Configure
        filters={`${ALGOLIA_FILTERS}${searchState.skillsStrFilter}`}
        facets={["objectID"]}
        facetsExcludes={{
          objectID: filteredObjectIDs,
        }}
        restrictSearchableAttributes={
          searchState.searchByAttributes?.attributes || []
        }
      />
    </InstantSearch>
  );
};

export default SearchContainer;
