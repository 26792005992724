import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useRef } from "react";

const SelectDropDown = ({ options, selectedOption, action }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    action(option);
    setIsOpen(false);
  };

  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    const handleOutsideClick = (e) => {
      if (
        isOpen &&
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left h-full">
      <button
        title={selectedOption.title || "Default"}
        ref={buttonRef}
        onClick={toggleDropdown}
        className="shadow border border-gray-300 border-r-none px-3 py-2 rounded-r-md flex items-center h-[40px]"
      >
        <span className="text-sm whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">
          <span className="hidden lg:block">
            {selectedOption.title || "Default"}
          </span>
        </span>
        <svg
          className={`-mr-1 ml-2 h-5 w-5 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="z-[50] origin-top-left absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        >
          <ul
            className="py-1 whitespace-nowrap"
            role="menu"
            aria-orientation="vertical"
          >
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => selectOption(option)}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                role="menuitem"
              >
                {option.title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

SelectDropDown.propTypes = {
  options: PropTypes.array,
  selectedOption: PropTypes.object,
  action: PropTypes.func,
};

SelectDropDown.defaultProps = {
  selectedOption: {},
  options: [],
  action: () => {},
};

export default SelectDropDown;
