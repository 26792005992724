import DownloadResults from "components/DownloadResults";
import NumericCircle from "components/NumericCircle";
import SvgButton from "components/base/SvgButton";
import React from "react";

const baseClassName =
  "transform transition duration-500 hover:scale-110 hover:!bg-[#c3ecff] hover:!fill-sky-600";

const ActionButtonsPanel = ({
  jobCalendarEvents,
  user,
  showModal,
  isJobPage,
}) => {
  const getUpComingEvents = () => {
    return (
      jobCalendarEvents
        ?.filter((item) => new Date(item.end).getTime() >= new Date().getTime())
        .sort((a, b) => a.date - b.date) || []
    );
  };
  return (
    <div className="w-full sticky top-0 px-2">
      <div className="flex flex-col gap-4 items-center w-full pt-4">
        {isJobPage && (
          <SvgButton
            onClick={() =>
              showModal({ type: "jobModal", user, initialTabIndex: 1 })
            }
            icon="magicWand"
            className={`${baseClassName} p-1.5`}
          />
        )}
        <div>
          <DownloadResults className={baseClassName} />
        </div>
        {isJobPage && (
          <div className="relative">
            <SvgButton
              onClick={() => showModal({ type: "calendar" })}
              icon="calendar"
              className={`${baseClassName} p-1.5`}
            />
            {getUpComingEvents()?.length > 0 && (
              <p className="absolute top-0 -right-2">
                <NumericCircle
                  value={getUpComingEvents().length}
                  className={"!bg-red-500 !text-white"}
                />
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionButtonsPanel;
