import React, { useContext, useEffect } from "react";
import { connectStateResults } from "react-instantsearch-dom";

import { SearchContext } from "context/providers";

const ResultsContainer = ({ children, searchResults }) => {
  const { setHits, setDisjunctiveFacets } = useContext(SearchContext);

  useEffect(() => {
    setHits(searchResults?.hits || []);
    setDisjunctiveFacets(searchResults?.disjunctiveFacets || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults?.params, searchResults?.index]);

  return <>{children}</>;
};

export default connectStateResults(ResultsContainer);
