import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import classNames from "classnames";

const Select = ({
  label,
  value,
  options,
  placeholder,
  valueKey,
  errorMessage,
  isRequired,
  onChange,
}) => {
  const id = useMemo(nanoid, []);

  const getLabel = () => {
    if (isRequired) {
      return `${label}*`;
    }

    return label;
  };

  const handleChange = ({ target: { value } }) => {
    onChange({ value, valueKey });
  };

  return (
    <div className="flex flex-col mb-4 relative">
      {!!label && (
        <label htmlFor={id} className="mb-1">
          {getLabel()}
        </label>
      )}

      <select
        id={id}
        className="border-2 outline-none p-2 rounded transition-all focus:border-gray-300"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <p
        className={classNames(
          "text-red-500 text-sm font-bold mt-1 absolute transition-all",
          {
            "-bottom-4 opacity-0": !errorMessage,
            "-bottom-6": !!errorMessage,
          }
        )}
      >
        {errorMessage}
      </p>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
};

Select.defaultProps = {
  label: "",
  placeholder: "",
  errorMessage: "",
  isRequired: false,
};

export default Select;
