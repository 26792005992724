import "./index.css";

const classNames = require("classnames");

const NumericCircle = ({ className, isActive, value }) => {
  return (
    <span
      className={classNames(
        "bg-gray-200 numberCircle",
        {
          "text-white !bg-sky-600": isActive,
        },
        className
      )}
    >
      <span> {value}</span>
    </span>
  );
};

export default NumericCircle;
