import { Alert as AlertComponent } from "@aws-amplify/ui-react";
import { AlertContext } from "context/providers";
import { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const MAX_LENGTH = 50;

const Alert = ({
  id,
  message,
  title,
  variation,
  duration,
  hasIcon,
  callback,
}) => {
  const { removeAlert } = useContext(AlertContext);
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText((value) => !value);
  };

  const displayText = showFullText
    ? message
    : message.length > MAX_LENGTH
    ? message.slice(0, MAX_LENGTH) + "..."
    : message;

  const timerRef = useRef(null);
  const shouldDismiss = useRef(true);
  const alertRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (shouldDismiss.current) removeAlert(id);
    }, duration);

    return () =>
      setTimeout(() => {
        clearTimeout(timerRef.current);
      }, 5);
  }, [id, removeAlert, duration]);

  const handleOnDismiss = () => {
    clearTimeout(timerRef.current);
    removeAlert(id);
    timerRef.current = null;
  };

  useEffect(() => {
    const mouseMove = (e) => {
      const eleBounds = alertRef?.current?.getBoundingClientRect();
      if (!eleBounds) return;
      if (e.clientX >= eleBounds.left && e.clientX <= eleBounds.right) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          shouldDismiss.current = false;
        }
      } else {
        if (!timerRef.current) {
          timerRef.current = setTimeout(() => {
            removeAlert(id);
          }, duration);
        }
      }
    };
    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={alertRef} className="max-w-2xl">
      <AlertComponent
        variation={variation}
        isDismissible
        onDismiss={handleOnDismiss}
        hasIcon={hasIcon}
      >
        <>
          <p className="text-md font-semibold">{title}</p>
          <p>{displayText}</p>
          {message.length > MAX_LENGTH && (
            <div
              onClick={toggleText}
              className="cursor-pointer text-blue-600 underline"
            >
              {showFullText ? "Less" : "More"}
            </div>
          )}
          {callback && (
            <div
              className="cursor-pointer text-blue-600 underline font-bold"
              onClick={() => {
                handleOnDismiss();
                callback.action();
              }}
            >
              {callback.label || "Callback..."}
            </div>
          )}
        </>
      </AlertComponent>
    </div>
  );
};

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  variation: PropTypes.string,
  duration: PropTypes.number,
  hasIcon: PropTypes.bool,
};

Alert.defaultProps = {
  message: "",
  variation: "error",
  duration: 5000,
  hasIcon: true,
};

export default Alert;
