import React, { useContext, useEffect } from "react";

import { JobsContext } from "context/providers";

import Applications from "components/Applications";

const UserSearch = ({ signOut }) => {
  const { clearJob } = useContext(JobsContext);

  useEffect(() => {
    return clearJob;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Applications signOut={signOut} />;
};

export default UserSearch;
