import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  alerts: [],
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.alert.ALERT_ADDED: {
      draft.alerts.unshift(payload.alert);
      break;
    }

    case contextConstants.alert.ALERT_REMOVED: {
      const index = draft.alerts.findIndex((alert) => alert.id === payload.id);
      if (index !== -1) draft.alerts.splice(index, 1);
      break;
    }

    case contextConstants.alert.ALERTS_CLEARED: {
      draft.alerts = [];
      break;
    }

    default: {
      break;
    }
  }
});
