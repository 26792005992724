/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://cc7kwnqydjhbzfdv4bc4ykn6f4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-uuiwsbic5zbkti3qmz25y67p34",
    "aws_cognito_identity_pool_id": "us-east-1:a572e117-eba9-430e-b841-6572132d71f7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wmMYxA1kJ",
    "aws_user_pools_web_client_id": "3ttb7dtqmgpredi7iecfrhqsec",
    "oauth": {
        "domain": "torc-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://admin.torc.dev,https://id.opentorc.com/,https://platform.opentorc.com/,https://platform.torc.dev,https://search-randstad.torc.dev,https://search.opentorc.com/,https://search.torc.dev",
        "redirectSignOut": "http://localhost:3000/,https://admin.torc.dev,https://id.opentorc.com/,https://platform.opentorc.com/,https://platform.torc.dev,https://search-randstad.torc.dev,https://search.opentorc.com/,https://search.torc.dev",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "GithubToken-production",
            "region": "us-east-1"
        },
        {
            "tableName": "Badge-production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "torcidentitydbaf2ff0ce664e0ba096a94868fde872161954-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
