import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import {
  SearchBox,
  HitsPerPage,
  Stats,
  Pagination,
} from "react-instantsearch-dom";

import { APPLICATION_TAB_NAMES } from "lookup";
import { JobsContext, MatchContext, SearchContext } from "context/providers";
import { mapHits } from "utils/helpers/search";
import { mapMatchesToHit } from "utils/helpers/match";
import { createFilter } from "./helpers/userCard";

import UserCardResult from "./components/UserCardResult";
import DownloadResults from "components/DownloadResults";
import { Filters } from "./components";
import Checkbox from "components/base/Checkbox";
import CustomToggle from "components/base/Toggle";

const DummyHits = ({ message, renderMessage }) => {
  if (!!renderMessage) {
    return (
      <div className="text-center text-red-400 text-lg mb-3">
        {renderMessage()}
      </div>
    );
  }

  return <div className="text-center text-red-400 text-lg mb-3">{message}</div>;
};

const SORTBYKEY = {
  MATCHDATE: "Matched Date",
  LASTNAME: "Last name",
  RATE: "Rate",
};

const SORTBYDIRECTION = {
  ASCENDING: "Ascending",
  DESCENDING: "Descending",
};

const SORTBYKEYFIELD = {
  [SORTBYKEY.MATCHDATE]: "match.createdAt",
  [SORTBYKEY.LASTNAME]: "family_name",
  [SORTBYKEY.RATE]: "ratePerHour.value",
};

function accessNestedProperty(obj, path) {
  let keys = path.split(".");
  let current = obj;

  for (let key of keys) {
    if (!current[key]) {
      return;
    }
    current = current[key];
  }

  return current;
}

function sortComparator(a, b, field) {
  const valueA = accessNestedProperty(a, SORTBYKEYFIELD[field]);
  const valueB = accessNestedProperty(b, SORTBYKEYFIELD[field]);

  if (valueA && valueB) {
    if (field === SORTBYKEY.MATCHDATE) {
      return new Date(valueA) - new Date(valueB);
    }

    if (field === SORTBYKEY.LASTNAME) {
      return valueA.localeCompare(valueB);
    }

    if (field === SORTBYKEY.RATE) {
      return valueA - valueB;
    }
  } else if (!valueA) {
    return 1;
  } else if (!valueB) {
    return -1;
  }

  return Number.NEGATIVE_INFINITY;
}

const UserCardList = ({
  activeTabName,
  showStatusColor,
  stats,
  search,
  pagination,
  downloadResults,
  collectionKey,
  noResultsMessage,
  renderCustomNoResults,
  showActions,
  excludedMatchStatuses,
  filterOutHitByMatchStatus,
  matchStatus,
  filter,
  customFilter,
  allowSorting,
}) => {
  const { matches, bestFit } = useContext(MatchContext);
  const {
    hits,
    hideSkipped,
    setHideSkipped,
    hideRejected,
    setHideRejected,
    hideMatchedAndApplied,
    setHideMatchedAndApplied,
  } = useContext(SearchContext);
  const { jobOpp, updateJob, updateJobLocally } = useContext(JobsContext);

  const [filters, setFilters] = useState({});
  const [sortOrder, setSortOrder] = useState({ key: null, direction: null });
  const [jobIsUpdating, setJobIsUpdating] = useState(false);

  const { finalItems, sourceItems } = useMemo(() => {
    let finalItems = [];
    let sourceItems;

    switch (collectionKey) {
      case "hits": {
        finalItems = mapHits(hits, matches);
        sourceItems = mapHits(hits, matches);
        break;
      }

      case "bestFit": {
        finalItems = mapHits(bestFit, matches, filterOutHitByMatchStatus);
        sourceItems = mapHits(bestFit, matches, filterOutHitByMatchStatus);
        break;
      }

      case "match": {
        finalItems = mapMatchesToHit(
          matches,
          matchStatus,
          activeTabName === APPLICATION_TAB_NAMES.CALIBRATION,
          excludedMatchStatuses
        );
        sourceItems = mapMatchesToHit(
          matches,
          matchStatus,
          activeTabName === APPLICATION_TAB_NAMES.CALIBRATION,
          excludedMatchStatuses
        );
        break;
      }

      default: {
        break;
      }
    }

    if (!!filter.length) {
      const finalItemsCpy = finalItems.filter((hit) => {
        let conditionValue;

        Object.keys(filters).forEach((filtersKey) => {
          const filtersByKey = filters[filtersKey].keys || {};
          Object.keys(filtersByKey).forEach((filterKey) => {
            if (filtersByKey[filterKey].value) {
              if (filters[filtersKey].filterCondition) {
                conditionValue =
                  conditionValue && hit.match?.[filtersKey] === filterKey;
              } else {
                conditionValue =
                  conditionValue || hit.match?.[filtersKey] === filterKey;
              }
            }
          });
        });

        return conditionValue;
      });

      if (sortOrder.key) {
        finalItemsCpy.sort((a, b) => {
          if (sortOrder.direction === SORTBYDIRECTION.ASCENDING) {
            return sortComparator(a, b, sortOrder.key);
          } else {
            return sortComparator(b, a, sortOrder.key);
          }
        });
      }

      return { finalItems: finalItemsCpy, sourceItems };
    }

    if (sortOrder.key) {
      finalItems.sort((a, b) => {
        if (sortOrder.direction === SORTBYDIRECTION.ASCENDING) {
          return sortComparator(a, b, sortOrder.key);
        } else {
          return sortComparator(b, a, sortOrder.key);
        }
      });
    }

    return { finalItems, sourceItems };
  }, [
    activeTabName,
    collectionKey,
    hits,
    bestFit,
    matches,
    matchStatus,
    excludedMatchStatuses,
    filterOutHitByMatchStatus,
    filter,
    filters,
    sortOrder,
  ]);

  const clearFilters = () => {
    setFilters((prev) => {
      const cleared = {};

      Object.keys(prev).forEach((filterKey) => (cleared[filterKey] = true));

      return cleared;
    });
  };

  const renderCustomFilter = () => {
    return (
      <div className="w-full flex flex-wrap gap-x-4">
        {customFilter.includes("hideSkipped") && (
          <Checkbox
            label="Hide Skipped Users"
            className="mt-0"
            checked={hideSkipped}
            onChange={setHideSkipped}
          />
        )}
        {customFilter.includes("hideRejected") && (
          <Checkbox
            label="Hide Rejected Users"
            className="mt-0"
            checked={hideRejected}
            onChange={setHideRejected}
          />
        )}
        {customFilter.includes("hideMatchedAndApplied") && (
          <Checkbox
            label="Hide Matched/Applied Users"
            className="mt-0"
            checked={hideMatchedAndApplied}
            onChange={setHideMatchedAndApplied}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!!filter.length) {
      const createdFilters = {};
      filter.forEach((f) => {
        createdFilters[f] = { keys: createFilter(sourceItems, f) };
        createdFilters[f].filterCondition = false;
      });
      setFilters(createdFilters);
    }

    return () => {
      if (!!filter.length) {
        clearFilters();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!filter.length, sourceItems.length]);

  const handleCalibrationChange = async (value, key) => {
    setJobIsUpdating(true);
    await updateJob(jobOpp.id, { [key]: value });
    updateJobLocally(value, key);
    setJobIsUpdating(false);
  };

  const handleSortOrderChange = (value) => {
    const [sortOrderKey, sortOrderDirection] = value.split("-");

    if (!sortOrderDirection) {
      setSortOrder({ key: null, direction: null });
    } else {
      setSortOrder({ key: sortOrderKey, direction: sortOrderDirection });
    }
  };

  return (
    <div
      className={classNames("flex flex-col gap-4 h-full px-6 pt-2", {
        "bg-slate-100": !!finalItems.length,
      })}
    >
      <div className="flex justify-between items-center">
        {customFilter?.length > 0 && renderCustomFilter()}

        {activeTabName === APPLICATION_TAB_NAMES.CALIBRATION && (
          <div
            className={classNames("text-sm font-semibold text-indigo-800", {
              "animate-pulse": jobIsUpdating,
            })}
            style={{ color: "#5a5e9a" }}
          >
            <CustomToggle
              checked={jobOpp?.calibrationIsEnabled ? true : false}
              valueKey={"calibrationIsEnabled"}
              leftLabel="OFF"
              rightLabel="ON"
              onChange={handleCalibrationChange}
            />
          </div>
        )}

        {stats && <Stats />}
      </div>

      {search && (
        <SearchBox
          translations={{
            placeholder: "Search for users",
          }}
          autoFocus
        />
      )}

      <div
        className={classNames({
          "flex items-center": true,
          "justify-end": !filter.length,
          "justify-between": !!filter.length,
        })}
      >
        {!!filter.length && <Filters filters={filters} onChange={setFilters} />}

        {allowSorting && (
          <select
            value={`${sortOrder.key}-${sortOrder.direction}`}
            className="block mt-2 border rounded border-black p-1 w-1/4"
            onChange={(e) => handleSortOrderChange(e.target.value)}
          >
            <option value="">Sort By</option>
            {Object.entries(SORTBYKEY).map(([key, value]) =>
              Object.entries(SORTBYDIRECTION).map(([dirKey, dirValue]) => (
                <option
                  value={`${value}-${dirValue}`}
                  key={`${value}-${dirValue}`}
                >
                  {`${value} (${dirValue})`}
                </option>
              ))
            )}
          </select>
        )}
      </div>

      {!!finalItems.length ? (
        finalItems.map((hit) => {
          const clonedHit = cloneDeep(hit);

          return (
            <UserCardResult
              key={clonedHit.id}
              hit={clonedHit}
              activeTabName={activeTabName}
              showStatusColor={showStatusColor}
              showActions={showActions}
              collectionKey={collectionKey}
            />
          );
        })
      ) : (
        <DummyHits
          message={noResultsMessage}
          renderMessage={renderCustomNoResults}
        />
      )}

      {pagination && (
        <div className="my-8 flex justify-between">
          <Pagination />

          <HitsPerPage
            items={[
              { value: 10, label: "Show 10 hits" },
              { value: 25, label: "Show 25 hits" },
            ]}
            defaultRefinement={10}
          />
        </div>
      )}

      {downloadResults && <DownloadResults />}
    </div>
  );
};

UserCardList.propTypes = {
  showStatusColor: PropTypes.bool,
  stats: PropTypes.bool,
  search: PropTypes.bool,
  pagination: PropTypes.bool,
  downloadResults: PropTypes.bool,
  collectionKey: PropTypes.string,
  noResultsMessage: PropTypes.string,
  activeTabName: PropTypes.string,
  renderCustomNoResults: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([undefined]),
  ]),
  showActions: PropTypes.bool,
  filter: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([""])]),
  customFilter: PropTypes.array,
  excludedMatchStatuses: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
  matchStatus: PropTypes.array,
  allowSorting: PropTypes.bool,
};

UserCardList.defaultProps = {
  showStatusColor: false,
  stats: false,
  search: false,
  pagination: false,
  downloadResults: false,
  collectionKey: "hits",
  noResultsMessage: "No results to display",
  activeTabName: "ALLUSERS",
  renderCustomNoResults: undefined,
  showActions: false,
  filter: [],
  customFilter: [],
  excludedMatchStatuses: undefined,
  matchStatus: undefined,
  allowSorting: false,
};

export default UserCardList;
