import Base from "./Base.js";

import { getMatch } from "api/graphql/queries.js";

import {
  createMatch,
  deleteMatch,
  updateMatch,
  getAutoMatches,
} from "api/graphql/mutations.js";

export default class MatchAPI extends Base {
  get(payload) {
    return this.apiClient.request({ query: getMatch, payload });
  }

  listBestFit(payload) {
    return this.apiClient.request({ query: getAutoMatches, payload });
  }

  create(payload) {
    return this.apiClient.request({ query: createMatch, payload });
  }

  update(payload) {
    return this.apiClient.request({ query: updateMatch, payload });
  }

  delete(payload) {
    return this.apiClient.request({ query: deleteMatch, payload });
  }
}
