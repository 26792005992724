import classNames from "classnames";

import { Chip } from "components/SearchFilters/components";
import SvgButton from "components/base/SvgButton";
import { ModalContext } from "context/providers";
import { JOB_OPPORTUNITY_TIME_COMMITMENT } from "lookup";
import React, { useContext, useMemo } from "react";
import { getCustomerName } from "utils/helpers/jobOppDetails";

const JobDetailsPageHeader = ({
  user,
  jobOpp,
  searchState,
  setSearchState,
}) => {
  const { showModal } = useContext(ModalContext);
  const definedSkills = useMemo(
    () => searchState.refinementList?.["skills.name"] || [],
    [searchState.refinementList]
  );

  const setSkills = (skills) => {
    const finalState = {
      ...searchState,
      refinementList: { ...searchState.refinementList, "skills.name": skills },
    };

    setSearchState(finalState);
  };

  const handleDeleteDefinedSkill = (skill) => {
    const skills = definedSkills.filter((el) => el !== skill);

    setSkills(skills);
  };

  const handleAddDefinedSkill = (skill) => {
    const skills = Array.from(new Set([...definedSkills, skill]));

    setSkills(skills);
  };

  const renderJobField = (label, value) => (
    <div className="flex flex-col gap-y-2 w-1/2 sm:w-min">
      <p className="uppercase text-base text-gray-400 font-bold">{label}</p>
      <p>{value}</p>
    </div>
  );

  const getTimeCommitmentLabel = (jobOpp) => {
    if (jobOpp.timeCommitment === JOB_OPPORTUNITY_TIME_COMMITMENT.FULLTIME) {
      return "Full-time";
    }

    return "Part-time";
  };

  const getJobLength = (jobOpp) => {
    if (jobOpp?.jobLengthInWeeks) {
      return `${jobOpp.jobLengthInWeeks}
          ${jobOpp.jobLengthInWeeks > 1 ? "weeks" : "week"}`;
    }
    return `${jobOpp?.jobLength} ${jobOpp?.jobLength > 1 ? "months" : "month"}`;
  };

  const getStartDate = (jobOpp) => {
    const startDate = new Date(jobOpp.startDate);
    if (startDate <= new Date()) {
      return "Immediate";
    }

    const year = startDate.getFullYear();
    const month = (1 + startDate.getMonth()).toString().padStart(2, "0");
    const day = startDate.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  return (
    <div className="min-h-96 w-full px-4 px-12 md:px-36 py-12">
      <div className="w-full h-full bg-white flex flex-col gap-y-6 pb-2">
        {jobOpp?.id && (
          <>
            <div className="flex flex-wrap gap-x-2">
              <h1 className="text-lg md:text-4xl font-nexa font-bold mb-2">
                {jobOpp.title}
              </h1>
              <SvgButton
                icon="info"
                className="!h-[30px] !w-[30px] !min-w-[30px] !min-h-[30px]"
                onClick={() => showModal({ type: "jobModal", user })}
              />
            </div>
            <div className="flex justify-start flex-wrap gap-x-0 sm:gap-x-4 md:gap-x-7 lg:gap-x-14 gap-y-4 whitespace-nowrap">
              {renderJobField("Job Role", jobOpp.jobType.title)}
              {renderJobField("Customer Name", getCustomerName(jobOpp))}
              {renderJobField(
                "Duration:",
                `${getTimeCommitmentLabel(jobOpp)} | ${getJobLength(jobOpp)}`
              )}
              {renderJobField("Start Date", getStartDate(jobOpp))}
              {renderJobField("Timezone", jobOpp.timezone?.label || "-")}
              {renderJobField(
                "Timezone Overlap",
                `${
                  jobOpp.timeOverlap > 1 && jobOpp.timeOverlap < 8
                    ? `${jobOpp.timeOverlap} hours`
                    : jobOpp.timeOverlap >= 8
                    ? "All hours"
                    : "No Restriction"
                }`
              )}
            </div>
            <div className="flex flex-col gap-y-3">
              <p className="uppercase text-base text-gray-400 font-bold">
                Required Skills
              </p>
              <div className="flex flex-wrap gap-1">
                {jobOpp.skills?.map((skill) => {
                  const isActive = definedSkills.includes(skill.name);

                  return (
                    <Chip
                      className={classNames(
                        "!h-[28px] !py-4 !border !font-medium",
                        {
                          "!bg-sky-600 !text-white": isActive,
                        },
                        { "!text-[#0EA5E9] !border-[#0EA5E9]": !isActive }
                      )}
                      key={skill.name}
                      value={skill.name}
                      isActive={isActive}
                      onClose={() => handleDeleteDefinedSkill(skill.name)}
                      {...(!isActive && {
                        onClick: () => handleAddDefinedSkill(skill.name),
                      })}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JobDetailsPageHeader;
