import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "components/Modals";
import { useContext } from "react";
import { MatchContext, NotesContext } from "context/providers";
import RichTextEditor from "components/base/RichTextEditor";
import classNames from "classnames";
import ActionButton from "components/UserCardList/components/UserCardResult/molecules/ActionButton";
import { useEffect } from "react";
import Checkbox from "components/base/Checkbox";

const CalibrationModal = ({
  user,
  onClose,
  applicationId,
  jobOpportunityId,
  userId,
  isUpdate,
  status,
  isCalibration,
}) => {
  const [commentNote, setCommentNote] = useState({
    content: "",
    isPublic: true,
    errorMessage: "",
  });
  const [calibrationRate, setCalibrationRate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMatchActionHandled, setIsMatchActionHandled] = useState(false);
  const { createMatch, updateMatch, updateMatchFieldLocally, matches } =
    useContext(MatchContext);
  const { add: addNote, match: initMatchNotes } = useContext(NotesContext);

  const handleOnChange = (value) => {
    setCommentNote((prev) => ({
      ...prev,
      content: value,
      errorMessage: "",
    }));
  };

  const createMatchNote = async (note) => {
    const { content, isPublic } = note;

    await addNote({
      applicationId,
      jobOpportunityId,
      content,
      isPublic,
      noteType: "CALIBRATION",
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let errorMessage = "";

    try {
      const params = {
        isCalibration,
      };

      if (status) {
        params.status = status;
      }

      if (calibrationRate) {
        params.calibrationRate = { value: calibrationRate, currency: "USD" };
      }

      if (!isUpdate) {
        await createMatch(applicationId, jobOpportunityId, userId, params);
      } else {
        await updateMatch(applicationId, jobOpportunityId, params);
      }
      if (commentNote.content) {
        setIsMatchActionHandled(true);
      } else {
        onClose();
      }
    } catch (error) {
      console.log("matchAction error: ", error);
      errorMessage = "Something went wrong";
      setIsLoading(false);
    }
    setCommentNote((prev) => ({
      ...prev,
      errorMessage,
    }));
  };

  useEffect(() => {
    if (
      isMatchActionHandled &&
      matches?.find((m) => m.applicationId === applicationId)
    ) {
      setIsMatchActionHandled(false);
      (async () => {
        await createMatchNote(commentNote);
        await initMatchNotes({ id: applicationId }, { id: jobOpportunityId });
        const matchNotesCount = user?.notesCount || 0;
        updateMatchFieldLocally(
          applicationId,
          "notesCount",
          +matchNotesCount + 1
        );

        onClose();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  if (!status && isCalibration === undefined) {
    return;
  }

  const handleChange = (value, valueKey) => {
    setCommentNote((prev) => ({
      ...prev,
      [valueKey]: value,
      errorMessage: "",
    }));
  };

  return (
    <Modal
      isVisible={applicationId && jobOpportunityId}
      title="Calibration:"
      onClose={onClose}
      className="max-h-[90vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
    >
      <>
        <div className="flex gap-2 mb-2">
          <p className="text-xl font-bold">Rate:</p>
          <input
            id="rate"
            type="number"
            className="border border-grey rounded-md w-32 px-2 max-w-full"
            value={calibrationRate}
            min="0"
            onChange={(e) => setCalibrationRate(e.target.value)}
          />
        </div>
        <p className="text-2xl font-bold">Include comment:</p>
        <p className="font-normal text-base mb-4">
          Remember: These notes are associated with the match record and will be
          marked as calibration comment.
        </p>
        <div className="flex justify-center mt-2">
          <RichTextEditor
            value={commentNote.content}
            valueKey="content"
            onChange={(value) => handleOnChange(value)}
          />
        </div>
        <div className="flex justify-end mt-6">
          <div className="flex flex-col">
            {commentNote.errorMessage && (
              <span className="text-red-600 text-sm font-bold mt-1">
                {commentNote.errorMessage}
              </span>
            )}
          </div>

          <div className="flex justify-between w-full">
            <Checkbox
              label="Customer Visible"
              checked={commentNote.isPublic}
              helpText="Make this note visible to the customer. Customer Notes will have a yellow background on the timeline"
              onChange={(checked) => handleChange(checked, "isPublic")}
            />

            <ActionButton
              className={classNames("text-gray-600 border-b-2")}
              isLoading={isLoading}
              action={() => handleSubmit()}
              type="calibration"
              iconType="calibration"
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

CalibrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CalibrationModal;
