import { Amplify, API } from "aws-amplify";
import classNames from "classnames";
import SvgIcon from "components/base/SvgIcon";
import { useEffect, useState } from "react";

const CHECKPOINT_API_NAME = "CheckpointApi";

if (process.env.REACT_APP_CHECKPOINT_API_ENDPOINT) {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: CHECKPOINT_API_NAME,
          endpoint: process.env.REACT_APP_CHECKPOINT_API_ENDPOINT,
        },
      ],
    },
  });
}

const primaryButtonClass =
  "inline-flex justify-center items-center gap-x-1.5 rounded-md px-2.5 py-1.5 font-semibold text-white bg-sky-300 hover:bg-sky-600";
const secondaryButtonClass =
  "inline-flex justify-center items-center gap-x-1.5 rounded-md px-2.5 py-1.5 font-semibold text-sky-400 bg-sky-50 hover:bg-sky-100 border border-sky-400";

export default function DemoManager({ jobOppId }) {
  const [loading, setLoading] = useState(true);
  const [backupExists, setBackupExists] = useState(false);
  const [isBacking, setIsBacking] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await API.get(CHECKPOINT_API_NAME, `/capture/${jobOppId}`);

      if (data.type === "SUCCESS") {
        setLoading(false);
      }

      setBackupExists(data.checkpointExists);
    })();
  }, [jobOppId]);

  const backup = async () => {
    setIsBacking(true);
    const data = await API.post(CHECKPOINT_API_NAME, "/capture", {
      body: { jobOpportunityId: jobOppId },
    });

    if (data.type === "SUCCESS") {
      setBackupExists(true);
    }

    setIsBacking(false);
  };

  const restore = async () => {
    setIsRestoring(true);
    await API.post(CHECKPOINT_API_NAME, `/restore/${jobOppId}`, {
      body: {},
    });

    setIsRestoring(false);
  };

  return (
    <>
      <hr className="w-full border-t-2 border-gray-300 my-4" />
      <p className="text-2xl font-bold mb-4 flex justify-start items-center gap-4">
        Demo Manager
        <span
          className={classNames({
            "inline-flex items-center justify-center gap-x-1.5 rounded-full text-xs px-2 py-1 border": true,
            "border-green-400 text-green-500": !loading,
            "border-yellow-400 text-yellow-500": loading,
          })}
        >
          <svg
            className={classNames({
              "h-1.5 w-1.5": true,
              "fill-green-500": !loading,
              "fill-yellow-500": loading,
            })}
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx="3" cy="3" r="3"></circle>
          </svg>{" "}
          {loading
            ? "Getting backup status..."
            : backupExists
            ? "Backed up"
            : "No backup found"}
        </span>
      </p>
      <div className="flex gap-4 text-sm text-slate-400 text-justify">
        <div className="flex flex-col gap-4 w-full">
          <button
            className={classNames({
              [primaryButtonClass]: !backupExists,
              [secondaryButtonClass]: backupExists,
            })}
            onClick={backup}
            disabled={isBacking || loading}
          >
            <SvgIcon type="backup" />
            {isBacking ? "Backing data..." : "Backup data"}
          </button>
          <p>
            Create a backup of this job and its dependants - namely, any matches
            associated with it and notes on the job opportunity &amp; matches.
            <br />
            <strong>NOTE:</strong> If there are any existing backups, it will be
            erased before a new backup is created
          </p>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <button
            className={classNames({
              [primaryButtonClass]: backupExists,
              [secondaryButtonClass]: !backupExists,
            })}
            onClick={restore}
            disabled={isRestoring || loading || !backupExists}
          >
            <SvgIcon type="restore" />
            {isRestoring ? "Restoring data..." : "Restore data"}
          </button>
          <p className="mt-[-2px]">
            Restore the backup of this jop and its dependants. You will have to
            refresh this page to view the latest data. Please be sure to save
            your work before proceeding.
          </p>
        </div>
      </div>
    </>
  );
}
