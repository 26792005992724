import React, { useContext, useMemo } from "react";
import { Amplify } from "aws-amplify";
import { ThemeProvider, Authenticator } from "@aws-amplify/ui-react";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import { ToastContainer } from "react-toastify";

import RootProvider, { AuthContext, JobsContext } from "context/providers";
import awsConfig from "./aws-exports";

import { SearchContainer, ModalContainer } from "containers";
import UserSearch from "pages/UserSearch";
import JobOpportunities from "pages/JobOppList";
import JobOppDetails from "pages/JobOppDetails";

import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";

import AppLogo from "components/Logo";
import Header from "components/Header";
import JobAssistant from "components/JobAssistant";

function urlMatchesLocation(url) {
  if (window.location.href.includes(url)) {
    return url;
  }

  return null;
}

awsConfig.oauth.domain =
  process.env.REACT_APP_COGNITO_HOSTED_DOMAIN || awsConfig.oauth.domain;

const aAcceptabelSignInURLs = awsConfig.oauth.redirectSignIn.split(",");
const aAcceptableSignOutURLs = awsConfig.oauth.redirectSignOut.split(",");

awsConfig.oauth.redirectSignIn = aAcceptabelSignInURLs.find(urlMatchesLocation);
awsConfig.oauth.redirectSignOut =
  aAcceptableSignOutURLs.find(urlMatchesLocation);

Amplify.configure(awsConfig);

const components = {
  SignIn: {
    Header() {
      return (
        <div className="flex justify-center pt-8">
          <AppLogo />
        </div>
      );
    },
  },
};

const PrivateRoute = ({
  component: Component,
  children,
  signOut,
  isAssistantRoute,
  ...rest
}) => {
  const { isAdmin, isManager, user, isPremiumCustomers } =
    useContext(AuthContext);
  const { jobOpp } = useContext(JobsContext);

  const loading = useMemo(
    () => isAdmin === undefined && isManager === undefined,
    [isAdmin, isManager]
  );

  if (loading) {
    return (
      <>
        <Header signOut={signOut} user={user} jobOpp={jobOpp} />
        <div className="flex justify-center mt-8">
          <span className="loader"></span>
        </div>
      </>
    );
  }

  if (isAssistantRoute && !isPremiumCustomers === true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const canAccess = isAdmin || isManager;

  return (
    <Route
      {...rest}
      render={(props) =>
        canAccess ? (
          <>
            <Header
              {...props}
              signOut={signOut}
              user={user}
              jobOpp={jobOpp}
              isAuthorized={canAccess}
            />
            {<Component user={user} />}
          </>
        ) : (
          <>
            <Header
              {...props}
              signOut={signOut}
              user={user}
              jobOpp={jobOpp}
              isAuthorized={canAccess}
            />
            <div className="text-center pt-8">
              <p className="text-lg font-semibold text-gray-500">
                @{user?.username || ""}{" "}
                <span className="text-red-400">
                  You are not authorized to use this service. Please contact
                  administrator if you think this is a mistake.
                </span>
              </p>
            </div>
          </>
        )
      }
    />
  );
};

export default function App() {
  return (
    <ThemeProvider>
      <Authenticator
        socialProviders={["google"]}
        hideSignUp={true}
        components={components}
      >
        {({ signOut, user }) => (
          <>
            <RootProvider>
              <SearchContainer>
                <HashRouter>
                  <CompatRouter>
                    <Switch>
                      <CompatRoute
                        path="/"
                        exact
                        render={() => (
                          <PrivateRoute
                            signOut={signOut}
                            component={JobOpportunities}
                          />
                        )}
                        user={user}
                      />
                      <CompatRoute
                        path="/assistant"
                        exact
                        render={() => (
                          <PrivateRoute
                            signOut={signOut}
                            component={JobAssistant}
                            isAssistantRoute
                          />
                        )}
                        user={user}
                      />
                      <CompatRoute
                        path="/usermanager"
                        exact
                        render={() => (
                          <PrivateRoute
                            signOut={signOut}
                            component={UserSearch}
                          />
                        )}
                        user={user}
                      />
                      <CompatRoute
                        path="/jobOpps/:id/details"
                        exact
                        render={() => (
                          <PrivateRoute
                            signOut={signOut}
                            component={JobOppDetails}
                          />
                        )}
                        user={user}
                      />
                    </Switch>
                  </CompatRouter>
                </HashRouter>
              </SearchContainer>

              <ModalContainer />
            </RootProvider>

            <ToastContainer autoClose={3000} theme="dark" />
          </>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}
