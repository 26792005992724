import React from "react";
import PropTypes from "prop-types";

import { CUSTOM_LINK_TYPES } from "lookup";

// base
import { ReactComponent as ArrowDown } from "images/arrow-down.svg";
import { ReactComponent as Assessment } from "images/assessment-icon.svg";
import { ReactComponent as Calibration } from "images/calibration.svg";
import { ReactComponent as Cancel } from "images/cancel-icon.svg";
import { ReactComponent as Delete } from "images/delete.svg";
import { ReactComponent as Cross } from "images/cross.svg";
import { ReactComponent as Info } from "images/info-circle.svg";
import { ReactComponent as Question } from "images/question.svg";
import { ReactComponent as Refresh } from "images/refresh.svg";
import { ReactComponent as Phone } from "images/phone.svg";
import { ReactComponent as Email } from "images/mail-send-line.svg";
import { ReactComponent as Heart } from "images/heart.svg";
import { ReactComponent as Focus } from "images/focus.svg";
import { ReactComponent as Calculator } from "images/calculator.svg";
import { ReactComponent as Calendar } from "images/calendar.svg";
import { ReactComponent as Skip } from "images/skip.svg";
import { ReactComponent as Users } from "images/users.svg";
import { ReactComponent as Xmark } from "images/xmark.svg";
import { ReactComponent as Logo } from "images/logo.svg";
import { ReactComponent as OpenBook } from "images/open-book.svg";
import { ReactComponent as DownloadFile } from "images/download-file.svg";
import { ReactComponent as UploadFile } from "images/upload_icon.svg";
import { ReactComponent as CheckBadge } from "images/check-badge.svg";
import { ReactComponent as Warning } from "images/warning.svg";
import { ReactComponent as ProfessionalCommunityIcon } from "images/professionalCommunityIcon.svg";
import { ReactComponent as MagicWand } from "images/magic-wand-icon.svg";
import { ReactComponent as Upload } from "images/upload_icon.svg";
import { ReactComponent as Ellipsis } from "images/ellipsis-icon.svg";
import { ReactComponent as BackupIcon } from "images/backup.svg";
import { ReactComponent as RestoreIcon } from "images/restore.svg";
import { ReactComponent as NewJobAssistantConversationIcon } from "images/new-assistant-conversation.svg";

// links
import { ReactComponent as ExternalLink } from "images/external-link.svg";
import { ReactComponent as GeneralLink } from "images/general-link.svg";
import { ReactComponent as OtherLink } from "images/other-link.svg";
import { ReactComponent as ResultLink } from "images/result-link.svg";
import { ReactComponent as ResumeLink } from "images/resume-link.svg";

// social
import { ReactComponent as Facebook } from "images/facebook-box-fill.svg";
import { ReactComponent as Hash } from "images/hashtag.svg";
import { ReactComponent as Instagram } from "images/instagram-fill.svg";
import { ReactComponent as Linkedin } from "images/linkedin-box-fill.svg";
import { ReactComponent as Website } from "images/profile-fill.svg";
import { ReactComponent as StackOverflow } from "images/stack-overflow-fill.svg";
import { ReactComponent as Twitter } from "images/twitter-fill.svg";
import { ReactComponent as Github } from "images/github-fill.svg";
import { ReactComponent as Whatsapp } from "images/whatsapp.svg";

// note
import { ReactComponent as Note } from "images/note.svg";
import { ReactComponent as NoteCustomer } from "images/note-customer.svg";
import { ReactComponent as NotePrivate } from "images/note-private.svg";
import { ReactComponent as NotePublic } from "images/note-public.svg";
import { ReactComponent as Horn } from "images/horn.svg";
import { ReactComponent as EyeClosed } from "images/eye-closed.svg";
import { ReactComponent as EyeVisible } from "images/eye-visible.svg";
import { ReactComponent as JobFeedback } from "images/job-feedback-icon.svg";

const BASE = {
  arrowDown: ArrowDown,
  assessment: Assessment,
  calibration: Calibration,
  cancel: Cancel,
  delete: Delete,
  cross: Cross,
  info: Info,
  question: Question,
  refresh: Refresh,
  phone: Phone,
  email: Email,
  skip: Skip,
  heart: Heart,
  focus: Focus,
  users: Users,
  calculator: Calculator,
  calendar: Calendar,
  xmark: Xmark,
  logo: Logo,
  openBook: OpenBook,
  downloadFile: DownloadFile,
  uploadFile: UploadFile,
  checkBadge: CheckBadge,
  warning: Warning,
  community: ProfessionalCommunityIcon,
  magicWand: MagicWand,
  upload: Upload,
  ellipsis: Ellipsis,
  backup: BackupIcon,
  restore: RestoreIcon,
  newAssistant: NewJobAssistantConversationIcon,
};

const LINK = {
  [CUSTOM_LINK_TYPES.EXTERNALLINK]: ExternalLink,
  [CUSTOM_LINK_TYPES.GENERAL]: GeneralLink,
  [CUSTOM_LINK_TYPES.OTHER]: OtherLink,
  [CUSTOM_LINK_TYPES.RESULT]: ResultLink,
  [CUSTOM_LINK_TYPES.RESUME]: ResumeLink,
};

const SOCIAL = {
  facebook: Facebook,
  hash: Hash,
  instagram: Instagram,
  linkedin: Linkedin,
  website: Website,
  stackOverflow: StackOverflow,
  twitter: Twitter,
  github: Github,
  whatsapp: Whatsapp,
};

const NOTE = {
  note: Note,
  noteCustomer: NoteCustomer,
  notePrivate: NotePrivate,
  notePublic: NotePublic,
  horn: Horn,
  eyeClosed: EyeClosed,
  eyeVisible: EyeVisible,
  jobFeedback: JobFeedback,
};

const SVG_BY_TYPE = {
  ...BASE,
  ...LINK,
  ...SOCIAL,
  ...NOTE,
};

function SvgIcon({ type, className, ...rest }) {
  const CustomSvg = SVG_BY_TYPE[type] || "svg";

  return <CustomSvg className={className} {...rest} />;
}

SvgIcon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  type: "",
  className: "",
};

export default SvgIcon;
