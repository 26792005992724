import Button from "components/Button";
import Modal from "components/Modals";
import { JOB_OPPORTUNITY_TIME_COMMITMENT } from "lookup";

import styles from "components/index.module.css";
import classNames from "classnames";

const DetailsModal = ({
  onClose,
  jobOpp,
  similarJob,
  actionButtonTitle,
  actionButtonCallBack,
  actionButtonIsReadOnly,
}) => {
  const Cell = ({ title, body, children }) => (
    <td className="w-1/2 h-full">
      <section className="bg-white border h-full p-8 !pt-2">
        {title && <h4 className="font-nexa font-bold text-xl mb-4">{title}</h4>}
        {body && <p className="font-nexa-light text-sm mb-2">{body}</p>}
        {children}
      </section>
    </td>
  );

  const JobDetails = ({ job }) => (
    <>
      <div className="text-blue-400 font-nexa text-xl mb-4">Job Details</div>
      <div className="text-blue-400 font-rubik mb-4 pb-2 font-bold italic leading-snug">
        <p>
          {job.timeCommitment === JOB_OPPORTUNITY_TIME_COMMITMENT.FULLTIME
            ? "Full-time"
            : "Part-time"}{" "}
          |{" "}
          {job?.jobLengthInWeeks ? (
            <span>
              {job.jobLengthInWeeks}{" "}
              {job.jobLengthInWeeks > 1 ? "weeks" : "week"}
            </span>
          ) : (
            <span>
              {job?.jobLength} {job?.jobLength > 1 ? "months" : "month"}
            </span>
          )}
        </p>
        <p>Hourly Rate: up to ${job.maxRate?.value}</p>
        <p>Timezone: {job.timezone?.label}</p>
        <p>
          Timezone Overlap:{" "}
          {job.timeOverlap > 1 && job.timeOverlap < 8
            ? `${job.timeOverlap} hours`
            : job.timeOverlap >= 8
            ? "All hours"
            : "No Restriction"}
        </p>
        <p>Job Role: {job.jobType.title}</p>
      </div>
    </>
  );
  return (
    <Modal
      onClose={onClose}
      isVisible
      className="!overflow-hidden !max-h-[75vh] !w-[70%]"
    >
      <div className="flex justify-center pt-2 pb-2">
        <Button
          isReadOnly={actionButtonIsReadOnly}
          onClick={() => {
            onClose();
            actionButtonCallBack();
          }}
          alt="Go to Job"
          className="hover:shadow-lg"
        >
          {actionButtonTitle}
        </Button>
      </div>
      <div className="flex">
        <h4 className="w-1/2 font-nexa font-bold text-xl mb-2 p-2">
          {jobOpp.title}
        </h4>
        <h4 className="w-1/2 font-nexa font-bold text-xl mb-2 p-2">
          {similarJob.title}
        </h4>
      </div>
      <div
        className={classNames(
          "flex justify-center p-2 overflow-auto max-h-[45vh]",
          styles.customScrollBar
        )}
      >
        <table
          className="border-spacing-0 h-px"
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody className="h-full">
            <tr>
              <Cell>
                <JobDetails job={jobOpp} />
              </Cell>
              <Cell>
                <JobDetails job={similarJob} />
              </Cell>
            </tr>

            <tr className="h-full">
              <Cell title="Overview" body={jobOpp.overview} />
              <Cell title="Overview" body={similarJob.overview} />
            </tr>
            <tr className="h-full">
              <Cell title="Responsibilities" body={jobOpp.responsibilities} />
              <Cell
                title="Responsibilities"
                body={similarJob.responsibilities}
              />
            </tr>
            <tr className="h-full">
              <Cell title="Requirements" body={jobOpp.requirements} />
              <Cell title="Requirements" body={similarJob.requirements} />
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default DetailsModal;
