import PropTypes from "prop-types";

import { ReactComponent as Bars } from "images/bars.svg";
import placeholderProfileImg from "images/placeholderProfile.png";
import HeadShot from "../../HeadShot";

const HeaderLoginButton = ({
  onClick,
  headshotKey,
  displayName,
  role,
  showMenu,
}) => {
  return (
    <>
      <div className="flex items-center">
        <div className="mr-4">
          <div className={"flex items-center justify-end"}>
            <span className="font-nexa-bold inline-block tracking-wider text-left font-bold not-italic text-white">
              {displayName}
            </span>
          </div>
          <div className="text-gray-400 text-xs text-right">
            {role ? role : ""}
          </div>
        </div>
        <div className="w-10 rounded overflow-hidden mr-7">
          {headshotKey ? (
            <HeadShot headshotKey={headshotKey} />
          ) : (
            <img src={placeholderProfileImg} alt="placeholder" />
          )}
        </div>
        {showMenu && (
          <div onClick={onClick} className="cursor-pointer">
            <Bars />
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderLoginButton;

HeaderLoginButton.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
};
