import algoliasearch from "algoliasearch/lite";
import { cloneDeep } from "lodash";

import api from "apiSingleton";
import { prepareQuery } from "utils/helpers/search";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const searchIndex = searchClient.initIndex(process.env.REACT_APP_INDEX_NAME);

export async function initSearchResults(state) {
  try {
    const filters = prepareQuery(state);

    const data = await searchIndex.search(state.searchState?.query || "", {
      hitsPerPage: state.searchState.hitsPerPage || 10,
      page: state.searchState.page - 1 || 0,
      ...filters,
    });

    return data;
  } catch (error) {
    console.log("initSearchResults error: ", error);
  }
}

export function updateHitFieldByKey(hits, hitId, fieldKey, value) {
  const clonedHits = cloneDeep(hits);
  const hitIndex = clonedHits.findIndex((h) => h.id === hitId);
  const hit = cloneDeep(hits[hitIndex]);

  hit[fieldKey] = value;
  clonedHits[hitIndex] = hit;

  return clonedHits;
}

export function replaceHit(hit, hits) {
  const clonedHits = cloneDeep(hits);
  const existingHitIndex = clonedHits.findIndex((el) => el.id === hit.id);

  clonedHits[existingHitIndex] = { ...clonedHits[existingHitIndex], ...hit };

  return clonedHits;
}

export async function updateHit(args) {
  try {
    const updatedMatch = await api.user.update({
      ...args,
    });

    return updatedMatch.data?.updateUser || {};
  } catch (error) {
    console.log("updateHit error: ", error);
  }
}
