import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import PropTypes from "prop-types";

import {
  AuthContext,
  JobsContext,
  MatchContext,
  ModalContext,
  NotesContext,
  SearchContext,
} from "context/providers";

import Modal from "components/Modals";
import Notes from "components/Notes";

const NoteModal = ({
  user,
  modalTarget,
  title,
  subTitle,
  editorLabel,
  editorSubLabel,
  onClose,
  isCalibration,
}) => {
  const { user: loggedInUser } = useContext(AuthContext);
  const { activeModal } = useContext(ModalContext);
  const { updateHitField } = useContext(SearchContext);
  const { updateMatchFieldLocally } = useContext(MatchContext);
  const { jobOpp } = useContext(JobsContext);
  const {
    userNotes,
    matchedNotes,
    user: initUserNotes,
    match: initMatchNotes,
    add: addNote,
    remove: removeNote,
  } = useContext(NotesContext);

  const [noteData, setNoteData] = useState({ isLoading: false });

  const { shouldInitMatchNotes, shouldInitUserNotes, match, hit } =
    useMemo(() => {
      const associatedApplication =
        modalTarget === "Match"
          ? { id: user?.applicationId }
          : (user?.applications || []).find(
              (app) => app.jobTypeId === jobOpp?.jobType?.id
            );

      const shouldInitMatchNotes =
        !matchedNotes[associatedApplication?.id] && associatedApplication;
      const shouldInitUserNotes =
        !userNotes[user.application?.user?.id || user.id];

      const match = {
        ...user,
        id: associatedApplication?.id,
        applicationId: associatedApplication?.id,
      };

      const hit =
        modalTarget === "User"
          ? user
          : user.application?.user || { id: activeModal.userId };

      return {
        shouldInitMatchNotes,
        shouldInitUserNotes,
        match,
        hit,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const initNotes = useCallback(
    async (initMatch, initUser) => {
      try {
        if (initMatch) {
          await initMatchNotes(match, jobOpp);
        }

        if (initUser) {
          await initUserNotes(hit);
        }
      } catch (error) {
        console.error("initUserNotes error: ", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const createUserNote = async (note) => {
    try {
      const { content, isPublic } = note;
      const { id: userId, notesCount } = hit;

      await addNote({
        userId,
        content,
        isPublic,
      });

      await initNotes(false, true);
      updateHitField(userId, "notesCount", (+notesCount || 0) + 1);

      return { isSuccess: true };
    } catch (error) {
      console.error("createNote error: ", error);

      return { isSuccess: false };
    }
  };

  const deleteUserNote = async (id) => {
    const { id: userId, notesCount } = hit;
    const newNotesCount = +notesCount > 0 ? +notesCount - 1 : +notesCount;

    await removeNote({ id });
    await initNotes(false, true);
    updateHitField(userId, "notesCount", newNotesCount);
  };

  const createMatchNote = async (note) => {
    try {
      const { content, isPublic } = note;
      const { id: jobOpportunityId } = jobOpp;
      const { applicationId, notesCount } = match;

      const params = {
        applicationId,
        jobOpportunityId,
        content,
        isPublic,
      };

      if (isCalibration) {
        params.noteType = "CALIBRATION";
      }

      await addNote(params);

      await initNotes(true, false);

      updateMatchFieldLocally(applicationId, "notesCount", +notesCount + 1);

      return { isSuccess: true };
    } catch (error) {
      console.error("createNote error: ", error);

      return { isSuccess: false };
    }
  };

  const deleteMatchNote = async (id) => {
    const { applicationId, notesCount } = match;
    const newNotesCount = +notesCount > 0 ? +notesCount - 1 : +notesCount;

    await removeNote({ id });
    await initNotes(true, false);

    updateMatchFieldLocally(applicationId, "notesCount", newNotesCount);
  };

  const remove = (id, { target }) => {
    switch (target) {
      case "Match": {
        deleteMatchNote(id);
        break;
      }

      default: {
        deleteUserNote(id);
        break;
      }
    }
  };

  useEffect(() => {
    if (user) {
      (async () => {
        if (shouldInitMatchNotes || shouldInitUserNotes) {
          setNoteData((prev) => ({ ...prev, isLoading: true }));
          await initNotes(
            shouldInitMatchNotes,
            shouldInitUserNotes && !isCalibration
          );
          setNoteData((prev) => ({ ...prev, isLoading: false }));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isCalibration) {
      (async () => {
        setNoteData((prev) => ({ ...prev, isLoading: true }));
        await initNotes(true, false);
        setNoteData((prev) => ({ ...prev, isLoading: false }));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotesToRender = () => {
    const notes = {
      matchNotes: {
        label: isCalibration ? "Calibration Notes" : "Match Notes",
        items: isCalibration
          ? matchedNotes[match.id]?.filter(
              (n) => n.noteType === "CALIBRATION"
            ) || []
          : matchedNotes[match.id] || [],
        target: "Match",
      },
    };
    if (!isCalibration) {
      notes.userNotes = {
        label: "User Notes",
        items: userNotes[hit.id] || [],
        target: "User",
      };
    }
    return notes;
  };

  return (
    <Modal
      isVisible={user}
      onClose={onClose}
      {...activeModal}
      title={title}
      subTitle={subTitle}
    >
      <Notes
        notes={getNotesToRender()}
        loggedInUser={loggedInUser}
        notesTarget={modalTarget}
        editorLabel={editorLabel}
        editorSubLabel={editorSubLabel}
        resetState={!user}
        isLoading={noteData.isLoading}
        onSubmit={modalTarget === "User" ? createUserNote : createMatchNote}
        onDelete={remove}
        isAllowedPublicNote={activeModal.isAllowedPublicNote}
        isCalibration={isCalibration}
      />
    </Modal>
  );
};

NoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NoteModal;
