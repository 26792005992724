import React, { useContext, useEffect } from "react";

import { JobsContext } from "context/providers";

import Modal from "components/Modal";
import Tabs from "components/base/Tabs";
import JobOppDetailsCard from "components/JobOppDetailsCard";
import SimilarJobs from "components/SimilarJobs";

const JobModal = ({ user, initialTabIndex, onClose }) => {
  const { jobOpp, loadingJobOpp, resetSimilarJobOppsFilters } =
    useContext(JobsContext);

  useEffect(() => {
    if (!loadingJobOpp && jobOpp) {
      resetSimilarJobOppsFilters();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingJobOpp]);

  return (
    <div>
      <Modal onClose={onClose} isOpen>
        <Tabs
          initialIndex={initialTabIndex}
          headerClassName="!justify-start gap-x-2 px-2 pt-1"
          items={[
            {
              title: "Overview",
              type: "custom",
              renderCustomContent: (activeTabName) => (
                <JobOppDetailsCard key={activeTabName} user={user} />
              ),
            },
            {
              title: "AI Assistant",
              type: "custom",
              renderCustomContent: (activeTabName) => (
                <SimilarJobs key={activeTabName} />
              ),
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default JobModal;
