import React from "react";
import classNames from "classnames";

const Button = ({
  type,
  children,
  outlined = false,
  white = false,
  isReadOnly,
  bgColor,
  text = "text-sky-500",
  className = "",
  ...props
}) => {
  return (
    <button
      {...props}
      type={type || `button`}
      className={classNames(
        `px-4 py-2 font-bold uppercase min-w-max`,
        outlined
          ? `border-2 border-black`
          : `${
              white ? `bg-white` : bgColor ? bgColor : `bg-sky-100`
            } shadow-sm`,
        className,
        text,
        isReadOnly ? "opacity-50" : "",
        { "hover:text-sky-600 hover:shadow-lg": !isReadOnly }
      )}
      style={{ borderRadius: `50px` }}
      disabled={isReadOnly}
    >
      {children}
    </button>
  );
};

export default Button;
