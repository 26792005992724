import { cloneDeep, sortBy } from "lodash";
import dayjs from "dayjs";

import { JOB_OPPORTUNITY_STATUSES } from "lookup";
import api from "apiSingleton";

const ITEM_LIMIT = 500;

function sortByStatus(jobs) {
  const jobStatusesOrder = [
    JOB_OPPORTUNITY_STATUSES.ACTIVE,
    JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL,
    JOB_OPPORTUNITY_STATUSES.FULFILLED,
    JOB_OPPORTUNITY_STATUSES.CANCELLED,
    JOB_OPPORTUNITY_STATUSES.DRAFT,
  ];

  return sortBy(jobs, [
    function (job) {
      return jobStatusesOrder.indexOf(job.status);
    },
    jobs.sort((a, b) =>
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
    ),
  ]);
}

const mapMatches = (matches) => {
  if (matches?.items) {
    return matches.items.map((el) => ({
      ...el,
      isMatching: false,
      isShortlisting: false,
      isDeleting: false,
    }));
  }

  return [];
};

const loadJobOpportunities = async (nextToken = null) => {
  const response = await api.jobs.list({
    filter: {
      status: {
        in: ["ACTIVE", "PENDINGAPPROVAL", "FULFILLED", "CANCELLED", "DRAFT"],
      },
    },
    limit: ITEM_LIMIT,
    nextToken,
  });

  const jobs = response.data.listJobOpportunitys;

  if (jobs.nextToken) {
    await new Promise((resolve) => setTimeout(resolve, 250));
    const jobsNextPortions = await loadJobOpportunities(jobs.nextToken);

    return [...jobs.items, ...jobsNextPortions];
  }

  return jobs.items;
};

export async function initializeJobs() {
  const jobs = await loadJobOpportunities();

  const jobsByStatus = sortByStatus(jobs);

  const companyNames = {};
  const jobTypes = {};
  const jobSkills = {};

  const jobOpps = [
    ...jobsByStatus?.map((el) => ({
      ...el,
      matches: { items: mapMatches(el.matches) },
    })),
  ];

  for (let job of jobOpps) {
    const organizationName = job?.organization;
    const customerName =
      job?.customer?.companyDetails?.name || job?.customer?.company;
    const jobType = job?.jobType?.title;

    const skills = job?.skills;

    if (organizationName && !companyNames[organizationName]) {
      companyNames[organizationName] = organizationName;
    } else if (customerName && !companyNames[customerName]) {
      companyNames[customerName] = customerName;
    }

    if (jobType && !jobTypes[jobType]) {
      jobTypes[jobType] = true;
    }

    if (skills.length > 0) {
      skills.forEach((skill) => {
        if (!jobSkills[skill]) {
          jobSkills[skill.name] = skill.name;
        }
      });
    }
  }

  return { jobOpps, companyNames, jobTypes, jobSkills };
}

export async function initializeJob(id) {
  const job = await api.jobs.get({ id });

  return job.data.getJobOpportunity;
}

export async function initializeJobCalendarEvents(id) {
  try {
    const { data } = await api.jobs.getEvents({ id });
    return data.getJobOpportunityEvents;
  } catch (error) {
    console.log("Something went wrong when fetching job calendar events");
    return [];
  }
}

export function updateJobLocally(job, value, valueKey) {
  const clonedJob = cloneDeep(job);
  const updatedJob = { ...clonedJob, [valueKey]: value };

  return updatedJob;
}

export async function updateJob(id, payload) {
  const job = await api.jobs.update({
    input: {
      id,
      ...payload,
    },
  });

  return job;
}
