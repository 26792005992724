import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import api from "apiSingleton";
import { JobsContext, MatchContext } from "context/providers";

import SvgIcon from "components/base/SvgIcon";

const MatchStatusHistoryInfo = ({ hit, collectionKey, configStatusFields }) => {
  const { updateMatch1LevelFieldLocally } = useContext(MatchContext);
  const { jobOpp } = useContext(JobsContext);

  const [isLoading, setIsLoading] = useState(true);
  const matchHistory = useRef([]);

  const statusOfInterest = useMemo(
    () => Object.keys(configStatusFields || {}),
    [configStatusFields]
  );

  const getMatchHistory = async (applicationId, jobOppId) => {
    const { data } = await api.common.getFieldHistory({
      key: { beginsWith: `${applicationId}_${jobOppId}` },
      entity: "Match",
    });
    return data.getFieldHistory?.items;
  };

  useEffect(() => {
    (async () => {
      if (jobOpp?.id && collectionKey === "match") {
        if (hit.match?.matchHistory === undefined) {
          matchHistory.current = await getMatchHistory(
            hit.match?.applicationId,
            jobOpp.id
          );

          updateMatch1LevelFieldLocally(
            hit.match?.applicationId,
            "matchHistory",
            matchHistory.current
          );
        } else {
          matchHistory.current = hit.match?.matchHistory;
        }
      }
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (collectionKey !== "match" || isLoading) {
    return null;
  }

  return (
    <div className="flex gap-x-3">
      {statusOfInterest.map((status) => {
        const { title, iconType } = configStatusFields[status];

        if (hit.match?.status === status) {
          return null;
        }

        if (matchHistory.current.some(({ newValue }) => newValue === status)) {
          return (
            <div key={status} title={title} className="mt-2">
              <div className="w-[25px] h-[25px]">
                <SvgIcon type={iconType} className="w-full" />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

MatchStatusHistoryInfo.propTypes = {
  hit: PropTypes.object,
  collectionKey: PropTypes.string,
  configStatusFields: PropTypes.object,
};

MatchStatusHistoryInfo.defaultProps = {
  hit: {},
  collectionKey: "",
  configStatusFields: {},
};

export default MatchStatusHistoryInfo;
