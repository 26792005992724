import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { nanoid } from "nanoid";

import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";

import Checkbox from "components/base/Checkbox";

const Root = styled("div")`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th,
  tr {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;

const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

const TableWithPagination = ({
  header,
  rows,
  filters,
  currentPage,
  rowsPerPage,
  searchField,
  searchPlaceHolder,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [page, setPage] = useState(currentPage);
  const [perPage, setPerPage] = useState(rowsPerPage);

  const colSpan = useMemo(() => header.length, [header]);

  const emptyRows = useMemo(() => {
    return page > 0 ? Math.max(0, (1 + page) * perPage - rows.length) : 0;
  }, [rows, page, perPage]);

  let filteredRows = useMemo(() => {
    if (searchField) {
      return rows.filter((item) => {
        const value =
          item[searchField]?.value !== undefined
            ? item[searchField].value
            : item[searchField];
        return typeof value === "string"
          ? value.toLowerCase().includes(searchTerm.toLowerCase())
          : item;
      });
    }
    return rows;
  }, [rows, searchTerm, searchField]);

  if (!!currentFilters?.length) {
    filteredRows = filteredRows.filter((row) => {
      let conditionValue;
      const activeFilters = currentFilters.filter(({ value }) => value);
      if (activeFilters.length === 0) {
        return true;
      }
      currentFilters.forEach((filter) => {
        if (filter.value) {
          if (currentFilters.length > 1 && filter.condition) {
            conditionValue = conditionValue && row[filter.key].value;
          } else {
            conditionValue = conditionValue || row[filter.key].value;
          }
        }
      });

      return conditionValue;
    });
  }

  const finalRows = useMemo(() => {
    return filteredRows.length
      ? filteredRows.slice(page * perPage, page * perPage + perPage)
      : [{ title: "No records to display" }];
  }, [filteredRows, page, perPage]);

  const totalPages = useMemo(
    () => filteredRows.length / perPage + 1,
    [filteredRows.length, perPage]
  );

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterChange = (key, value) => {
    setPage(0);
    const currentFiltersCopy = [...currentFilters];

    const filterIndex = currentFiltersCopy.findIndex((f) => f.key === key);

    if (filterIndex !== -1) {
      currentFiltersCopy[filterIndex] = {
        ...currentFiltersCopy[filterIndex],
        value,
      };
      setCurrentFilters(currentFiltersCopy);
    }
  };

  useEffect(() => {
    setPage(currentPage);
    setPerPage(rowsPerPage);
  }, [currentPage, rowsPerPage]);

  return (
    <Root>
      {(searchField || currentFilters) && (
        <div className="flex gap-x-4 items-start">
          {searchField && (
            <div>
              <input
                value={searchTerm}
                type="search"
                className="border-2 mb-2 border px-2 py-1 text-sm w-[200px]"
                placeholder={searchPlaceHolder || "Search"}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}
          {currentFilters && (
            <div className="flex h-full">
              {currentFilters.map((f) => (
                <Checkbox
                  label={f.label}
                  checked={f.value}
                  className="mr-6 whitespace-nowrap !mt-0"
                  onChange={(value) => handleFilterChange(f.key, value)}
                />
              ))}
            </div>
          )}
        </div>
      )}
      <table>
        <thead>
          <tr>
            {header.map((head) => (
              <th key={nanoid()}>{head}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {finalRows.map((row) => {
            return (
              <tr key={nanoid()} className="whitespace-nowrap">
                {Object.values(row).map((cell) => {
                  if (cell?.hidden) {
                    return null;
                  }
                  return <td key={nanoid()}>{cell?.content || cell}</td>;
                })}
              </tr>
            );
          })}

          {emptyRows > 0 && (
            <tr style={{ height: 41 * emptyRows }}>
              <td colSpan={colSpan} />
            </tr>
          )}
        </tbody>
        {totalPages >= 2 && (
          <tfoot>
            <tr>
              <CustomTablePagination
                rowsPerPageOptions={[]}
                colSpan={colSpan}
                count={filteredRows.length}
                rowsPerPage={perPage}
                page={page}
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        )}
      </table>
    </Root>
  );
};

TableWithPagination.propTypes = {
  header: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  filters: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.node.isRequired,
  searchField: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
};

export default TableWithPagination;
