export const JOB_OPPORTUNITY_STATUSES = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  FULFILLED: "FULFILLED",
  DELETED: "DELETED",
  DRAFT: "DRAFT",
  PENDINGAPPROVAL: "PENDINGAPPROVAL",
};

export const JOB_OPPORTUNITY_TIME_COMMITMENT = {
  FULLTIME: "FULLTIME",
  PARTTIME: "PARTTIME",
};

export const SOCIAL_LINK_TYPES = {
  BADGR: "BADGR",
  CALENDAR: "CALENDAR",
  DEV: "DEV",
  FACEBOOK: "FACEBOOK",
  GITHUB: "GITHUB",
  HASHNODE: "HASHNODE",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  PORTFOLIO: "PORTFOLIO",
  STACKOVERFLOW: "STACKOVERFLOW",
  TWITTER: "TWITTER",
};

export const CUSTOM_LINK_TYPES = {
  EXTERNALLINK: "EXTERNALLINK",
  GENERAL: "GENERAL",
  RESULT: "RESULT",
  RESUME: "RESUME",
  OTHER: "OTHER",
};

export const JOB_APPLICATION_MATCH_STATUS_FORMATTED = {
  ACCEPTED: "Accepted",
  APPLIED: "Applied",
  MATCHED: "Matched",
  PASSEDON: "Passed On",
  REJECTEDBYCUSTOMER: "Rejected (Customer)",
  REJECTEDBYMATCHER: "Rejected (Matcher)",
  REJECTEDBYMEMBER: "Rejected (Member)",
  SHORTLISTED: "Shortlisted",
  MOREINFO: "More Info",
  INTERESTED: "Interested",
  INTERESTEDFASTTRACK: "Interested Fasttrack",
};

export const JOB_APPLICATION_MATCH_STATUS = {
  ACCEPTED: "ACCEPTED",
  APPLIED: "APPLIED",
  MATCHED: "MATCHED",
  PASSEDON: "PASSEDON",
  REJECTEDBYCUSTOMER: "REJECTEDBYCUSTOMER",
  REJECTEDBYMATCHER: "REJECTEDBYMATCHER",
  REJECTEDBYMEMBER: "REJECTEDBYMEMBER",
  SHORTLISTED: "SHORTLISTED",
  MOREINFO: "MOREINFO",
  SKIPPED: "SKIPPED",
  DELETED: "DELETED",
  INTERESTED: "INTERESTED",
  INTERESTEDFASTTRACK: "INTERESTEDFASTTRACK",
};

export const JOB_APPLICATION_MATCH_SUB_STATUS = {
  FINALIST: "FINALIST",
  NULL: null,
};
export const JOB_APPLICATION_MATCH_SUB_STATUS_FORMATTED = {
  FINALIST: "Only Finalists",
  CALIBRATION: "Only Calibrations",
};

export const JOB_APPLICATION_MATCH_RATINGS = {
  VERYUNDESIRABLE: "VERYUNDESIRABLE",
  UNDESIRABLE: "UNDESIRABLE",
  NEUTRAL: "NEUTRAL",
  DESIRABLE: "DESIRABLE",
  VERYDESIRABLE: "VERYDESIRABLE",
};

export const JOB_APPLICATION_MATCH_RATINGS_FORMATTED = {
  VERYUNDESIRABLE: "Very Undesirable",
  UNDESIRABLE: "Undesirable",
  NEUTRAL: "Neutral",
  DESIRABLE: "Desirable",
  VERYDESIRABLE: "Very Desirable",
};

export const MATCH_RATING_REASONS_FORMATTED = {
  RATE: "Rate",
  LOCATION: "Location",
  TIMEZONE: "Timezone",
  INSUFFICIENTSKILLS: "Insufficient Skills",
  INSUFFICIENTEXPERIENCE: "Insufficient Experience",
  EXPERIENCENOTRELEVANT: "Experience Not Relevant",
};

export const CUSTOM_LINK_TITLES_BY_TYPE = {
  [CUSTOM_LINK_TYPES.EXTERNALLINK]: "External link",
  [CUSTOM_LINK_TYPES.GENERAL]: "General link",
  [CUSTOM_LINK_TYPES.OTHER]: "Other link",
  [CUSTOM_LINK_TYPES.RESULT]: "Result link",
  [CUSTOM_LINK_TYPES.RESUME]: "Resume link",
};

export const USER_TYPES = {
  FREELANCER: "FREELANCER",
  CUSTOMER: "CUSTOMER",
  ADMIN: "ADMIN",
};

export const CUSTOM_PANEL_DEFAULT_OPERATOR = {
  "skills.name": "and",
  activeJobTypeTitles: "or",
  "knownLanguages.language": "and",
  "assessments.testName": "and",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
};

export const DEFAULT_RATE_RANGE = { min: 0, max: 200 };

export const GEOGRAPHICAL_REGIONS = [
  {
    regionName: "North America",
    countryNames: ["United States", "Canada", "Mexico"],
  },
  {
    regionName: "Asia",
    countryNames: ["India", "China", "Japan"],
  },
  {
    regionName: "Europe",
    countryNames: ["France", "Germany", "Italy"],
  },
];

export const APPLICATION_TAB_NAMES = {
  ALLUSERS: "All Users",
  MATCHED: "Matched",
  CALIBRATION: "Calibration",
  SHORTLISTED: "Shortlisted",
  SKIPPED: "Skipped",
  BESTFIT: "Best Fit",
};

export const SEARCH_BY_ATTRIBUTES = [
  {
    title: "Default",
    attributes: [
      "skills_str",
      "given_name",
      "family_name",
      "email",
      "username",
      "identity_username",
      "company",
      "bio",
      "referralCode",
      "referrerCode",
      "tagline",
      "location",
      "activeJobTypeTitles",
      "id",
      "knownLanguages_str",
    ],
  },
  {
    title: "Experience & Resume",
    attributes: [
      "careers.title",
      "careers.stack",
      "careers.description",
      "projectsCaseStudies.title",
      "projectsCaseStudies.stack",
      "projectsCaseStudies.description",
      "parsedResume.content",
    ],
  },
  {
    title: "Experience",
    attributes: [
      "careers.title",
      "careers.stack",
      "careers.description",
      "projectsCaseStudies.title",
      "projectsCaseStudies.stack",
      "projectsCaseStudies.description",
    ],
  },
  {
    title: "Resume",
    attributes: ["parsedResume.content"],
  },
];

export const NOTE_TYPES = {
  CALIBRATION: "CALIBRATION",
  GENERAL: "GENERAL",
  JOBFEEDBACK: "JOBFEEDBACK",
};

export const NOTE_TYPES_MAPPED_ICON = {
  JOBFEEDBACK: { icon: "jobFeedback", label: "Job Feedback" },
};

export const LANGUAGE_KEY_NAMES = {
  language: "knownLanguages.language",
  level: "knownLanguages.level",
};

export const SKILL_KEY_NAMES = {
  name: "skills.name",
};

export const SKILL_STR_FORMATTED_OPTIONS = [
  { label: "5+y", value: "high" },
  { label: "2-5y", value: "medium" },
  { label: "0-2y", value: "low" },
];
