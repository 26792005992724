import classNames from "classnames";
import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";

const GeographicalRegion = ({
  geographicalRegions,
  searchState,
  setGeographicalRegions,
  itemsLimit,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const filteredRegions = geographicalRegions.filter(({ regionName }) =>
    regionName.toLowerCase().includes(searchValue.trim().toLowerCase())
  );

  const finalItems = useMemo(() => {
    if (expanded && filteredRegions.length > itemsLimit) {
      return filteredRegions;
    }

    return filteredRegions.slice(0, itemsLimit);
  }, [expanded, itemsLimit, filteredRegions]);

  const handleGeographicalPreset = (regionName) => {
    const geographicalRegion = geographicalRegions.map((geoRegion) =>
      geoRegion.regionName === regionName
        ? { ...geoRegion, checked: !geoRegion.checked }
        : geoRegion
    );
    setGeographicalRegions(geographicalRegion);
  };

  const highlightText = (text, searchValue) => {
    if (!searchValue) {
      return text;
    }
    const regex = new RegExp(`(${searchValue})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="ais-Highlight-highlighted">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    if (geographicalRegions.some((geoRegion) => geoRegion.checked)) {
      const { refinementList } = searchState;

      const searchLocations = refinementList?.["location.countryName"];

      const regionToUnMark = geographicalRegions.find((geoRegion) => {
        return (
          geoRegion.checked &&
          !geoRegion.countryNames.some((region) =>
            searchLocations?.includes(region)
          )
        );
      });
      if (regionToUnMark) {
        handleGeographicalPreset(regionToUnMark.regionName);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.refinementList?.["location.countryName"]]);

  return (
    <>
      <input
        className="border block w-full px-2 py-1 text-sm my-6"
        type="search"
        placeholder="Search for a specific region"
        onChange={(event) => setSearchValue(event.currentTarget.value)}
      />
      <ul className="ais-RefinementList-list">
        {finalItems.map((geoRegion) => (
          <li
            key={geoRegion.regionName}
            className={classNames("mt-1 ais-RefinementList-item", {
              "ais-RefinementList-item--selected": geoRegion.checked,
            })}
          >
            <label
              className="flex items-center text-sm whitespace-nowrap ais-RefinementList-label"
              title={geoRegion.countryNames?.join(", ")}
            >
              <input
                type="checkbox"
                className="ais-RefinementList-checkbox"
                checked={geoRegion.checked}
                onClick={() => handleGeographicalPreset(geoRegion.regionName)}
              />
              <span
                className={classNames("mx-2 overflow-hidden text-ellipsis", {
                  "font-bold": geoRegion.checked,
                })}
              >
                {highlightText(geoRegion.regionName, searchValue)}
              </span>
            </label>
          </li>
        ))}
      </ul>
      {filteredRegions.length > itemsLimit && (
        <button
          onClick={() => setExpanded((prev) => !prev)}
          className="ais-RefinementList-showMore"
        >
          {expanded ? "Show less" : "Show more"}
        </button>
      )}
    </>
  );
};

GeographicalRegion.propTypes = {
  geographicalRegions: PropTypes.array,
  itemsLimit: PropTypes.number,
};

GeographicalRegion.defaultProps = {
  geographicalRegions: [],
  itemsLimit: 10,
};

export default GeographicalRegion;
