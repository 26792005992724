import React, { createContext, useReducer } from "react";

import { contextConstants } from "context/constants";
import { contextReducers } from "context/reducers";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.modal.reducer,
    contextReducers.modal.initialState
  );

  const showModal = (activeModal) => {
    dispatch({
      type: contextConstants.modal.MODAL_SHOWED,
      payload: { activeModal },
    });
  };

  const hideModal = () => {
    dispatch({
      type: contextConstants.modal.MODAL_HIDE,
    });
  };

  const modal = {
    ...state,
    showModal,
    hideModal,
  };

  return (
    <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
  );
};
