import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import styles from "../index.module.css";

const ProfileCompletion = ({ hit }) => {
  return (
    <CircularProgressbarWithChildren
      className="relative w-[130px]"
      value={hit.profileCompletion || 0}
      counterClockwise
      strokeWidth={20}
      styles={buildStyles({
        pathTransitionDuration: 1,
        pathColor: "#0495b7",
        trailColor: "#302C7F",
        width: 130,
      })}
    >
      <span className={classNames(`text-purple-600 ${styles.percent}`)}>
        {hit.profileCompletion || 0}%
      </span>

      <span className={styles.complete}>Complete</span>
    </CircularProgressbarWithChildren>
  );
};

ProfileCompletion.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default ProfileCompletion;
