import React, { useState } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

const HelpText = ({
  text,
  iconType,
  iconText,
  className,
  iconTextClassName,
  children,
  toolTipClassName,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const renderIcon = () => {
    switch (iconType) {
      case "svg": {
        return (
          <div
            className={classNames(
              "inline-block rounded-full flex justify-center items-center w-5 h-5 ml-1 align-middle relative",
              iconTextClassName
            )}
            style={{
              background: `linear-gradient(to bottom, #DADADA, #979797)`,
              cursor: `help`,
            }}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            <span
              className="text-sm text-white hard-center font-rubik"
              style={{ textShadow: `0px 0px 3px black` }}
            >
              ?
            </span>
          </div>
        );
      }

      case "text": {
        return (
          <p
            className={classNames(
              "font-bold text-blue-500 cursor-help",
              iconTextClassName
            )}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          >
            {iconText}
          </p>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <div className={`relative font-normal not-italic ${className}`}>
      {renderIcon()}

      <div
        style={{ borderRadius: `20px`, cursor: `unset` }}
        className={classNames(
          "pointer-events-none text-black absolute top-full left-0 mt-2 transform -translate-x-1/2 bg-white shadow p-4 max-w-xs w-max z-10 font-rubik transition-all normal-case shadow",
          {
            "translate-y-1": isVisible,
            "opacity-0 -translate-y-1": !isVisible,
          },
          toolTipClassName
        )}
      >
        {children ? children : <p className="font-rubik font-normal">{text}</p>}
      </div>
    </div>
  );
};

HelpText.propTypes = {
  text: PropTypes.string,
  iconType: PropTypes.string,
  iconText: PropTypes.string,
  className: PropTypes.string,
  iconTextClassName: PropTypes.string,
};

HelpText.defaultProps = {
  text: "",
  iconType: "svg",
  iconText: "",
  className: "",
  iconTextClassName: "",
};

export default HelpText;
