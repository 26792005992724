import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import api from "apiSingleton";

const Referrer = ({ referralCode }) => {
  const [referrer, setReferrer] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!referralCode || !referralCode.length) {
      return
    }

    (async () => {
      const res = await api.user.getByReferralCode({
        referralCode
      })

      if (res.data.getUserByReferralCode.items[0]) {
        setReferrer(res.data.getUserByReferralCode.items[0])
      } else {
        setReferrer({username:"UNKNOWN", userType:""})
      }

      setLoading(false)
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!referralCode || !referralCode.length) {
    return null
  }

  return (
    <p>
      Referred by{ referrer.userType?.startsWith("TORC_")? " Torc": "" }:{" "}
      <span className="font-normal">
        {loading ? "<loading...>" : referrer.username}
      </span>
    </p>
  );
};

Referrer.propTypes = {
  referralCode: PropTypes.string
};

Referrer.defaultProps = {
  referralCode: ""
};

export default Referrer;
